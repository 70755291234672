  import React from 'react';
  import {
    Container,
    Box,
    Typography,
    Button,
  } from '@mui/material';
  import { Link as RouterLink } from 'react-router-dom';
  import Marquee from 'react-marquee-slider';

  import Header from './Header';
  import VideoBackground from '../utils/VideoBackground';
  import Footer from './Footer';
  import { Height } from '@mui/icons-material';
  //const backgroundImage = '/media/bg/background.jpg';



  interface Props {
    children: React.ReactNode;
    backgroundImage: string;
  }
  
  const PageRedux = ({ children, backgroundImage }: Props) => {


    return (
      <Box
      sx={{
        bgcolor: 'black',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover', // Cover the entire space of the Box

        backgroundPosition: 'center',
        backgroundAttachment: 'fixed', // Add this line
        minHeight: '100vh', // Set a minimum height to ensure the background covers the entire viewport
    
      }}
    >
        
          <Header />
  
          <main>
            <Container  sx={{ padding: 2, minHeight:"88vh",}}>{children}</Container>
          </main>
          <footer>
          
          <Footer />
        </footer>
      
        
      </Box>
    );
  };

  export default PageRedux;
