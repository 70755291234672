
import Page from "components/Page";
import { Box, Button,CircularProgress,Dialog,DialogActions,DialogContent,DialogTitle,Grid, Link, Paper, TableContainer, TextField, Typography } from "@mui/material";
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { ethers } from 'ethers';
import { getAccount, switchChain } from '@wagmi/core'
import { mainnet } from '@wagmi/core/chains'
import { arbitrumSepolia, arbitrumNova } from "@wagmi/core/chains";
import { EXPECTED_CHAIN_ID } from "config";
import { type GetBalanceReturnType } from '@wagmi/core'
//import {BigNumber as BG} from 'bignumber.js'; // Assuming BigNumber usage for handling big numbers

//import { config } from './config'
import {
  useAccount,
  useAccountEffect,
  useBalance,
  useBlockNumber,
  useConnect,
  useContractReads,
  useDisconnect,
  useReadContracts,
  //useNetwork
} from 'wagmi';
//import Web3 from 'web3';
import { BigNumber } from 'ethers';
import { Link as MuiLink } from "@mui/material";
import Connect from "components/Connect";

import RadiantGiftMintCard from "components/RadiantGiftMintCard";

import InfoCard from "components/InfoCard";

import * as React from 'react'
import { useDebounce } from 'usehooks-ts'
import {
 // usePrepareSendTransaction,
  useSendTransaction,
 // useWaitForTransaction,
} from 'wagmi'
import { utils } from 'ethers'
import { fetchBalance, getBalance, switchNetwork } from 'wagmi/actions'
import { debounce } from "lodash";
import  { useEffect, useState } from "react";
import RadiantGiftGallery from "components/RadiantGiftGallery";
import AvgETHPriceComponent from "../components/AvgETHPriceComponent";
import SwitchNetworkButton from "components/SwitchNetworkButton";
import AddTokenButton from "components/AddTokenButton";
import { useContractRead } from 'wagmi';

import LLMABI from 'abi/LogicalMechanism.json' // Make sure you import the ABI of your contract here
import MintingButtonEnabled from "components/MintingButtonEnabled";
import LLMMintButton from "components/LLMMintButton";
import { BridgeWarning } from "components/BridgeWarning";
import LLMMintCard from "components/LLMMintCard";
import axios from "axios";
import { Balance } from "@mui/icons-material";
import { CustomConnectButton } from "components/CustomConnectButton";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { config }  from "config";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
const  LLMPage = () => {

  const queryClient = new QueryClient();
  const { address, isConnected } = useAccount();
  const [swapAmount, setSwapAmount] = useState<any | BigNumber>(BigNumber.from(0));
  const  chainId  = getAccount(config).chain?.id;
  const { data: blockNumber } = useBlockNumber({ watch: true }) ;
  //const { data: balance, queryKey } = useBalance({
   // address: '0x4557B18E779944BFE9d78A672452331C186a9f48',
 // })
 
  // Use useAccountEffect for handling onConnect and onDisconnect events
  useAccountEffect({
    async onConnect({ address, connector, isReconnected }) {
      console.log('Connected', { address, connector, isReconnected });
      let tmpAddress = address as `0x${string}` ?? "0x0000000000000000000000000000000000000000"as `0x${string}`;
     // const bal = await getBalance(config, {
     //   address: tmpAddress,
      //});
     // console.log(bal);
    },
    onDisconnect() {
      console.log('Disconnected');
      // Handle disconnection logic here
    },
  });
  const rows = [
    { phase: 1, cap: '100,000,000 LLM', price: '$0.01', change: '150.00%', priceIncreasePerLLM: '$0.0000000015'},
    { phase: 2, cap: '500,000,000 LLM', price: '$0.025', change: '60.00%'},
    { phase: 3, cap: '1,500,000,000 LLM', price: '$0.04', change: '25.00%'},
    { phase: 4, cap: '3,000,000,000 LLM', price: '$0.05', change: '10.00%'},
    { phase: 5, cap: '5,000,000,000 LLM', price: '$0.055',change: '9.09%'},
    { phase: 6, cap: '9,000,000,000 LLM', price: '$0.06', change: '8.33%'},
    { phase: 7, cap: '12,000,000,000 LLM', price: '$0.065', change: '7.69%'},
    { phase: 8, cap: '14,000,000,000 LLM', price: '$0.07', change: '4.29%'},
    { phase: 9, cap: '15,0000,000,000 LLM', price: '$0.073', change: '2.74%'},
  ];
  
  const chains = config.chains; 
  const [MintPrice, setMintPrice] = useState<any | BigNumber>(BigNumber.from(10));

  const [CurrentSupply, setCurrentSupply] = useState<any | Number>(0);
  const [CurrentAddress, setCurrentAddress] = useState<any | string>("0x0000000000000000000000000000000000000000");
  useEffect(() => {
    if (address) {
      const interval = setInterval(() => {
        if (address) {
          fetchLLMBalance(address);
        }
      }, 12000);
  
      return () => clearInterval(interval); // Clears the interval when the component is unmounted
    }
  }, [address]); // Re-run the effect when `address` changes
  
const [UserBalance, setUserBalance] = useState<any | string>("0");
useEffect(() => {
  try{
  if (address) {
    setCurrentAddress(address);
      //console.log(chain?.id) arbitrum
      //let chainz = getAccount(config).chain ;
      if(chainId === EXPECTED_CHAIN_ID ) { //90011337) {
        SetIsCorrectNetwork(true);   
       fetchLLMBalance(address);
      } else {
       if(isConnected){
       // const network = await switchNetwork({
          //  chainId: 1,
         // })
     //  addCustomNetwork();
   //  handleSwitchNetwork();0x9b70fa3555eef9c7624f764777d4501712a7c72af8cd489f175791246c409a17
       }  
      }
    }
  }
  catch(err) {}
  }, [address, chainId, debounce]);

//use effect for one time pop up
//useEffect (() => {



  const addCustomNetwork = async () => {
    if (typeof window.ethereum !== 'undefined') {
        try {
            // Define network parameters
            const params = {//0xa4ba55D76C9
              chainId: '0x55d76c9', 
               chainName: 'Sage Towers',
              nativeCurrency: {
                name: 'Sage Stone',
                symbol: 'SGSTN',
                decimals: 18,

              },
              rpcUrls: ['https://chain.sagetowers.com/'],
              blockExplorerUrls: ['https://scan.sagetowers.com/'],
            };
    
            // Add Ethereum network
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [params],
            });
          } catch (error) {
            console.error(error);
          }
    } else {
        alert('Please install Wallet');
    }
  };
  /*
  const handleSwitchNetwork = async () => {
    try {
      await switchChain(config, { chainId: mainnet.id })
      console.log('Network switched:', network);
    } catch (error) {
      console.error('Failed to switch network:', error);
    }
  };
 */
  const fetchLLMBalance = async (walletAddress: `0x${string}` ) => {
    if(walletAddress === "0x0000000000000000000000000000000000000000"){
        setUserBalance(0);
    }
    /*
const balance = getBalance(config, {
  address: '0x4557B18E779944BFE9d78A672452331C186a9f48',
})
    */
    const bal = await getBalance(config, {address: walletAddress,  token:"0x226B70c1Af7FeA262D252b5A16E55057bb1c79be"});  
    setUserBalance(bal.formatted);
    console.log(bal.formatted);  
  };


const [IsCorrectNetwork, SetIsCorrectNetwork] = useState<boolean>(false);
const CurrentSupplyString = CurrentSupply? CurrentSupply.toString() : "0";
const numUserBalance = parseFloat(UserBalance);


const LLMContract = {
    address: '0x226B70c1Af7FeA262D252b5A16E55057bb1c79be', // Replace with your contract address
    abi: LLMABI,
  } as const;

  const [totalMinted, setTotalMinted] = useState<BigNumber>(BigNumber.from(0));
  const [currentPhase, setCurrentPhase] = useState<BigNumber>(BigNumber.from(0));
  const [usdRetailPrice, setUsdRetailPrice] = useState<BigNumber>(BigNumber.from(0));


const { data, isError, error }  = useReadContracts({
    
contracts: [
  {
    ...LLMContract,
    functionName: 'totalMinted',
   
    
  },
  {
    ...LLMContract,
    functionName: 'currentPhase',
   
  },
  {
    ...LLMContract,
    functionName: 'usdRetailPrice',
   
  }
],

})
useEffect(() => {
  if (data) {
    // Assuming the results are numbers (e.g., uint256) from the smart contract
    data.forEach((item, index) => {
      if (item.status === 'success' && typeof item.result === 'number') {
        const value =  BigNumber.from(item.result);
        switch (index) {
          case 0:
            setTotalMinted(value);
            break;
          case 1:
            setCurrentPhase(value);
            break;
          case 2:
            setUsdRetailPrice(value);
            break;
          default:
            console.warn('Unexpected data index:', index);
        }
      } else if (item.status === 'failure') {
        console.error('Error fetching data for index', index, ':', item.error);
      }
    });
  }
}, [data]);

/*
useEffect(() => {
  // Ensure blockNumber is defined and convert 5 to bigint if blockNumber is bigint
  if (blockNumber !== undefined && blockNumber % BigInt(5) === BigInt(0)) {
    queryClient.invalidateQueries({ queryKey });
  }
}, [blockNumber]);*/
const [avgETHPrice, setAvgETHPrice] = useState(2500.00);

const getCoinGeckoPrice = async () => {
  const response = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd');
  return response.data.ethereum.usd;
};

const calculateAvgPrice = async () => {
  try {

    let coinGeckoPrice = await getCoinGeckoPrice();

    setAvgETHPrice(coinGeckoPrice);
    console.log('avgPrice', coinGeckoPrice);
  } catch (error) {
    console.error(error);
  }
};

useEffect(() => {
 
  const interval = setInterval(() => {
    calculateAvgPrice();



//(usdRetailPrice / Math.pow(10, 18) * 1)
/*
const costPerTokenInUsd: number = (usdRetailPrice / Math.pow(10, 18) * 1);
console.log("Cost Per Token in USD: " + costPerTokenInUsd);
const ethToUsdConversionRate: number = avgETHPrice;
console.log("ETH to USD Conversion Rate: " + ethToUsdConversionRate);
const costPerTokenInEth: number = costPerTokenInUsd / ethToUsdConversionRate;
const usdRetailPrice: ethers.BigNumber = ethers.BigNumber.from("your_big_number_here");
*/
// Convert usdRetailPrice to a floating-point number (in USD) by dividing by 10^18 and then converting to a number
const usdRetailPriceInUsd: number = parseFloat(ethers.utils.formatUnits(usdRetailPrice, 18));

console.log("Cost Per Token in USD: " + usdRetailPriceInUsd);

// Assuming avgETHPrice is the conversion rate from ETH to USD as a number
const ethToUsdConversionRate: number = avgETHPrice; // Ensure avgETHPrice is defined somewhere in your code

console.log("ETH to USD Conversion Rate: " + ethToUsdConversionRate);

// Calculate the cost per token in ETH by dividing the USD price by the ETH to USD conversion rate
const costPerTokenInEth: number = usdRetailPriceInUsd / ethToUsdConversionRate;

console.log("Cost Per Token in ETH: " + costPerTokenInEth);
//const costPerTokenInWei: ethers.BigNumber = ethers.utils.parseEther(costPerTokenInEth.toString());

console.log(`The cost per token in wei is: ${costPerTokenInEth.toString()}`);

  }, 60000); // Refresh every 60 seconds

  return () => clearInterval(interval); // Clear the interval on unmount
}, []);



function formatToTenDecimalPlaces(numberz:number) {
    return Math.round(numberz * 10000000000) / 10000000000;
  }

//convert bignumber to number
//const totalMintedNumber = totalMinted? totalMinted.toNumber() : 0;
//const currentPhaseNumber = currentPhase? currentPhase.toNumber() : 0;
///const usdRetailPriceNumber = usdRetailPrice;//? usdRetailPrice.toNumber() : 0;
//console.log("USD Retail Price: " + usdRetailPriceNumber);
// Assuming usdRetailPrice is in wei and 1 Ether = 1000 USD
// 1 Ether = 10^18 wei
// const [CurrentSupply, setCurrentSupply] = useState<any | Number>(0);
//const [retailPriceDollars,SetRetailPrice] = useState<any | BigNumber>(BigNumber.from(0));
//const usdRetailPriceDollars = (usdRetailPrice / Math.pow(10, 18) * 1);
//SetRetailPrice(usdRetailPriceDollars);
//convert bignumber to number
//const totalMintedNumber = totalMinted? totalMinted.toNumber() : 0;
//const currentPhaseNumber = currentPhase? currentPhase.toNumber() : 0;
const usdRetailPriceNumber = usdRetailPrice;//? usdRetailPrice.toNumber() : 0;
console.log("USD Retail Price: " + usdRetailPriceNumber);
// Assuming usdRetailPrice is in wei and 1 Ether = 1000 USD
// 1 Ether = 10^18 wei
// const [CurrentSupply, setCurrentSupply] = useState<any | Number>(0);
//const [retailPriceDollars,SetRetailPrice] = useState<any | BigNumber>(BigNumber.from(0));

const usdRetailPriceDollars  = parseFloat(ethers.utils.formatUnits(usdRetailPrice, 18));
//SetRetailPrice(usdRetailPriceDollars);
console.log("USD Retail Price in Dollars: " + usdRetailPriceDollars);
console.log("Raw num: " + usdRetailPrice);

const keker = avgETHPrice * usdRetailPriceDollars ;
console.log("Price per token in USD: " + keker);

const kek = formatToTenDecimalPlaces(usdRetailPriceDollars);
console.log("Formatted Value: " + kek);

// Assuming numUserBalance is the number of tokens (like LLM) the user has
const totalUserValue = kek * numUserBalance;
console.log("Total User Value: " + totalUserValue);

const totalUserValueFormatted = totalUserValue.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
console.log("Total User Value Formatted: " + totalUserValueFormatted);

//take usdRetailPriceDollars and multiple it by avgETHPrice
const donePrice =  kek * avgETHPrice;
console.log("Done Price: " + donePrice);

const costPerTokenInUsd: number = usdRetailPriceDollars;
console.log("Cost Per Token in USD: " + costPerTokenInUsd);
const ethToUsdConversionRate: number = avgETHPrice;
console.log("ETH to USD Conversion Rate: " + ethToUsdConversionRate);
const costPerTokenInEth: number = costPerTokenInUsd / ethToUsdConversionRate;
//const costPerTokenInWei: ethers.BigNumber = ethers.utils.parseEther(costPerTokenInEth.toString());

console.log(`The cost per token in wei is: ${costPerTokenInEth.toString()}`);
const [open, setOpen] = useState(false);

const handleOpen = () => {
  setOpen(true);
};

const handleClose = () => {
  setOpen(false);
};

const handleProceed = () => {
  // Handle the logic for proceeding with the action
  console.log('Proceeding with the action...');
  setOpen(false);
  calculateAvgPrice();
  fetchLLMBalance(address? address : '0x0000000000000000000000000000000000000000')


};

//We need to run handleOpen on first load
useEffect(() => {
    handleOpen();
}
, []);


  return (
    <Page>
          <Dialog
        open={open}
        onClose={handleClose}
        sx={{backgroundColor: "rgba(23, 23, 33, 0.8)", m:"3"}}
        
      >
        <Box sx={{backgroundColor: "rgba(23, 23, 33, 0.8)" }}>
        <DialogTitle color="primary"    sx={{backgroundColor: "rgba(244, 67, 54, 1)", m:"3", }} >Warning: ONE WAY ONLY</DialogTitle>
        <DialogContent      sx={{ m:"3", mt:"2rem"}} >
        <Typography variant="body1">
            Please note that the Nether Bridge is currently a one-way mechanism, allowing ETH to LLM conversion only. This is an intentional part of the testnet phase. Rest assured, your LLM tokens retain value within the Sage Towers ecosystem, and future updates are planned to alter this one-way restriction. We are smaller team and use a % of ETH sent to us to pay service providers of our AI systems.
          </Typography>
  
          </DialogContent>
          <DialogContent>
          <Typography  variant="body1">
            The Logical Mechanism (LLM) token is uniquely tied to real-world AI compute time within Sage Towers AI services. It provides tangible utility value and can be consumed at any time to interact with various AI-driven offerings.
          </Typography>
          </DialogContent>
          <DialogContent>
          <Typography variant="body1">
            Should you require assistance or have any concerns, dedicated support is available through the <MuiLink href="https://discord.gg/sagetowers">Sage Towers Discord</MuiLink>. All mainnet transaction hashes will be honored, ensuring that LLM cannot be lost on the Sage testnet side.
          </Typography>
          </DialogContent>
          <DialogContent>
          <Typography m="1" variant="h1" fontSize={"1.5rem"} >Are you sure you understand?</Typography>   </DialogContent>
     
        
        <DialogActions>
        
          <Button onClick={handleProceed} size='large' sx={{backgroundColor: "rgba(244, 67, 54, 1)",}}>
            I understand this is only one way for now.
          </Button>
        </DialogActions> </Box>
      </Dialog>
        
      {chainId === EXPECTED_CHAIN_ID &&(
      <Box
        bgcolor="rgba(23, 23, 33, 0.85)"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          gap: 1.5,
          width: "100%",
          padding: "0.5rem",
          borderRadius: "3px",
        }}
      >
  

   <Box sx={{mt:"3vh"}}>
    <LLMMintCard  mintPrice={costPerTokenInEth} ethPrice={costPerTokenInUsd} balance={UserBalance} totalMinted={ parseFloat(ethers.utils.formatUnits(totalMinted, 18)).toString()}></LLMMintCard>
  
       </Box>
         </Box>
   )}
   {
       !isConnected &&
        (
            <Box
            bgcolor="rgba(23, 23, 33, 0.85)"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              gap: 1.5,
              width: "100%",
              padding: "0.5rem",
              borderRadius: "3px",
            }}
          >
            
  
                  <Typography color="#925fa5" variant="h1" fontSize={"2.75rem"}>Nether Bridge</Typography> 
             <ConnectButton/>          
                   <img src="./media/icons/llmlogo.png" width={"190px"} alt="LLM Logo" />

    
    <Typography color="#925fa5" variant="h2" fontSize={"1 .55rem"}>ETH  :   LLM</Typography>     <Typography color="#925fa5"     variant="h3" style={{fontSize:"1.2rem"}}  component="p"><strong>
   1 LLM = ${(costPerTokenInUsd ).toFixed(9)} </strong>
  
        </Typography>
                  </Box>  
        )
      }
    {chainId != EXPECTED_CHAIN_ID && isConnected &&(
        <Box
        bgcolor="rgba(23, 23, 33, 0.85)"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          gap: 1.5,
          width: "100%",
          padding: "0.5rem",
          borderRadius: "3px",
        }}
      >
        
        <Typography color="#925fa5" variant="h1" fontSize={"2.75rem"}>Nether Bridge</Typography> 
        <SwitchNetworkButton/>       
                   <img src="./media/icons/llmlogo.png" width={"190px"} alt="LLM Logo" />

    
    <Typography color="#925fa5" variant="h2" fontSize={"1 .55rem"}>ETH  :   LLM</Typography>     <Typography color="#925fa5"     variant="h3" style={{fontSize:"1.2rem"}}  component="p"><strong>
   1 LLM = ${(costPerTokenInUsd ).toFixed(9)} </strong>
  
        </Typography>
   
      </Box>
    )}

        {address &&(
      <Box
        bgcolor="rgba(23, 23, 33, 0.85)"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent:  'space-between',
          textAlign: "center",
          width: "100%",
          padding: "0.25rem",
          borderRadius: "3px", 
        }}
      >
  <Grid container mt={"0.1vh"}  style={{width: '100%'}} display="flex" justifyContent="center" alignItems="center" spacing={4}>
  <Grid item sm={12} md={6} mt={"-2.5vh"}   >
    
           
     <Typography mt={"0.6rem"} mb={"0.5rem"}  variant="h1" fontSize={"1.8rem"}> <strong>Total LLM Minted : { parseFloat(ethers.utils.formatUnits(totalMinted, 18)).toString()}</strong></Typography>
     
  <Box display="flex" flexDirection="column" alignItems="center">
  {UserBalance > 0 && (<div>
      <Typography  mt={2} mb={0} variant="h1" fontSize="1.80rem">
          Your LLM Balance: {UserBalance} 
      </Typography>
      <Typography  mt={1} mb={1} variant="h2" fontSize="1.4rem">
      & Sage Towers AI Compute Value: {(UserBalance * ( parseFloat(ethers.utils.formatUnits(usdRetailPrice, 18)))).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
      </Typography>

     
      
        
  </div>)}
  <Box mt={1} mb={1} display="flex" flexDirection="column" alignItems="center">
        <AddTokenButton/></Box>
<Typography m={2} mt={3}  variant="body1" style={{fontSize:"0.7rem"}}  component="p">
    <strong> *Sage Towers is currently an Arbitrum Orbit chain and currently settles on Arbitrum Goerli. When testing is done and it's avalable, we plan to
      move over to launch/settle Sage Towers Orbit chain on Arbitrum Nova. A certain amount of assets, such as LLM, 
      will be bridged/airdropped over to the final layer.</strong>
        </Typography>
    
        </Box>

          
</Grid>



</Grid>  
    
  
 
   
      </Box>)}
      <Box
       sx={{
        mt: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          gap: 1.5,
          width: "100%",
          height: "100%",
          padding: "0.5rem",
          borderRadius: "3px",
        }}
      >
        <Box
       sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          gap: 1.5,
          width: "100%",
          height: "100%",
          //padding: "0.5rem",
          borderRadius: "3px",
          //bgcolor: "rgba(22,22,32,0.5)",
      boxShadow: "0px 0px 15px rgba(128, 226, 126, 0.9)", 
      backgroundColor: "rgba(23, 23, 33, 1)",
      filter: 'drop-shadow(0px 0px 15px rgba(128, 226, 126, 0.9))',
padding: 0,
        }}
      >
<Box sx={{m:0  }} >
<Typography sx={{mt:"2vh"}}  variant="h1" color="#925fa5" fontSize={"2.5rem"}>LLM Early Adoption Incentives</Typography> 
<Typography sx={{mb:"2vh"}} variant="h2" color="#925fa5" fontSize={"1.15rem"}> Seizing Opportunity in the Towers of Logic</Typography> 
<Box mt={"2rem"}>
<img src="./media/llmchart.jpg" width={"65%"} alt="LLM Chart" /></Box>
<InfoCard
     title=""
     text=" In the sprawling metaverse of Sage Towers, the Logical Mechanism (LLM) token operates as more than just a digital asset. It's the key to unlock unparalleled AI interactions and a gateway to endless possibilities. The phased pricing structure of LLM creates a unique opportunity for early adopters, providing them with an advantageous entry into the world of Sage Towers."
     imageUrl="none"
/>


<InfoCard
     title="The Advantage of Early Entry"
     text="As the architects of the future build and innovate within Sage Towers, the LLM token represents a finite resource, akin to the precious AI compute time it embodies. Early acquisition of LLM tokens grants pioneers a foothold in this expansive digital realm at a favorable price, allowing them to harness the full potential of the Logical Mechanism."
     imageUrl="media/bigmarket.png"
/>

<InfoCard
     title="A Journey Through Phases"
     text="Just as Sage Towers consists of various intricate levels and domains, the pricing structure of LLM unfolds through phases. Each phase highlights a different price point, escalating as more tokens are minted. Early navigators of this journey are rewarded with the benefit of lower costs, making their exploration and innovation within Sage Towers more accessible." 
     imageUrl="media/bigmarket2.png"
/>
<InfoCard
     title="The Ripple Effect"
     text="The early adoption of LLM tokens does more than just provide a cost advantage. It fuels the growth and vitality of Sage Towers, attracting more creators, innovators, and explorers. The logical mechanisms turn, and the towers thrive, creating a self-reinforcing cycle of growth and value"  
     imageUrl="media/basic18.png"
/>

<Button size="large"
          variant="outlined"
          color="inherit"
          href="https://docs.sagetowers.com/sage-towers/blockchain-services/currencies/logical-mechanism-llm" target="_blank" sx={{ mt:"-5vh" }}>
          Deep dive on Logical Mechanism here
        </Button>   
</Box>
  <InfoCard
     title="What is the LLM token?"
     text="The LLM token is an ERC-20 digital asset designed to be tied to real-world AI compute credits within the Sage Towers gaming environment. Its phased pricing structure ensures a stable and predictable growth pattern."
     imageUrl="media/basic13.png"
/>

<InfoCard
     title="Can the price of LLM tokens decrease?"
     text="No, the system guarantees that the LLM token's retail price will never decrease after reaching a phase, providing a stable minimum value for holders and protecting against unexpected price drops."
     imageUrl="media/basic5.png"
/>

<InfoCard
     title="What are the AI-driven experiences in Sage Towers?"
     text="AI-driven experiences in Sage Towers include interactions with AI-controlled NPCs and AI-crafted NFTs. These experiences are accessed using LLM tokens, which are burned after each interaction, offering unique and engaging content within the game."
     imageUrl="media/basic4.png"
/>    

 </Box>
       </Box>




       
    </Page>
    
  );
};

export default LLMPage;


