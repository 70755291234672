import * as React from 'react';
import { useEffect, useState } from 'react';
import { useWaitForTransactionReceipt, useWriteContract } from 'wagmi';
import {
  Box,
  Typography,
  Modal,
  Button,
  CircularProgress,
  Card,
  styled
} from '@mui/material';
//import web3 from 'web3';
import { ethers } from 'ethers';
import { parseEther } from 'viem';
import { useNavigate } from 'react-router-dom';

const CONTRACT_ADDRESS_NFT = '0xA8675eaacFCB964a81e360B857e06dbedc2F003d';
const CONTRACT_ABI_NFT = [
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "name",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "description",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "image",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "coinTicker",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "coinName",
				"type": "string"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "mintNFT",
		"outputs": [],
		//"stateMutability": "nonpayable",  // Adjusted from "nonpayable" to "payable"
		"type": "function"
	},
	
];

  interface MintButtonProps {
	nftName: string;
	description: string;
	imageUrl: string;
	coinTicker: string;
	coinName: string;
	amount: string;
  }
  
  interface EventData {
	tokenId: string;
	owner: string;
	nftName: string;
	description: string;
	imageUrl: string;
	coinAddress: string;
  }
const StyledCard = styled(Card)(({ theme }) => ({
	transition: "transform 0.3s ease-in-out",
	'&:hover': {
	  transform: "scale(1.05)",
	  boxShadow: "0px 0px 15px rgba(192, 179, 163, 1)",
	  backgroundColor: "rgba(0, 0, 0, 1)",
	  filter: 'drop-shadow(0px 0px 15px rgba(165, 179, 163, 1))',
	  '& img': {
		transition: 'filter 0.15s ease-in-out',
	  },
	},
	minHeight: 390,
	minWidth: 280,
	maxWidth: '75%',
	transform: "scale(1.05)",
	boxShadow: "0px 0px 15px rgba(192, 179, 163, 1)",
	backgroundColor: "rgba(0, 0, 0, 1)",
	filter: 'drop-shadow(0px 0px 15px rgba(165, 179, 163, 1))',
	'& img': {
	  filter: 'drop-shadow(0px 0px 10px rgba(165, 179, 163, 0.9))',
	  transition: 'filter 0.15s ease-in-out',
	},
	wordWrap: 'break-word',
	overflowWrap: 'break-word',
	whiteSpace: 'normal', // Ensures that the text will wrap
  }));
  
  const MintButton: React.FC<MintButtonProps> = ({ nftName, description, imageUrl, coinTicker, coinName, amount }) => {
	const [open, setOpen] = useState(false);
	const [statusMessage, setStatusMessage] = useState('');
	const [statusSeverity, setStatusSeverity] = useState<'info' | 'success' | 'error'>('info');
	const [errorMessage, setErrorMessage] = useState('');
	//tokenID
	const [tokenId, setTokenId] = useState<string>('');
	const [eventData, setEventData] = useState<EventData | null>(null);
	const { data: hash, error, isPending, writeContract } = useWriteContract();
	const { isLoading: isConfirming, isSuccess: isConfirmed, data: receiptData } = useWaitForTransactionReceipt({ hash });
    const navigate = useNavigate();
	const handleClick = () => {
	  setStatusMessage('Transaction initiated...');
	  setStatusSeverity('info');
	  setOpen(true);
  
	  writeContract({
		address: CONTRACT_ADDRESS_NFT,
		abi: CONTRACT_ABI_NFT,
		functionName: 'mintNFT',
		args: [nftName, description, imageUrl, coinTicker, coinName, parseEther(amount)]//,
		//value: parseEther(amount), 
	  });

	};
  
	useEffect(() => {
		let timer: NodeJS.Timeout;
	
		if (isPending) {
		  setStatusMessage('Transaction is pending...');
		  setStatusSeverity('info');
		  setOpen(true);
		} else if (isConfirming) {
		  setStatusMessage('Transaction is confirming...');
		  setStatusSeverity('info');
		  setOpen(true);
		} else if (isConfirmed) {
		  setStatusMessage('Transaction confirmed!');
		  setStatusSeverity('success');
		  setOpen(true);
		  if (hash) {
			//window.open(`https://cheesechain.calderaexplorer.xyz/tx/${hash}`);
		  }
		
		  timer = setTimeout(() => {
			setOpen(false);
			// use transaction and https://cheesechain-testnet.explorer.caldera.xyz/tx/TXGOESHERE and open in a new window
		  }, 6000);
		
		} else if (error) {
		  setStatusMessage(`Transaction failed`);
		  setStatusSeverity('error');
		  setErrorMessage(error.message);
		  setOpen(true);
		  timer = setTimeout(() => {
			setOpen(false);
		  }, 5000);
		}
	
		return () => {
		  clearTimeout(timer);
		};
	  }, [isPending, isConfirming, isConfirmed, error]);
  
	useEffect(() => {
	  if (isConfirmed && receiptData) {
		console.log("Transaction successful with receipt:", receiptData);
		if (receiptData.logs && receiptData.logs.length > 0) {
			const createChatbotEventSignature = "CreateChatbot(uint256,address,string,string,string,address)";
			const createChatbotEventHash = ethers.utils.id(createChatbotEventSignature);
		
			const createChatbotEvent = receiptData.logs.find(log => 
			  log.topics[0] === createChatbotEventHash
			);
		
			if (createChatbotEvent) {
			  const iface = new ethers.utils.Interface([`event ${createChatbotEventSignature}`]);
			  const event = iface.parseLog(createChatbotEvent);
		
			  console.log("CreateChatbot Event Data:", event.args);
		
		} else {
		  console.log('No logs found in transaction receipt');
		}

		  // Check if there are sufficient logs and specifically log index 8 is present
		  if (receiptData.logs && receiptData.logs.length > 8) {
            // Extract the data from log index 8
            const logData = receiptData.logs[8].data;
            // Since the token ID is stored as the first 32 bytes after '0x', we can directly convert it
            const tokenId = ethers.BigNumber.from(logData).toString();
			console.log("Token ID from log 8:", tokenId);
            setTokenId(tokenId);
			window.location.href = `http://localhost:3000/chatbot/${tokenId}/admin`;
            console.log("Token ID from log 8:", tokenId);
        } else {
            console.log("Log 8 not found or insufficient logs in transaction receipt");
        }
	  }
	}
	}, [isConfirmed, receiptData]);
  
	const handleClose = () => {
	  setOpen(false);
	};

	const handleTX = () => {
		window.open(`https://cheesechain.calderaexplorer.xyz/tx/${hash}`, '_blank');
	  };
  
	return (
	  <>
		<Button
		  variant="contained"
		  color="primary"
		  disabled={isPending || isConfirming}
		  onClick={handleClick}
		  endIcon={isPending || isConfirming ? <CircularProgress size={20} /> : null}
		  sx={{ margin: "0.5rem" }}
		>
		  {isPending ? 'Confirming...' : 'Mint NFT'}
		</Button>
		<Modal
		  open={open}
		  onClose={handleClose}
		  aria-labelledby="modal-modal-title"
		  aria-describedby="modal-modal-description"
		>
		  <Box
			sx={{
			  position: "absolute",
			  top: "50%",
			  left: "50%",
			  transform: "translate(-50%, -50%)",
			  width: { xs: '90%', sm: '80%', md: '50%', lg: '40%' },
			  bgcolor: "none",
			  border: "none",
			  boxShadow: 0,
			  padding: 1,
			}}
		  >
			<Box
			  sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
			  }}
			>
			  <StyledCard>
				<Typography
				  margin={"1rem"}
				  id="modal-modal-title"
				  variant="h1"
				  fontSize="1.2rem"
				  sx={{ 
					display: "flex", 
					justifyContent: "center", 
					alignItems: "center",
					wordWrap: 'break-word',
					overflowWrap: 'break-word',
					whiteSpace: 'normal',
					maxWidth: '90%',
					textOverflow: 'ellipsis',
					overflow: 'hidden',
				  }}
				>
				  {statusMessage}
				</Typography>
				{statusSeverity === 'error' && (
				  <Typography
					margin={"1rem"}
					color="error"
					sx={{ 
					  wordWrap: 'break-word',
					  overflowWrap: 'break-word',
					  whiteSpace: 'normal',
					  maxWidth: '90%',
					}}
				  >
					{errorMessage}
				  </Typography>
				)}
				<Box margin={"1rem"} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
				  <img src={imageUrl} alt="Status" style={{ width: '30%', height: '30%' }} />
				</Box>
				{statusSeverity !== 'success' && (
				  <Box m={"1rem"} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
					<CircularProgress color="secondary" />
				  </Box>
				)}
				 {statusSeverity === 'success' && eventData && (
            <div>
              <Typography>NFT ID: {eventData.tokenId}</Typography>
              <Typography>Owner Address: {eventData.owner}</Typography>
              <Typography>NFT Name: {eventData.nftName}</Typography>
              <Typography>Description: {eventData.description}</Typography>
              <Typography>Image URL: {eventData.imageUrl}</Typography>
              <Typography>Coin Address: {eventData.coinAddress}</Typography>
            </div>
          )}
		   {statusSeverity === 'success'  && (
            	<Button onClick={handleTX} sx={{ marginTop: "1rem" }}>
				Check transaction
			  </Button>
          )}
	
			  </StyledCard>
			</Box>
		  </Box>
		</Modal>
	  </>
	);
  };
  
  export default MintButton;