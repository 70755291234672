import React, { useState, useEffect } from 'react';
import { Grid, Card, CardMedia, CardContent, Typography, Container } from '@mui/material';
import PageRedux from 'components/PageRedux';
import { useNavigate } from 'react-router-dom';

// Define the structure of the NFT metadata
interface INFTMetadata {
    tokenId: number;
    name: string;
    description: string;
    image: string;
    coinTicker: string;
    coinName: string;
}

function FrontPage() {
    const [nfts, setNfts] = useState<INFTMetadata[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
    const navigate = useNavigate();
    useEffect(() => {
        const fetchNFTs = async () => {
            try {
                const response = await fetch('https://api.chatover.wine/api/getNFTDataLatest', {
                    method: 'GET',
                  //  headers: {
                   //     'Content-Type': 'application/json'
                   // },
                   // body: JSON.stringify({ tokenIds: [1, 2, 3, 4, 5] }) // Example token IDs
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const data = await response.json() as INFTMetadata[];
                setNfts(data);
            } catch (error: any) {
                console.error('Failed to fetch NFT data:', error);
                setError('Failed to load NFT data: ' + error.message);
            }
            setLoading(false);
        };

        fetchNFTs();
    }, []);

    const handleMouseEnter = (index: number) => {
        setHoveredIndex(index);
    };

    const handleMouseLeave = () => {
        setHoveredIndex(null);
    };


    const handleCardClick = (tokenId: number) => {
      navigate(`/chatbot/${tokenId}`); // Navigate when card is clicked
  };

  function truncateString(str: string, num: number) {
    if (str.length > num) {
      return str.slice(0, num) + '...'; // Truncate the string to 'num' characters and add '...'
    } else {
      return str; // Return the string unchanged if it's short enough
    }
  }
  
     return (
        <PageRedux backgroundImage={'/media/bg/bot5.webp'}>
            <Container maxWidth="lg" sx={{ mt: 4, bgcolor: "rgba(223, 223, 233, 0.95)", p: 2 }}>
                <Typography variant="h4" align="center" gutterBottom>
                    Latest off the Vine
                </Typography>
                <Grid container spacing={2}>
                    {nfts.map((nft, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Card
                                onClick={() => handleCardClick(nft.tokenId)}
                                onMouseEnter={() => handleMouseEnter(index)}
                                onMouseLeave={handleMouseLeave}
                                sx={{
                                    transform: hoveredIndex === index ? 'translateY(-10px)' : 'none',
                                    transition: 'transform 0.3s ease',
                                }}
                            >
                                <CardMedia
                                    component="img"
                                    image={nft.image}
                                    alt={truncateString(nft.name, 50)} // Using the truncate function for alt text too
                                    sx={{
                                        height: 250,
                                        objectFit: 'contain',
                                    }}
                                />
                                <CardContent>
                                    <Typography variant="h6">{truncateString(nft.name, 20)}</Typography>
                                    <Typography variant="body1">{truncateString(nft.description, 140)}</Typography>
                                    <Typography variant="body1">Coin Ticker: {nft.coinTicker}</Typography>
                                    <Typography variant="body1">Coin Name: {nft.coinName}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </PageRedux>
    );
}

export default FrontPage;
