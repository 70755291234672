import React from 'react';
import { Box } from '@mui/material';

const TickerBox: React.FC = () => {
  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        paddingBottom: '125%',
        '@media (min-width: 1400px)': {
          paddingBottom: '65%',
        },
        '& iframe': {
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          border: 0,
        },
      }}
    >
      <iframe
        src="https://dexscreener.com/solana/5Y3RHbBMDy9qEDamQz1sGFRB767nw6AnxirtugRJ3JNV?embed=1&theme=dark"
        title="Dexscreener Embed"
        allowFullScreen
      ></iframe>
    </Box>
  );
};

export default TickerBox;