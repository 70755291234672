import React, { useState, useEffect } from 'react';
import { useAccount, useConnect, useSignMessage, useDisconnect, useReadContract } from 'wagmi';

import axios, { AxiosError } from 'axios';
import { 
  Container, 
  Typography, 
  Button, 
  Paper, 
  Box, 
  Divider, 
  Card,
  CardMedia,
  CircularProgress,
  TextField,
  Snackbar,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  SelectChangeEvent
} from '@mui/material';
import { Twitter as TwitterIcon } from '@mui/icons-material';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import Page from './Page';
import Cookies from 'js-cookie';
import PageRedux from './PageRedux';
import SEO from './SEO';



const REQUIRED_TOKEN_ID = 1;

interface User {
  twitterId: string;
  username: string;
  walletAddress?: string;
  grapes?: number;
}

interface NFTMetadata {
  attributes: Array<{ trait_type: string; value: string }>;
  description: string;
  image: string;
  name: string;
}
const ERC1155_CONTRACT_ADDRESS = '0xc27DeA0F4873A474F6592Ea71b9C4a41319bEB27';
const ERC1155_CONTRACT_ABI = [
  {
    "inputs": [
      { "name": "account", "type": "address" },
      { "name": "id", "type": "uint256" }
    ],
    "name": "balanceOf",
    "outputs": [{ "name": "", "type": "uint256" }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{ "name": "id", "type": "uint256" }],
    "name": "uri",
    "outputs": [{ "name": "", "type": "string" }],
    "stateMutability": "view",
    "type": "function"
  }
];

const TwitterLogin: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);
  const [nftMetadata, setNftMetadata] = useState<NFTMetadata | null>(null);
  const [nftMetadata2, setNftMetadata2] = useState<NFTMetadata | null>(null);
  const [message, setMessage] = useState<string>('');
  axios.defaults.withCredentials = true;
  const { address, isConnected } = useAccount();
  const { signMessageAsync } = useSignMessage()
  const { disconnect } = useDisconnect();

  const [name, setName] = useState<string>('');
  const [sex, setSex] = useState<string>('');
  const [age, setAge] = useState<string>('');



  const { data: tokenBalance, isLoading: isLoadingBalance } = useReadContract({
    address: ERC1155_CONTRACT_ADDRESS,
    abi: ERC1155_CONTRACT_ABI,
    functionName: 'balanceOf',
    args: [address, 1],
  });

  const { data: tokenUri, isLoading: isLoadingUri } = useReadContract({
    address: ERC1155_CONTRACT_ADDRESS,
    abi: ERC1155_CONTRACT_ABI,
    functionName: 'uri',
    args: [1],
  });

  const { data: tokenBalance2, isLoading: isLoadingBalance2 } = useReadContract({
    address: ERC1155_CONTRACT_ADDRESS,
    abi: ERC1155_CONTRACT_ABI,
    functionName: 'balanceOf',
    args: [address, 2],
  });

  const { data: tokenUri2, isLoading: isLoadingUri2 } = useReadContract({
    address: ERC1155_CONTRACT_ADDRESS,
    abi: ERC1155_CONTRACT_ABI,
    functionName: 'uri',
    args: [2],
  });


  
  
  
  const API_URL = 'https://api.chatover.wine';
  const REQUIRED_TOKEN_ID = 1;
  
  interface User {
    twitterId?: string;
    username?: string;
    walletAddress?: string;
    grapes?: number;
  }
  
  interface NFTMetadata {
    attributes: Array<{ trait_type: string; value: string }>;
    description: string;
    image: string;
    name: string;
  }
  const [isLinking, setIsLinking] = useState(false);
  
   // const [user, setUser] = useState<User | null>(null);
    //const [nftMetadata, setNftMetadata] = useState<NFTMetadata | null>(null);
    //const [message, setMessage] = useState<string>('');
    const [referralCodes, setReferralCodes] = useState<string[]>([]);
    const [referralCode, setReferralCode] = useState<string>('');
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  //setLoggedIn
  const [loggedIn, setLoggedIn] = useState(false);
    axios.defaults.withCredentials = true;
    //const { address, isConnected } = useAccount();
   // const { signMessageAsync } = useSignMessage();
   // const { disconnect } = useDisconnect();

  
    useEffect(() => {
      checkAuth();
    }, []);
  
    useEffect(() => {
      if (isConnected && address) {
        fetchUserFromWallet();
        fetchReferralCodes();
        if (tokenUri && tokenBalance && Number(tokenBalance) > 0) {
          fetchNFTMetadata();
         // fetchNFTMetadata2();
        }
        if (tokenUri2 && tokenBalance2 && Number(tokenBalance2) > 0) {
          //fetchNFTMetadata();
          fetchNFTMetadata2();
        }
      }
    }, [isConnected, address, tokenUri, tokenBalance,  tokenUri2, tokenBalance2]);
  
    const checkAuth = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const userParam = urlParams.get('user');
    
      if (userParam) {
        const parsedUser = JSON.parse(decodeURIComponent(userParam));
        setUser(parsedUser);
        fetchReferralCodes();
      } else {
        try {
         // const response = await axios.get(`${API_URL}/api/user`, { withCredentials: true });
          const response = await axios.get(`${API_URL}/api/userfromwallet?walletAddress=${address}`);
          if (response.data) {
            setUser(prevUser => ({ ...prevUser, ...response.data }));
            console.log('User from wallet:', response.data);  
            console.log('User from wallet:', response.data.name);
            setName(response.data.name || '');
          setSex(response.data.sex || '');
          setAge(response.data.age ? response.data.age.toString() : '');
          }
          setUser(response.data.user);
          fetchReferralCodes();
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };
  
    const fetchUserFromWallet = async () => {
      if (address) {
        try {
          const response = await axios.get(`${API_URL}/api/userfromwallet?walletAddress=${address}`);
          if (response.data) {
            setUser(prevUser => ({ ...prevUser, ...response.data }));
            console.log('User from wallet:', response.data);  
            console.log('User from wallet:', response.data.name);
            setName(response.data.name || '');
          setSex(response.data.sex || '');
          setAge(response.data.age ? response.data.age.toString() : '');
          }
        } catch (error) {
          console.error('Error fetching user data from wallet:', error);
        }
      }
    };
    const [loggedInReal, setLogin] = useState(false);
    const getToken = () => {
        const token = Cookies.get('jwtToken');
        if (token) {
         
            setLogin(true);
        } else {
            console.log('Token not found');
        }
        return token;
    };

    const setToken = (token: string, days: number) => {
        Cookies.set('jwtToken', token, { expires: days, secure: true, sameSite: 'Strict' });
        console.log('Token set:', token);
    };
    
    const clearToken = () => {
        Cookies.remove('jwtToken');
        console.log('Token cleared');
    };
useEffect(() => {
  const checkAuthStatus = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/auth-status`, { withCredentials: true });
      if (response.data.isAuthenticated) {
        setUser(response.data.user);
      }
    } catch (error) {
      console.error('Error checking auth status:', error);
    }
  };

  checkAuthStatus();
}, []);
    useEffect(() => {
        const requestInterceptor = axios.interceptors.request.use((config) => {


        const token = getToken();
      //  console.log('Token from interceptor:', token);
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
        });
    
        return () => {
        axios.interceptors.request.eject(requestInterceptor);
        };
    }, []);

  
    const handleTwitterLogin = () => {
  
      window.location.href = `${API_URL}/auth/twitter`;
    };
  
    const handleLogout = async () => {
      try {
        await axios.get(`${API_URL}/logout`);
        setUser(null);
        setReferralCodes([]);
        disconnect();
      } catch (error) {
        console.error('Error logging out:', error);
      }
    };
  
    const fetchNFTMetadata = async () => {
      if (tokenUri && tokenBalance && Number(tokenBalance) > 0) {
       // setIsLoadingNFT(true);
        try {
          const uriString = tokenUri.toString();
          let jsonData: string;
          
          if (uriString.startsWith('data:application/json;base64,')) {
            const base64Data = uriString.split(',')[1];
            jsonData = atob(base64Data);
          } else {
            jsonData = uriString;
          }
          
          const parsedMetadata = JSON.parse(jsonData) as NFTMetadata;
          console.log('Parsed NFT Metadata:', parsedMetadata);
          
          setNftMetadata(parsedMetadata);
        } catch (error) {
          console.error('Error parsing NFT metadata:', error);
          setNftMetadata(null);
        } finally {
        //  setIsLoadingNFT(false);
        }
      } else {
        setNftMetadata(null);
      }
    };
    
    const fetchNFTMetadata2 = async () => {
      if (tokenUri2 && tokenBalance2 && Number(tokenBalance2) > 0) {
       // setIsLoadingNFT(true);
        try {
          const uriString = tokenUri2.toString();
          let jsonData: string;
          
          if (uriString.startsWith('data:application/json;base64,')) {
            const base64Data = uriString.split(',')[1];
            jsonData = atob(base64Data);
          } else {
            jsonData = uriString;
          }
          
          const parsedMetadata = JSON.parse(jsonData) as NFTMetadata;
          console.log('Parsed NFT Metadata:', parsedMetadata);
          
          setNftMetadata2(parsedMetadata);
        } catch (error) {
          console.error('Error parsing NFT metadata:', error);
          setNftMetadata2(null);
        } finally {
        //  setIsLoadingNFT(false);
        }
      } else {
        setNftMetadata2(null);
      }
    };
    const fetchReferralCodes = async () => {
      if (address) {
        try {
          const response = await axios.get(`${API_URL}/api/referral-codes-by-wallet?walletAddress=${address}`);
          setReferralCodes(response.data.referralCodes);
        } catch (error) {
          console.error('Error fetching referral codes:', error);
        }
      }
    };
    const linkWallet = async () => {
      if (!isConnected || !address) {
        setMessage('Please connect your wallet first');
        return;
      }
      setIsLinking(true);
      try {
        const initResponse = await axios.post(
          `${API_URL}/initiate-wallet-link`,
          { walletAddress: address },
          { withCredentials: true }
        );
        
        const { challenge, twitterUsername } = initResponse.data;
        const messageToSign = `Link Twitter @${twitterUsername} to wallet ${address}\nChallenge: ${challenge}`;
        //  const message =   `Link Twitter @${req.user.username} to wallet ${walletAddress}\nChallenge: ${challenge}`;
        const signature = await signMessageAsync({ message: messageToSign });
  
        const completeResponse = await axios.post(
          `${API_URL}/complete-wallet-link`,
          { signature, referralCode },
          { withCredentials: true }
        );
  
        setMessage(completeResponse.data.message);
        setUser(prevUser => ({ ...prevUser, walletAddress: address }));
        setReferralCodes(completeResponse.data.referralCodes || []);
      } catch (error) {
        console.error('Failed to link wallet:', error);
        setMessage(`Failed to link wallet: ${error}`);
      }
      finally {
        setIsLinking(false);
      }
    };
   
    const handleReferralCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setReferralCode(event.target.value);
    };
  
    const copyReferralCode = (code: string) => {
      navigator.clipboard.writeText(code);
      setSnackbarMessage('Referral code copied to clipboard!');
      setSnackbarOpen(true);
    };

    
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };
  const handleSexChange = (event: SelectChangeEvent<string>) => {
    setSex(event.target.value as string);
  };

  const handleAgeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAge(event.target.value);
  };

  const submitUserData = async () => {
    if (!user?.twitterId || !user?.walletAddress) {
      setMessage('Please link both Twitter and wallet before submitting user data.');
      return;
    }
  
    try {
      const token = getToken(); // Assuming you have a getToken function
      const response = await axios.post(
        `${API_URL}/api/setUserData`,
        { name, sex, age: parseInt(age, 10) },
        { 
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
  
      setMessage(response.data.message || 'User data updated successfully');
      setUser(prevUser => ({ ...prevUser, name, sex, age: parseInt(age, 10) }));
    } catch (error) {
      console.error('Error submitting user data:', error);
      setMessage('Failed to update user data. Please try again.');
    }
  };
    const setCookie = (name: string, value: any, days: number) => {
    Cookies.set(name, value, { expires: days, secure: true, sameSite: 'Strict' });
  };

  const clearCookie = (name: string) => {
    Cookies.remove(name);
  };

  
  const handleLoginMessage = async () => {
    try {
      const message = 'To verify your wallet and login to https://Chatover.wine, sign this message';
      const signature = await signMessageAsync({ message });

      const loginResponse = await axios.post('https://api.chatover.wine/api/login', {
        signature,
        message,
        address,
      });

      if (loginResponse.data.isValid) {
        setLoggedIn(true);
        setCookie('jwtToken', loginResponse.data.token, 1);
        console.log('Login successful');
      } else {
        console.error('Login failed: Invalid signature');
      }
    } catch (error) {
      console.error('Signing failed:', error);
    }
  };
  
    return (
      <PageRedux backgroundImage={'/media/bg/bot5.webp'}>
        <Container maxWidth="sm" sx={{ mt: 4 }}>
          <Paper elevation={3} sx={{ p: 4, bgcolor: "rgba(223, 223, 233, 0.95)" }}>
            <Typography variant="h4" gutterBottom>
              User Profile
            </Typography>
            {!isConnected && (
              <ConnectButton />
            )}
            {isConnected && !user?.twitterId && (
              <Button
                variant="contained"
                color="primary"
                startIcon={<TwitterIcon />}
                onClick={handleTwitterLogin}
                fullWidth
                sx={{ mt: 2 }}
              >
                Login with Twitter
              </Button>
            )}
            {isConnected && user?.twitterId && (
              <Box mt={2}>
                <Typography variant="subtitle1">
                  Twitter: @{user.username}
                </Typography>
                <Typography variant="subtitle1">
                  Wallet Address: {user?.walletAddress || 'Not linked'}
                </Typography>
                <Divider sx={{ my: 2 }} />
                {user?.grapes !== undefined && (
                  <>
                    <Typography variant="h5">
                      Grapes: {user.grapes}
                    </Typography>
                    <Typography variant="body2">
                      Grapes are gathered from all AI activities.
                      The more grapes you have, the more rewards you can earn.
                    </Typography>
                  </>
                )}
                {isLoadingBalance || isLoadingUri ? (
                  <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <CircularProgress />
                  </Box>
                ) : nftMetadata ? (
                  <Card sx={{ mt: 2, display: 'flex', alignItems: 'center', p: 2 }}>
                    <CardMedia
                      component="img"
                      sx={{ width: 100, height: 100, objectFit: 'contain' }}
                      image={nftMetadata.image}
                      alt={nftMetadata.name}
                    />
                    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 2, flexGrow: 1 }}>
                      <Typography variant="h6">{nftMetadata.name}</Typography>
                      <Typography variant="body2" color="text.secondary">
                        {nftMetadata.description}
                      </Typography>
                      {nftMetadata.attributes && nftMetadata.attributes.length > 0 && (
                        <Typography variant="body2" color="text.secondary">
                          {nftMetadata.attributes.map(attr => `${attr.trait_type}: ${attr.value}`).join(', ')}
                        </Typography>
                      )}
                    </Box>
                  </Card>
                ) : (
                  <Typography variant="body2" sx={{ mt: 2 }}>
                    No NFT found for ID 1
                  </Typography>
                )}
                {isLoadingBalance2 || isLoadingUri2 ? (
                  <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <CircularProgress />
                  </Box>
                ) : nftMetadata2 ? (
                  <Card sx={{ mt: 2, display: 'flex', alignItems: 'center', p: 2 }}>
                    <CardMedia
                      component="img"
                      sx={{ width: 100, height: 100, objectFit: 'contain' }}
                      image={nftMetadata2.image}
                      alt={nftMetadata2.name}
                    />
                    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 2, flexGrow: 1 }}>
                      <Typography variant="h6">{nftMetadata2.name}</Typography>
                      <Typography variant="body2" color="text.secondary">
                        {nftMetadata2.description}
                      </Typography>
                      {nftMetadata2.attributes && nftMetadata2.attributes.length > 0 && (
                        <Typography variant="body2" color="text.secondary">
                          {nftMetadata2.attributes.map(attr => `${attr.trait_type}: ${attr.value}`).join(', ')}
                        </Typography>
                      )}
                    </Box>
                  </Card>
                ) : (
                  <Typography variant="body2" sx={{ mt: 2 }}>
                    No NFT found for ID 2
                  </Typography>
                )}
                {!user?.walletAddress && (
                  <>
                    <TextField
                      fullWidth
                      label="Referral Code (required with no NFT)"
                      variant="outlined"
                      value={referralCode}
                      onChange={handleReferralCodeChange}
                      sx={{ mt: 2 }}
                    />
                    <Button 
                      variant="contained" 
                      color="primary" 
                      onClick={linkWallet} 
                      fullWidth 
                      sx={{ mt: 2 }}
                      disabled={isLinking}
                    >
                      {isLinking ? (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <CircularProgress size={24} color="inherit" sx={{ mr: 1 }} />
                          Linking Wallet...
                        </Box>
                      ) : (
                        'Link Wallet'
                      )}
                    </Button>
                  </>
                )}
                {referralCodes.length > 0 && (
                  <Box mt={2}>
                    <Typography variant="h6">Your Referral Codes:</Typography>
                    {referralCodes.map((code, index) => (
                      <Button
                        key={index}
                        variant="contained"
                        onClick={() => copyReferralCode(code)}
                        fullWidth
                        sx={{ mt: 1 }}
                      >
                        {code}
                      </Button>
                    ))}
                  </Box>
                )}
              </Box>
            )}
            {message && (
              <Typography variant="body2" sx={{ mt: 2, textAlign: 'center' }}>
                {message}
              </Typography>
            )}
            {isConnected && user?.twitterId && user?.walletAddress && (
              <Box mt={2}>
                <Typography variant="h6">Complete Your Profile</Typography>
                <TextField
                  fullWidth
                  label="Chat Name"
                  variant="outlined"
                  value={name}
                  onChange={handleNameChange}
                  sx={{ mt: 2 }}
                />
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel>Sex</InputLabel>
                  <Select
                    value={sex}
                    label="Sex"
                    onChange={handleSexChange}
                  >
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="female">Female</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  label="Age"
                  variant="outlined"
                  type="number"
                  value={age}
                  onChange={handleAgeChange}
                  sx={{ mt: 2 }}
                />
                {loggedIn ? (
                  <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={submitUserData} 
                    fullWidth 
                    sx={{ mt: 2 }}
                  >
                    Update Profile
                  </Button>
                ) : (
                  <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={handleLoginMessage} 
                    fullWidth 
                    sx={{ mt: 2 }}
                  >
                    Login
                  </Button>
                )}
                <Button 
                  variant="contained" 
                  color="secondary" 
                  onClick={handleLogout} 
                  fullWidth 
                  sx={{ mt: 2 }}
                >
                  Logout
                </Button>
              </Box>
            )}
          </Paper>
        </Container>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={() => setSnackbarOpen(false)}
          message={snackbarMessage}
        />
      </PageRedux>
    );
}

  
  export default TwitterLogin;