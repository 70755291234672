import React, { useEffect, useState } from 'react';
import { useSignMessage, useAccount } from 'wagmi';
import { Button, Box, CircularProgress, Typography } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import axios from 'axios';
import { ProfileData } from '../hooks/useProfile';
import { url } from 'inspector';
import { URL } from 'url';
import { toast } from 'react-toastify';
import { TermsPopup } from './TermsPopup';

// Inside the DiscordLogin component...


const keker = "media/icons/discord.svg"
//import { useSignMessage, useAccount } from // Import the necessary hooks
interface DiscordLoginProps {
  profileData: ProfileData | null;
  setProfileData: React.Dispatch<React.SetStateAction<ProfileData | null>>;
}

const DiscordLogin: React.FC<DiscordLoginProps> = ({ profileData, setProfileData }) => {
const [tosDialogOpen, setTosDialogOpen] = useState(false);
  // ...rest of the code
  
 // const [profileData, setProfileData] = useState<any>(null);
 //const { signMessage } = useSignMessage() ;
  const { data, error, status , signMessage } = useSignMessage();
  const { address } = useAccount();
  const [accessToken, setAccessToken] = useState<string | null>(null);

 
  useEffect(() => {
    
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('connectdiscord')) {
      console.log("connet found");
      setAccessToken(urlParams.get('connectdiscord'));

    }
  }, []);

  useEffect(() => {
    if (address) {
      fetchProfileData(address);
    }
  }, [accessToken, address]);

  const fetchProfileData = async (walletAddress: string | undefined) => {
    // Fetch user profile data
    const response = await fetch(`https://api.sagetowers.com/getprofile/${walletAddress}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const profile = await response.json();
      setProfileData(profile);
    }
  };

  const handleLoginWithDiscord = () => {
   // window.location.href = 'https://api.sagetowers.com/auth/discord';
    setTosDialogOpen(true);
  };

  async function fetchDiscordAccessToken() {
    try {
      // Fetch the access token from the backend
      console.log("fetching discord access token");
      const response = await axios.get('https://api.sagetowers.com/getDiscordAccessToken');
      setAccessToken(response.data.accessToken);
    } catch (error) {
      console.error('Error fetching access token:', error);
    }
  }
  const handleSubmit = async () => {
    if (!data) {
      toast.error(`Please sign the message with your wallet before submitting.`, {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    const signature = data;
    const walletAddress = address;

    // Send the signature, wallet address, and accessToken to the backend
    const response = await fetch('https://api.sagetowers.com/verifyDiscordSignature', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        accessToken,
        signature,
        walletAddress,
      }),
    });

    if (response.ok) {
      fetchProfileData(walletAddress);
      toast.success(`Wallet address successfully verified and updated.`, {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      //const error = await response.json();
      toast.error('An error occurred.', {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  useEffect(() => {
    if (data) {
      handleSubmit();
    }
  }, [data]);


  return (
    <Box
    >
      {profileData && (
        <>
        {profileData.discordTag && (
          <>
          <Typography variant="h1" sx={{ mb: 0.5 , mt: 0.5 }}>
           Discord: {profileData.discordTag } 
          </Typography>
          </>
        )}
        {!profileData.discordTag && (
          <>
          <Button
        disabled={status === "pending"}
        onClick={(event) => {
          event.preventDefault();
          if (!accessToken) {
            handleLoginWithDiscord();
          } else {
            signMessage({
              message: `By signing this message and verifying ownership of your Discord account (${accessToken}) you agree to the Sage Towers terms of service and privacy policy.`,
            });
          }
        }}
        variant="contained"
        color="primary"
        size="large"
        startIcon={<img src="media/icons/discord.svg" alt="Discord" style={{ width: '24px', height: '24px' }} />}
        sx={{
          textTransform: 'none',
          fontWeight: 'bold',
         
          mb: 1,
        }}
      >
        {status === "pending" ? (
          <CircularProgress size={24} />
        ) : (
          accessToken ? 'Sign Message' : 'Login with Discord'
        )}
      </Button>
          </>
        )}
        </>
      )}
       {!profileData && (
        <>
      <Button
        disabled={status === "pending"}
        onClick={(event) => {
          event.preventDefault();
          if (!accessToken) {
            handleLoginWithDiscord();
          } else {
            signMessage({
              message:`By signing this message and verifying ownership of your Discord account (${accessToken}) you agree to the Sage Towers terms of service and privacy policy.`

            });
          }
        }}
        variant="contained"
        color="primary"
        size="large"
        startIcon={<img src="media/icons/discord.svg" alt="Discord" style={{ width: '24px', height: '24px' }} />}
     
        sx={{
          textTransform: 'none',
          fontWeight: 'bold',
          
          mb: 1,
        }}
      >
        {status === "pending" ? (
          <CircularProgress size={24} />
        ) : (
          accessToken ? 'Sign Message' : 'Login with Discord'
        )}
      </Button>
       </>
      )}
      {data && <input type="hidden" value={data} />}
      <TermsPopup open={tosDialogOpen} handleClose={() => {
  // Handle the user accepting the terms of service here (e.g., by redirecting them to login with Discord)
  setTosDialogOpen(false);
  window.location.href = 'https://api.sagetowers.com/auth/discord';
}} />
    </Box>
  );
};

export default DiscordLogin;
