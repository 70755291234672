import * as React from 'react';
import { useAccount, useBalance, useReadContract } from 'wagmi';
import { Box, Typography, CircularProgress } from '@mui/material';
import { BigNumber, utils } from 'ethers';

const CONTRACT_ADDRESS = '0x56e5776B59c3dfB42bA9B45733Cf618F6D128Ec5';
const CONTRACT_ABI = [
  {
    "constant": true,
    "inputs": [{ "name": "_owner", "type": "address" }],
    "name": "balanceOf",
    "outputs": [{ "name": "balance", "type": "uint256" }],
    "type": "function"
  },
];

const WalletBalance = () => {
  const { address } = useAccount();
  const { data: baseTokenBalance, refetch: refetchBaseTokenBalance } = useBalance({ address });
  const { data: tokenBalance, refetch: refetchTokenBalance } = useReadContract({
    address: CONTRACT_ADDRESS,
    abi: CONTRACT_ABI,
    functionName: 'balanceOf',
    args: [address],
  });

  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<string | null>(null);

  const fetchBalances = async () => {
    try {
      setLoading(true);
      await refetchBaseTokenBalance();
      await refetchTokenBalance();
      setLoading(false);
    } catch (err: unknown) {
      setError((err as Error).message);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (address) {
      fetchBalances();
      const intervalId = setInterval(fetchBalances, 30000); // Refresh every 60 seconds

      return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }
  }, [address]);

  if (loading) {
    return ;
  }

  if (error) {
    return ;
  }

  const formatBalance = (balance: BigNumber | bigint) => {
    const bigNumberBalance = BigNumber.isBigNumber(balance) ? balance : BigNumber.from(balance);
    return parseFloat(utils.formatEther(bigNumberBalance)).toFixed(3);
  };

  return (
    <Box display="flex" alignItems="center" ml={2}>
      <Typography variant="body1" mr={1}>
        {`Cheese: ${baseTokenBalance ? formatBalance(baseTokenBalance.value) : '0.00'}`}
      </Typography>
      <Typography variant="body1">
        {`Wine: ${tokenBalance ? formatBalance(BigNumber.from(tokenBalance)) : '0.00'}`}
      </Typography>
    </Box>
  );
};

export default WalletBalance;
