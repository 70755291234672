import React, { useRef, useEffect } from 'react';
import { List, ListItem, ListItemText } from '@mui/material';
import { CSSProperties } from 'react';

type Chat = {
  id: number;
  text: string;
};

interface ChatHistoryProps {
  chats: Chat[];
}

const ChatHistory: React.FC<ChatHistoryProps> = ({ chats }) => {
  const listRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = listRef.current.scrollHeight;
    }
  }, [chats]);

  const style: CSSProperties = {
    maxHeight: '300px',
    overflowY: 'auto',
    background: 'rgba(255, 255, 255, 0.8)',
    padding: '10px',
    scrollBehavior: 'smooth'
  };

  const listItemStyle: CSSProperties = {
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap'
  };

  return (
    <List ref={listRef} style={style} sx={{ mt: 4, bgcolor: "rgba(223, 223, 233, 0.0)" }}>
      {chats.map((chat) => (
        <ListItem key={chat.id} divider style={listItemStyle}>
          <ListItemText primary={chat.text} />
        </ListItem>
      ))}
    </List>
  );
};

export default ChatHistory;