import { http, createConfig } from 'wagmi'
import { mainnet, sepolia,arbitrumSepolia,arbitrumNova} from 'wagmi/chains'
export const EXPECTED_CHAIN_ID = Number(0xa4ba)//Number(0x66eed)0xa4ba // nova 0xa4ba //test 0x66eee
export const GRAPHQL_URL = process.env.REACT_APP_GRAPHQL_URL
export const INFURA_KEY = process.env.REACT_APP_INFURA_KEY || ""
export const CORE_CONTRACT_ADDRESS = process.env.REACT_APP_CORE_CONTRACT_ADDRESS as `0x${string}` | undefined;
export const MINT_CONTRACT_ADDRESS = process.env.REACT_APP_MINT_CONTRACT_ADDRESS as `0x${string}` | undefined;

export const VOIDRITE_ADDRESS = process.env.REACT_APP_VOIDRITE_ADDRESS as `0x${string}` | undefined;
export const TOKEN_URL = process.env.REACT_APP_TOKEN_URL
export const MEDIA_URL = process.env.REACT_APP_MEDIA_URL
export const GENERATOR_URL = process.env.REACT_APP_GENERATOR_URL
export const PROJECTS_PER_PAGE = 8
export const TOKENS_PER_PAGE = 9
export const MULTIPLY_GAS_LIMIT = 1.1


export const config = createConfig({
  chains: [arbitrumSepolia,arbitrumNova],
  transports: {
    [arbitrumSepolia.id]: http(),
    [arbitrumNova.id]: http(),
  },
})
//import { arbitrumSepolia, arbitrum } from "@wagmi/core/chains";