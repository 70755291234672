import Page from 'components/Page';
import { useParams } from 'react-router-dom';
import React, { ChangeEvent, FormEvent, useState, useEffect, useCallback } from 'react';
import { Container, Box, TextField, Button, Typography, CircularProgress, Modal, Card, styled, Link } from '@mui/material';
import { useWriteContract, useWaitForTransactionReceipt, useReadContract, useAccount } from 'wagmi';
import axios from 'axios';
import { BigNumber, ethers } from 'ethers';
import ChatMintButton from './ChatMintButton';
import ApproveButton from './ApproveButton'; // Adjust the import path as necessary
import PageRedux from 'components/PageRedux';
import MemecoinStore from './MemecoinStore';
import { useAuth } from 'components/AuthContext';
import Cookies from 'js-cookie';


const CONTRACT_ADDRESS = '0x56e5776B59c3dfB42bA9B45733Cf618F6D128Ec5';
const CONTRACT_ABI = [
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "spender",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "value",
        "type": "uint256"
      }
    ],
    "name": "approve",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "owner",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "spender",
        "type": "address"
      }
    ],
    "name": "allowance",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
];

const CONTRACT_ADDRESS_NFT = '0xA8675eaacFCB964a81e360B857e06dbedc2F003d';
const CONTRACT_ABI_NFT = [
  {
      "inputs": [
          {
              "internalType": "uint256",
              "name": "tokenId",
              "type": "uint256"
          }
      ],
      "name": "tokenURI",
      "outputs": [
          {
              "internalType": "string",
              "name": "",
              "type": "string"
          }
      ],
      "stateMutability": "view",
      "type": "function"
  },
  {
      "inputs": [
          {
              "internalType": "uint256",
              "name": "tokenId",
              "type": "uint256"
          }
      ],
      "name": "getTokenAddress",
      "outputs": [
          {
              "internalType": "address",
              "name": "",
              "type": "address"
          }
      ],
      "stateMutability": "view",
      "type": "function"
  },
  {
      "inputs": [
          {
              "internalType": "uint256",
              "name": "tokenId",
              "type": "uint256"
          }
      ],
      "name": "ownerOf",
      "outputs": [
          {
              "internalType": "address",
              "name": "",
              "type": "address"
          }
      ],
      "stateMutability": "view",
      "type": "function"
  }
];

const StyledCard = styled(Card)(({ theme }) => ({
  transition: "transform 0.3s ease-in-out",
  '&:hover': {
    transform: "scale(1.05)",
    boxShadow: "0px 0px 15px  rgba(192, 179, 163, 1)",
    backgroundColor: "rgba(0, 0, 0, 1)",
    filter: 'drop-shadow(0px 0px 15px rgba(165, 179, 163, 1))',
    '& img': {
      transition: 'filter 0.15s ease-in-out',
    },
  },
  minHeight: 390,
  minWidth: 280,
  transform: "scale(1.05)",
  boxShadow: "0px 0px 15px  rgba(192, 179, 163, 1)",
  backgroundColor: "rgba(0, 0, 0, 1)",
  filter: 'drop-shadow(0px 0px 15px rgba(165, 179, 163, 1))',
  '& img': {
    filter: 'drop-shadow(0px 0px 10px rgba(165, 179, 163,  0.9))',
    transition: 'filter 0.15s ease-in-out',
  },
}));

interface NFTMetadata {
  name: string;
  description: string;
  image: string;
  attributes: { trait_type: string; value: string }[];
}

const ChatbotNFTAdmin: React.FC = () => {
  const [prompt, setPrompt] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [imagePrompt, setImagePrompt] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [twitter, setTwitter] = useState('');
  const [telegram, setTelegram] = useState('');
  const [website, setWebsite] = useState('');
  const [loggedIn, setLogin] = useState(false);
  const [message, setMessage] = useState('');

  const getPathTokenID = () => {
    const pathname = window.location.pathname;
    const segments = pathname.split('/');
    return segments[2];
  }

  const tokenID = getPathTokenID();
  const { data: tokenUri } = useReadContract({
    address: CONTRACT_ADDRESS_NFT,
    abi: CONTRACT_ABI_NFT,
    functionName: 'tokenURI',
    args: [tokenID],
  });

  const [nftMetadata, setNftMetadata] = useState<NFTMetadata | null>(null);
  const [image, setImage] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  useEffect(() => {
    if (tokenUri) {
      const jsonPart = tokenUri.toString().split(',')[1];
      if (jsonPart) {
        const decodedJson = atob(jsonPart);
        const parsedMetadata = JSON.parse(decodedJson);
        setNftMetadata(parsedMetadata);
      }
    }
  }, [tokenUri]);

  
 
// Util to get the JWT token from the cookie
    const getToken = () => {
        const token = Cookies.get('jwtToken');
       // console.log('Token from cookies:', token); // Check if token is being retrieved
        //if token isnt undefined
        if (token) {
           // console.log('Token:', token);
            setLogin(true);
        } else {
            console.log('Token not found');
        }
        return token;
    };

    const setToken = (token: string, days: number) => {
        Cookies.set('jwtToken', token, { expires: days, secure: true, sameSite: 'Strict' });
       // console.log('Token set:', token);
    };
    
    const clearToken = () => {
        Cookies.remove('jwtToken');
       // console.log('Token cleared');
    };

    useEffect(() => {
        const requestInterceptor = axios.interceptors.request.use((config) => {


        const token = getToken();
        console.log('Token from interceptor:', token);
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
        });
    
        return () => {
        axios.interceptors.request.eject(requestInterceptor);
        };
    }, []);

  const fetchNFTData = async (tokenId: string) => {
    try {
        const response = await fetch(`https://api.chatover.wine/api/getNFTExtra?tokenId=${tokenId}`);
        const data = await response.json();
        if (response.ok) {
            console.log('NFT Data:', data);
            return data;
        } else {
            console.log('Error fetching NFT:', data.message);
            return null;
        }
    } catch (error) {
        console.error('Network error:', error);
        return null;
    }
}


  useEffect(() => {
    if (tokenID) {
        fetchNFTData(tokenID).then((nftData: any) => {
            if (nftData) {
                // Set state with fetched data
                console.log('NFT Loaded:', nftData);
            }
        });
    }
}, [tokenID]);

  const handleInputChange = (event: { target: { name: any; value: any; }; }) => {
    const { name, value } = event.target;
    switch (name) {
      case 'prompt':
        setPrompt(value);
        break;
      case 'imageUrl':
        setImageUrl(value);
        break;
      case 'imagePrompt':
        setImagePrompt(value);
        break;
      case 'twitter':
        setTwitter(value);
        break;
      case 'telegram':
        setTelegram(value);
        break;
      case 'website':
        setWebsite(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    setSubmitting(true);
    const updateData = {
      tokenId: tokenID,
      systemPrompt: prompt, // Assuming 'prompt' corresponds to 'systemPrompt' in your DB model
      botPrompt: prompt,   // Change as necessary if you need a separate field
      imagePrompt: imagePrompt,
      twitter: twitter,
      telegram: telegram,
      website: website
    };
//console.log(updateData);
    try {
        const token = getToken();
      const response = await fetch('https://api.chatover.wine/api/updateNFTExtra', {
        method: 'POST',
        headers: {
             'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updateData),
      });
//console.log(response);
      const data = await response.json();
      if (response.ok) {
        setMessage('NFT updated successfully');
      } else {
        setMessage('Failed to update NFT: ' + data.message);
      }
    } catch (error) {
      setMessage('Failed to update NFT');
    } finally {
      setSubmitting(false);
    }
  };

  const handleImageChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const selectedFile = event.target.files[0];
      setImage(selectedFile);
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(selectedFile);
      const url = await uploadImage(selectedFile);
      setImageUrl(url);
    }
  };

  const uploadImage = async (file: File): Promise<string> => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post(`https://api.chatover.wine/api/nft-upload-image/${tokenID}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('Image uploaded:', response.data.uploadResult.url);
      return response.data.uploadResult.url;
    } catch (error) {
      console.error('Error uploading image:', error);
      return "";
    }
  };
  const URL = "../chatbot/"+tokenID;
  return (
    <PageRedux backgroundImage={'/media/bg/bot5.webp'}>
    <Container maxWidth="sm" sx={{ mt: 4, p: 2, bgcolor: 'rgba(223, 223, 233, 0.95)' }}>
      <Box display="flex" flexDirection="column" alignItems="center">
      <Button variant="contained" color="primary" href = {URL} sx={{ m: 1 }}>
          Back to Main
        </Button>
        {nftMetadata && loggedIn && (
          <>
            <Typography variant="h1" gutterBottom>
              {nftMetadata.name}
            </Typography>
            <img src={nftMetadata.image} alt={nftMetadata.name} style={{ maxWidth: '50%', borderRadius: '10px' }} />
            <Typography variant="body1">
              {nftMetadata.description}
            </Typography>
            <form onSubmit={handleSubmit}>
            <TextField label="Prompt" name="prompt" value={prompt} onChange={handleInputChange} fullWidth sx={{ mt: 2 }} />
            <Box sx={{ position: 'relative', overflow: 'hidden', borderRadius: 1, '& input': { position: 'absolute', opacity: 0, top: 0, right: 0, bottom: 0, left: 0, width: '100%', height: '100%', cursor: 'pointer' } }}>
            <Button variant="contained" component="label" htmlFor="image-upload"     sx={{ mt: 2 }}>Upload Background Image</Button>
            <input id="image-upload" type="file" onChange={handleImageChange} />    
            <Box sx={{mt: 2, position: 'relative', overflow: 'hidden', borderRadius: 1, '& input': { position: 'absolute', opacity: 0, top: 0, right: 0, bottom: 0, left: 0, width: '100%', height: '100%', cursor: 'pointer' } }}>
            
             {imagePreview && <img src={imagePreview} alt="Image Preview" style={{ maxWidth: '100%', marginBottom: '10px' }} />} </Box>
          </Box>
     
        
          <TextField label="Twitter Username" name="twitter" value={twitter} onChange={handleInputChange} fullWidth sx={{ mt: 2 }} />
        
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={submitting}
                onClick={handleSubmit}
                sx={{ mt: 2 }}
              >
                Submit
              </Button>
            </form>
          </>
        )}
        {!loggedIn && (
  <Typography variant="h1">
  Log in to make changes!
</Typography>
)}
      </Box>
    </Container>
    </PageRedux>
  );
};

export default ChatbotNFTAdmin;

