import { Button } from "@mui/material";
import React from "react";
import { useAccount } from "wagmi";
import { switchNetwork } from '@wagmi/core';

//make props interface for window

//window: globalThis.Window & typeof globalThis
const AddXTokenButton = () => {
 // const { chain } = useNetwork();
const { chain } = useAccount(); 
    const tokenAddress= "0xa69B4933138EBCCa895963Dd05E24D47cb012eF4"; // The address of the token.
    const       tokenSymbol="XWINE"; // A ticker symbol or shorthand, up to 5 characters.
    const       tokenDecimals= 18; // The number of decimals in the token.
    const       tokenImage= "https://storage.fleek-internal.com/94da0b0e-3d2c-46dc-a94c-df68eb5eec1b-bucket/Untitled (27).png";
  const addToken = async () => { 
    
    if(window.ethereum){
    try {
      
      // Request user's account if not already connected const handleSwitchNetwork = async () => {
    
   
     
   
      const accounts = await window.ethereum.request({ method: "eth_requestAccounts" });
/*
if(chain?.id !== 0x55d76c9) {
      const network = await switchNetwork({
        chainId: 0x55d76c9,
      });}
*/
      const isAdded = await window.ethereum.request({
       
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals: tokenDecimals,
            image: tokenImage,
         
          },
        
        },
      });

      if (isAdded) {
        console.log("Token added!");
      } else {
        console.log("Token not added!");
      }
    } catch (error) {
      console.log(error);
    }
}
  };

  return <Button variant="contained" onClick={addToken} sx={{ margin:"1rem"}} >Add $XWINE to Wallet</Button>;
};

export default AddXTokenButton;
