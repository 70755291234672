import Page from 'components/Page';
import { useParams } from 'react-router-dom';
import React, { ChangeEvent, FormEvent, useState, useEffect, useCallback } from 'react';
import { Container, Box, TextField, Button, Typography, CircularProgress, Modal, Card, styled, Link, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Snackbar, Alert } from '@mui/material';
import { useWriteContract, useWaitForTransactionReceipt, useReadContract, useAccount } from 'wagmi';
import axios from 'axios';
import { BigNumber, ethers } from 'ethers';
import ChatMintButton from './ChatMintButton';
import ApproveButton from './ApproveButton'; // Adjust the import path as necessary
import PageRedux from 'components/PageRedux';
import MemecoinStore from './MemecoinStore';
import { useAuth } from 'components/AuthContext';
import Cookies from 'js-cookie';
import TopSelections from 'components/TopSelections';
import ChatHistory from 'components/ChatHistory';
import BotCard from '../components/BotCard'; // Path to your BotCard component
import { start } from 'repl';
import NFTGate from './NFTGate';
interface Bot {
    name: string;
    description: string;
    imageUrl: string;
    backgroundImage: string;
    rating?: number;  // Optional initial rating for each bot
    memePrompt?:string;
  }
  
  interface MainContentState {

    botBackgroundImage: string;
    botImage: string;
    botName: string;
    botDescription: string;
    botPrompt: string;
    chatHistory: ChatItem[];
    chatHistoryReal: ChatItem[];
    
  }
  interface TopSelectionsProps {
      name: string;
      setName: React.Dispatch<React.SetStateAction<string>>;
      sex: string;
      setSex: React.Dispatch<React.SetStateAction<string>>;
      isMature: boolean;
      setIsMature: React.Dispatch<React.SetStateAction<boolean>>;
      voice: boolean;
      setVoice: React.Dispatch<React.SetStateAction<boolean>>;
    }
  
  interface ChatPageProps {
    botIndex?: number;
    onBackToLeaderboard: () => void;
  }

const CONTRACT_ADDRESS = '0x56e5776B59c3dfB42bA9B45733Cf618F6D128Ec5';
const CONTRACT_ABI = [
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "spender",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "value",
        "type": "uint256"
      }
    ],
    "name": "approve",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "owner",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "spender",
        "type": "address"
      }
    ],
    "name": "allowance",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
];

interface Voice {
    id: string;
}

interface VoiceDropdownProps {
    // Define any props here if needed. For now, we assume no props.
}
interface ChatItem {
    id: number;
    text: string;
    user: string;
  }
  interface State {
    chatHistory: ChatItem[];
    // ... other state properties
  }
const CONTRACT_ADDRESS_NFT = '0xA8675eaacFCB964a81e360B857e06dbedc2F003d';
const CONTRACT_ABI_NFT = [
  {
      "inputs": [
          {
              "internalType": "uint256",
              "name": "tokenId",
              "type": "uint256"
          }
      ],
      "name": "tokenURI",
      "outputs": [
          {
              "internalType": "string",
              "name": "",
              "type": "string"
          }
      ],
      "stateMutability": "view",
      "type": "function"
  },
  {
      "inputs": [
          {
              "internalType": "uint256",
              "name": "tokenId",
              "type": "uint256"
          }
      ],
      "name": "getTokenAddress",
      "outputs": [
          {
              "internalType": "address",
              "name": "",
              "type": "address"
          }
      ],
      "stateMutability": "view",
      "type": "function"
  },
  {
      "inputs": [
          {
              "internalType": "uint256",
              "name": "tokenId",
              "type": "uint256"
          }
      ],
      "name": "ownerOf",
      "outputs": [
          {
              "internalType": "address",
              "name": "",
              "type": "address"
          }
      ],
      "stateMutability": "view",
      "type": "function"
  }
];

const StyledCard = styled(Card)(({ theme }) => ({
  transition: "transform 0.3s ease-in-out",
  '&:hover': {
    transform: "scale(1.05)",
    boxShadow: "0px 0px 15px  rgba(192, 179, 163, 1)",
    backgroundColor: "rgba(0, 0, 0, 1)",
    filter: 'drop-shadow(0px 0px 15px rgba(165, 179, 163, 1))',
    '& img': {
      transition: 'filter 0.15s ease-in-out',
    },
  },
  minHeight: 390,
  minWidth: 280,
  transform: "scale(1.05)",
  boxShadow: "0px 0px 15px  rgba(192, 179, 163, 1)",
  backgroundColor: "rgba(0, 0, 0, 1)",
  filter: 'drop-shadow(0px 0px 15px rgba(165, 179, 163, 1))',
  '& img': {
    filter: 'drop-shadow(0px 0px 10px rgba(165, 179, 163,  0.9))',
    transition: 'filter 0.15s ease-in-out',
  },
}));

interface NFTMetadata {
  name: string;
  description: string;
  image: string;
  attributes: { trait_type: string; value: string }[];
}

interface Voice {
    id: string;
    alias_of: null | string;
    title: null | string;
    supported_models: string[];
}

const ChatbotRoom: React.FC = () => {

  const [loggedIn, setLogin] = useState(false);

  const getPathTokenID = () => {
    const pathname = window.location.pathname;
    const segments = pathname.split('/');
    return segments[2];
  }

  const tokenID = getPathTokenID();
  const { data: tokenUri } = useReadContract({
    address: CONTRACT_ADDRESS_NFT,
    abi: CONTRACT_ABI_NFT,
    functionName: 'tokenURI',
    args: [tokenID],
  });
  useEffect(() => {
    if (tokenUri) {
      console.log("Token URI: " + tokenUri);
      const jsonPart = tokenUri.toString().split(',')[1]; // Get the Base64 encoded JSON
      if (jsonPart) {
        const decodedJson = atob(jsonPart); // Decode Base64
        const parsedMetadata = JSON.parse(decodedJson); // Parse the JSON
        setNftMetadata(parsedMetadata); // Set the parsed metadata to state
        state.botName=parsedMetadata.name;
        state.botDescription=parsedMetadata.description;
        state.botImage=parsedMetadata.image;
      }
    }

  }, [tokenUri]);
  const [nftMetadata, setNftMetadata] = useState<NFTMetadata | null>(null);

    const getToken = () => {
        const token = Cookies.get('jwtToken');
        if (token) {
         
            setLogin(true);
        } else {
            console.log('Token not found');
        }
        return token;
    };

    const setToken = (token: string, days: number) => {
        Cookies.set('jwtToken', token, { expires: days, secure: true, sameSite: 'Strict' });
        console.log('Token set:', token);
    };
    
    const clearToken = () => {
        Cookies.remove('jwtToken');
        console.log('Token cleared');
    };

    useEffect(() => {
        const requestInterceptor = axios.interceptors.request.use((config) => {


        const token = getToken();
       // console.log('Token from interceptor:', token);
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
        });
    
        return () => {
        axios.interceptors.request.eject(requestInterceptor);
        };
    }, []);
    const [bg, setBG] = useState('');
    const [error, setError] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
      };
  const fetchNFTData = async (tokenId: string) => {
    try {
        const response = await fetch(`https://api.chatover.wine/api/getNFTExtraImage?tokenId=${tokenId}`);
        const data = await response.json();
        if (response.ok) {
            console.log('NFT Data:', data);
            state.botBackgroundImage=data;
            setBG(data);
            console.log('NFT Data:', state.botBackgroundImage);
            return data;
        } else {
            console.log('Error fetching NFT:', data.message);
            return null;
        }
    } catch (error) {
        console.error('Network error:', error);
        return null;
    }
}
const [voices, setVoices] = useState<Voice[]>([]);

useEffect(() => {
    axios.get('https://api.chatover.wine/api/getvoices')
        .then(response => {
            setVoices(response.data);
            if (response.data.length > 0) {
                setSelectedVoice("us-male-6");
              }
        })
        .catch(error => {
            console.error('Failed to load voices', error);
        });
}, []);

  useEffect(() => {
    if (tokenID) {
        fetchNFTData(tokenID).then((nftData: any) => {
            if (nftData) {
                // Set state with fetched data
                console.log('NFT Loaded:', nftData);
            }
        });
    }
}, [tokenID]);
const [selectedVoice, setSelectedVoice] = useState<string>('');
const [name, setName] = useState('');
const [sex, setSex] = useState('Male'); 
const [isMature, setIsMature] = useState(false);
const [voice, setVoice] = useState(true);
const [chatInput, setChatInput] = useState('');
const [loading, setLoading] = useState(false);
const [audioUrl, setAudioUrl] = useState('');
const [voiceChoice, setVoiceChoice] = useState('');
const [state, setState] = React.useState<MainContentState>({
  botBackgroundImage: '',
  botImage: '',
  botName: '',
  botDescription: '',
  botPrompt: '',
  chatHistory: [],
  chatHistoryReal: [],
});


const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  setChatInput(event.target.value);
};

const handleSendChat = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!chatInput.trim()) return;
  
    setLoading(true);
  
    // Format the chat history correctly
    const formattedHistory = state.chatHistoryReal.map(chat => ({
      role: chat.user === state.botName ? "assistant" : "user",
      content: chat.text
    }));
  
    try {
      const response = await axios.post('https://api.chatover.wine/api/test/chatbot', {
        message: chatInput,
        pastChat: formattedHistory,
        userInfo: {
          name,
          sex,
          isMature,
          voice,
        },
        botId: tokenID,
        voiceChoice: selectedVoice
      });
  
      const newChatsReal = [
        ...state.chatHistoryReal,
        { id: state.chatHistoryReal.length + 1, text: chatInput, user: name },
        { id: state.chatHistoryReal.length + 2, text: response.data.message, user: state.botName }
      ];
  
      const newChats = newChatsReal.map(chat => ({
        id: chat.id,
        text: `${chat.user}: ${chat.text}`,
        user: chat.user
      }));
  
      setState(prevState => ({
        ...prevState,
        chatHistory: newChats,
        chatHistoryReal: newChatsReal,
      }));
  
      if (response.data.audioUrl) {
        console.log('Audio URL:', response.data.audioUrl);
        setAudioUrl(response.data.audioUrl);
      }
    } catch (error) {
        if (axios.isAxiosError(error) && error.response && error.response.status === 403) {
            if(nftMetadata){
            setError(`You need to hold 10,000 ${nftMetadata?.name} coins to use this.`);
            setOpenSnackbar(true);
            }
        }
      console.error('Failed to send message:', error);
    } finally {
      setChatInput('');
      setLoading(false);
    }
  };

const handleButtonClick =  async (event: React.MouseEvent<HTMLButtonElement>) => {
  event.preventDefault();
  // Your logic for the button click goes here.
  if (!chatInput.trim()) return;
  event.preventDefault(); // Prevent the form from submitting

  setLoading(true);
  const reversedChatHistory = state.chatHistory.slice().reverse();

  // Initialize an empty string to accumulate the chat texts
  let combinedText = '';
  // Iterate through the reversed chat history
  for (const chat of reversedChatHistory) {
      // Check if adding the next chat text would exceed the 2000 character limit
      if (combinedText.length + chat.text.length > 2000) {
          break; // Stop adding if it exceeds the limit
      }
      // Add the current chat text to the accumulated string
      combinedText += chat.text + ' ';
  }
  
  // Output the result
  console.log(combinedText.trim()); // Trim to remove any trailing whitespace
  try {
    const response = await axios.post('https://api.chatover.wine/api/test/chatbot', {
      message: chatInput,
      pastChat: reversedChatHistory, 
      userInfo: {
        name,
        sex,
        isMature,
        voice
      },
      botId: state.botName, 
    });

    const newChats = [
      ...state.chatHistory,
      { id: state.chatHistory.length + 1, text: name+ ": " + chatInput, user: name },
      { id: state.chatHistory.length + 2, text: state.botName+": "+ response.data.message, user: state.botName}
    ];

    setState(prevState => ({
      ...prevState,
      chatHistory: newChats,
    }));
    if (response.data.audioUrl) {
    setAudioUrl(response.data.audioUrl);
    }
  } catch (error) {
    console.error('Failed to send message:', error);
  } finally {
    setChatInput('');
    setLoading(false);
  }
};

const spinnerStyle = {
  border: "4px solid rgba(0, 0, 0, 0.1)",
  width: "36px",
  height: "36px",
  borderRadius: "50%",
  borderColor: "transparent",
  borderTopColor: "rgba(250, 250, 250, 0.8)",
  animation: "spin 1s ease-in-out infinite"
};   
const URL = "../chatbot/"+tokenID;
const handleChange = (event: SelectChangeEvent<string>) => {
    setSelectedVoice(event.target.value); // Direct use of event.target.value which is now appropriately typed as string
};
return (
  <PageRedux backgroundImage={bg}>
    <Container maxWidth="sm" sx={{ mt: 4 , bgcolor: "rgba(223, 223, 233, 0.95)"}}>
    <NFTGate>
    {loading && (
          <div className="overlay">
              <div className="spinner"></div>
          </div>
      )}
      <Box display="flex" flexDirection="column" alignItems="center">

     
        <Box my={2} width="100%" maxHeight="100%"  >
        <Button variant="contained" color="primary" href = {URL} sx={{ m: 1 }}>
          Back to Main
        </Button>
        {nftMetadata && (
          <>
          <Typography variant="h1" gutterBottom>
          {nftMetadata.name}
          </Typography>
     

              <Box flexDirection="column" alignItems="center" sx={{ display: 'flex', justifyContent: 'center',  p: 2 }}>

                <img src={nftMetadata.image} alt={nftMetadata.name} style={{ maxWidth: '50%', borderRadius: '10px' }} />
      
              </Box>
             
              <Typography variant="body1">
                {nftMetadata.description}
              </Typography>
          
</>
           
          )}
        </Box>
       
 
        {loggedIn && (
            <>
        <TopSelections
      name={name}
      setName={setName}
      sex={sex}
      setSex={setSex}
      isMature={isMature}
      setIsMature={setIsMature}
      voice={voice}
      setVoice={setVoice}
      />
      <FormControl fullWidth>
            <InputLabel id="voice-select-label">Voice</InputLabel>
            <Select
                labelId="voice-select-label"
                id="voice-select"
                value={selectedVoice}
                label="Voice"
                onChange={handleChange}
            >
                {voices.map(voice => (
                    <MenuItem key={voice.id} value={voice.id}>
                        {voice.id}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>

        <Box my={2} width="100%">

      <Typography variant="h6" gutterBottom sx={{ml:2,mr:2,mt:1}}>
    Welcome to AI Chat with Voice
    </Typography>
    <Typography variant="body1" gutterBottom sx={{ml:2,mr:2}}>
    Better RP models and mature models are coming soon
    </Typography>
          <ChatHistory chats={state.chatHistory} />
          <Box
my={2}
width="100%"
sx={{
  display: 'flex',
  alignItems: 'center', // Vertically centers the content
  justifyContent: 'center', // Horizontally centers the content
}}
>
{/* Audio player to play response from the bot */}
{audioUrl && <audio src={audioUrl} autoPlay controls />}
</Box>
<Box component="form" onSubmit={handleSendChat} sx={{ display: 'flex', mt: 2 }}>
<TextField
  fullWidth
  value={chatInput}
  onChange={handleInputChange}
  variant="outlined"
  placeholder="Type a message..."
  disabled={loading}  // Disable the input when loading
  sx={{mr: 2, ml:2, mb: 2}}
/>

  <Button type="submit" sx={{ ml: 1, mr: 2, mb: 2 }} variant="contained" color="primary">
    Send
  </Button>

</Box></Box></>)}
         {!loggedIn && (
  <Typography variant="h1">
  Log in to use!
</Typography>
)}
        
      </Box>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            {error}
          </Alert>
        </Snackbar>
        </NFTGate>
    </Container>
  </PageRedux>
);
};


export default ChatbotRoom;

