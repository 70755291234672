  import Page from 'components/Page';
  import { useParams } from 'react-router-dom';
  import React, { ChangeEvent, FormEvent, useState, useEffect, useCallback } from 'react';
  import { Container, Box, TextField, Button, Typography, CircularProgress, Modal, Card, styled, Link } from '@mui/material';
  import { useWriteContract, useWaitForTransactionReceipt, useReadContract, useAccount } from 'wagmi';
  import axios from 'axios';
  import { BigNumber, ethers } from 'ethers';
  import ChatMintButton from './ChatMintButton';
  import ApproveButton from './ApproveButton'; // Adjust the import path as necessary
  import PageRedux from 'components/PageRedux';
  import MemecoinStore from './MemecoinStore';
import { Type } from 'react-toastify/dist/utils';
import NFTGate from './NFTGate';
import SEO from 'components/SEO';


  const CONTRACT_ADDRESS = '0x56e5776B59c3dfB42bA9B45733Cf618F6D128Ec5';
  const CONTRACT_ABI = [
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "spender",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "value",
          "type": "uint256"
        }
      ],
      "name": "approve",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "owner",
          "type": "address"
        },
        {
          "internalType": "address",
          "name": "spender",
          "type": "address"
        }
      ],
      "name": "allowance",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
  ];

  const CONTRACT_ADDRESS_NFT = '0xA8675eaacFCB964a81e360B857e06dbedc2F003d';
  const CONTRACT_ABI_NFT = [
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "tokenURI",
        "outputs": [
            {
                "internalType": "string",
                "name": "",
                "type": "string"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "getTokenAddress",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "ownerOf",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }
  ];

  const StyledCard = styled(Card)(({ theme }) => ({
    transition: "transform 0.3s ease-in-out",
    '&:hover': {
      transform: "scale(1.05)",
      boxShadow: "0px 0px 15px  rgba(192, 179, 163, 1)",
      backgroundColor: "rgba(0, 0, 0, 1)",
      filter: 'drop-shadow(0px 0px 15px rgba(165, 179, 163, 1))',
      '& img': {
        transition: 'filter 0.15s ease-in-out',
      },
    },
    minHeight: 390,
    minWidth: 280,
    transform: "scale(1.05)",
    boxShadow: "0px 0px 15px  rgba(192, 179, 163, 1)",
    backgroundColor: "rgba(0, 0, 0, 1)",
    filter: 'drop-shadow(0px 0px 15px rgba(165, 179, 163, 1))',
    '& img': {
      filter: 'drop-shadow(0px 0px 10px rgba(165, 179, 163,  0.9))',
      transition: 'filter 0.15s ease-in-out',
    },
  }));

  interface NFTMetadata {
    name: string;
    description: string;
    image: string;
    attributes: { trait_type: string; value: string }[];
  }

  const ChatbotNFT: React.FC = () => {
    
    const getPathTokenID = () => {
      const pathname = window.location.pathname; // e.g., '/chatbot/1'
      console.log("Current Path:", pathname);
      
      const segments = pathname.split('/'); // Split the path into segments
      const tokenID = segments[2]; // Assuming 'chatbot' is always at the first index after the first slash
      
      console.log("Token ID:", tokenID);
      return tokenID;
  }
    const tokenID = getPathTokenID();
    console.log(tokenID);
    const { data: hash, error, isPending, writeContract } = useWriteContract();
    const { isLoading: isConfirming, isSuccess: isConfirmed, data: receiptData } = useWaitForTransactionReceipt({ hash });
    const [nftMetadata, setNftMetadata] = useState<NFTMetadata | null>(null);
    const [isOwner, setIsOwner] = useState<boolean>(false);
    const [coinTicker, setCoinTicker] = useState('');
    const [coinName, setCoinName] = useState('');
    //const { address: userAddress } = useAccount();
    const account = useAccount()
    let accountAddress = account.address;
    console.log(accountAddress);

    const { data: tokenUri } = useReadContract({
      address: CONTRACT_ADDRESS_NFT,
      abi: CONTRACT_ABI_NFT,
      functionName: 'tokenURI',
      args: [tokenID],
    });// as { data: string | undefined };

    const { data: nftOwner } = useReadContract({
      address: CONTRACT_ADDRESS_NFT,
      abi: CONTRACT_ABI_NFT,
      functionName: 'ownerOf',
      args: [tokenID],
    }) as { data: string | undefined };

    //getokenaddress
    const { data: tokenAddress } = useReadContract({
      address: CONTRACT_ADDRESS_NFT,
      abi: CONTRACT_ABI_NFT,
      functionName: 'getTokenAddress',
      args: [tokenID],
    }) as { data: string | undefined };
    

    useEffect(() => {
      if (tokenUri) {
        console.log("Token URI: " + tokenUri);
        const jsonPart = tokenUri.toString().split(',')[1]; // Get the Base64 encoded JSON
        if (jsonPart) {
          const decodedJson = atob(jsonPart); // Decode Base64
          const parsedMetadata = JSON.parse(decodedJson); // Parse the JSON
          setNftMetadata(parsedMetadata); // Set the parsed metadata to state
          // Extract and set coin details
          let coinTicker = '';
          let coinName = '';
      
          // Iterate over attributes to extract information
          parsedMetadata.attributes.forEach((attr: { trait_type: string; value: string; }) => {
              console.log(attr.trait_type + ": " + attr.value);
              if (attr.trait_type === "Coin Ticker") {
                  coinTicker = attr.value;
              }
              if (attr.trait_type === "Coin Name") {
                  coinName = attr.value;
              }
          });
      
          // Set the extracted values to state
          setCoinTicker(coinTicker);
          setCoinName(coinName);


        }
      }
      if(nftOwner) {
        console.log(nftOwner);
      }
      if (nftOwner && accountAddress) {
        setIsOwner(nftOwner.toLowerCase() === accountAddress.toLowerCase());
      }
      if(tokenAddress) {
        console.log(tokenAddress);
      }
    }, [tokenUri, nftOwner, accountAddress, tokenAddress]);
    // Fetch and display NFT data using tokenId

    const botBackgroundImages = [
      // '/media/bg/bot1.webp',
      '/media/bg/bot2.webp',
      '/media/bg/bot3.webp',
      '/media/bg/bot4.webp',
      '/media/bg/bot5.webp',
      '/media/bg/bot6.webp',
      '/media/bg/bot7.webp',
      // Add more bot background images as needed
  ];
    console.log(window.location.pathname);

    const [bg, setBG] = useState('');
    const fetchNFTData = async (tokenId: string) => {
      try {
          const response = await fetch(`https://api.chatover.wine/api/getNFTExtraImage?tokenId=${tokenId}`);
          const data = await response.json();
          if (response.ok) {
              console.log('NFT Data:', data);
             // state.botBackgroundImage=data;
              setBG(data);
             // console.log('NFT Data:', state.botBackgroundImage);
              return data;
          } else {
              console.log('Error fetching NFT:', data.message);
              return null;
          }
      } catch (error) {
          console.error('Network error:', error);
          return null;
      }
  }
  fetchNFTData(tokenID);

  const [twitter, setTwitter] = useState('chatover_wine');
  //get socials getNFTExtra
  const fetchNFTDataExtra = async (tokenId: string) => {
    try {
        const response = await fetch(`https://api.chatover.wine/api/getNFTExtra?tokenId=${tokenId}`);
        const data = await response.json();
        if (response.ok) {
            console.log('NFT Data:', data);
           // state.botBackgroundImage=data;
           setTwitter(data?.twitter);
           // console.log('NFT Data:', state.botBackgroundImage);
            return data;
        } else {
            console.log('Error fetching NFT:', data.message);
            return null;
        }
    } catch (error) {
        console.error('Network error:', error);
        return null;
    }
}
fetchNFTDataExtra(tokenID);
    return (
      <PageRedux backgroundImage={bg}>
  
      <Container maxWidth="sm" sx={{ mt: 4, p: 2, bgcolor: 'rgba(223, 223, 233, 0.95)' }}>
        <Box display="flex" flexDirection="column" alignItems="center">
        <NFTGate>
        {nftMetadata && (
          <>
          <Typography variant="h1" gutterBottom>
          {nftMetadata.name}
          </Typography>
      

        
            <Box flexDirection="column" alignItems="center" sx={{display: 'flex', mt: 2 , justifyContent: 'center'  }}>
            <Link href= {`https://twitter.com/${twitter}`} target="_blank"  >
        <img src={`https://chatover.wine/media/icons/twitter.svg`} alt="Twitter" height='27px' width='27px' />
        </Link>
            <Link 
              href={`https://cheesechain.calderaexplorer.xyz/token/0xA8675eaacFCB964a81e360B857e06dbedc2F003d/instance/${tokenID}`} 
              variant="h6" 
              sx={{ mt: 2 }} // Additional spacing from the last link
            >
              Chatbot #{tokenID}
            </Link>
                <Link 
              
                href={`https://cheesechain.calderaexplorer.xyz/token/${tokenAddress}`} 
                variant="h6" 
                sx={{ display: 'block', mt: 1 }} // Ensures each link is on a new line
              >
                Coin Ticker: {coinTicker}
              </Link>
          <Typography variant="body2" sx={{ mt: 1 }}>
  CA: {tokenAddress}
</Typography>
              <Box flexDirection="column" alignItems="center" sx={{ display: 'flex', justifyContent: 'center',  p: 2 }}>

                <img src={nftMetadata.image} alt={nftMetadata.name} style={{ maxWidth: '50%', borderRadius: '10px' }} />
                <Typography variant="body1" sx={{ m: 1 }}>
                {nftMetadata.description}
              </Typography>
          
                {isOwner && (
<Button
  variant="contained"
  color="primary"
  sx={{ mt: 2 }}
  href= {`admin`}
>
  Admin
</Button>
)}

    <Box flexDirection="row" alignItems="center" sx={{ display: 'flex', justifyContent: 'center'}}>
<Button
  variant="contained"
  color="primary"
  sx={{ m: 1 }}
  href= {`chatbotroom`}
>
  Chatbot
</Button>
<Button
  variant="contained"
  color="primary"
  sx={{ m: 1 }}
  href= {`aitools`}
>
  Img Gen
</Button>
</Box>
              </Box>
             
           
          
            
            {tokenAddress && (
            <MemecoinStore  tokenAddress={`0x${tokenAddress.slice(2)}`} ticker = {coinTicker}></MemecoinStore>
            )}
            </Box></>
          )}
          
          </NFTGate>
        </Box>
      </Container>
    </PageRedux>
    );
  };

  export default ChatbotNFT;
