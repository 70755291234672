import React, { useState } from 'react';
import { List, ListItem, ListItemText, Typography, Box, TextField, Button } from '@mui/material';
import { CSSProperties } from 'react';

type Message = {
  id: number;
  sender: string;
  text: string;
};

const ClubBox: React.FC = () => {
  const [messages, setMessages] = useState<Message[]>([
    { id: 1, sender: '420UpOnly', text: 'This is so based' },
    { id: 2, sender: 'PepelaKek', text: 'Wut this' },
    { id: 3, sender: 'MeWifHat', text: 'Devs cooking' },
  ]);
  const [inputText, setInputText] = useState('');

  const handleSendMessage = () => {
    if (inputText.trim() !== '') {
      const newMessage: Message = {
        id: messages.length + 1,
        sender: 'You',
        text: inputText,
      };
      setMessages([...messages, newMessage]);
      setInputText('');
    }
  };

  const style: CSSProperties = {
    maxHeight: '300px',
    overflowY: 'auto',
    background: 'rgba(255, 255, 255, 0.8)',
    padding: '10px',
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Club Chat Room
      </Typography>
      <Typography variant="h6" gutterBottom>
        Coming Soon
      </Typography>
      <List style={style}>
        {messages.map((message) => (
          <ListItem key={message.id} divider>
            <ListItemText
              primary={`${message.sender}: ${message.text}`}
            />
          </ListItem>
        ))}
      </List>
      <Box mt={2} display="flex" alignItems="center">
        <TextField
          label="Type a message"
          variant="outlined"
          size="small"
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          fullWidth
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSendMessage}
          style={{ marginLeft: '10px' }}
        >
          Send
        </Button>
      </Box>
    </Box>
  );
};

export default ClubBox;