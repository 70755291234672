import React, { useState, useRef } from 'react';
import Dropzone from 'react-dropzone';
import Draggable from 'react-draggable';
import html2canvas from 'html2canvas';
import { Box, Button, Container, Typography, Slider } from '@mui/material';
import Page from 'components/Page';

const SimpleMeme: React.FC = () => {
  const [image, setImage] = useState<string | null>(null);
  const [glassSize, setGlassSize] = useState<number>(100);
  const [rotation, setRotation] = useState<number>(0);
  const imageRef = useRef<HTMLImageElement | null>(null);
  const canvasRef = useRef<HTMLDivElement | null>(null);

  const onDrop = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result as string);
    };
    reader.readAsDataURL(file);
  };

  const downloadImage = () => {
    if (canvasRef.current && imageRef.current) {
      const canvasElement = canvasRef.current;
      const imageElement = imageRef.current;

      html2canvas(canvasElement, {
        useCORS: true,
        backgroundColor: null, // Set background color to transparent
      }).then((canvas) => {
        const link = document.createElement('a');
        link.href = canvas.toDataURL();
        link.download = 'image-with-wineglass.png';
        link.click();
      });
    }
  };

  const handleSizeChange = (event: Event, newValue: number | number[]) => {
    setGlassSize(newValue as number);
  };

  return (
    <Page>
      <Container maxWidth="md" sx={{ bgcolor: "rgba(223, 223, 233, 0.85)", mt: 4, p: 4, textAlign: 'center' }}>
        <Typography variant="h4" gutterBottom>Upload Your Image</Typography>
        <Dropzone onDrop={onDrop}>
          {({ getRootProps, getInputProps }) => (
            <Box {...getRootProps()} sx={{ border: '2px dashed gray', p: 4, cursor: 'pointer' }}>
              <input {...getInputProps()} />
              <Typography>Drag 'n' drop an image here, or click to select one</Typography>
            </Box>
          )}
        </Dropzone>
        {image && (
          <>
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                mt: 4,
                overflow: 'hidden',
                width: '100%',
                height: '400px', // Set a fixed height for the container
              }}
              ref={canvasRef}
            >
              <img
                src={image}
                alt="Uploaded"
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  objectFit: 'contain',
                }}
                ref={imageRef}
              />
              <Draggable>
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: `translate(-50%, -50%) rotate(${rotation}deg)`,
                    transformOrigin: 'center center',
                  }}
                >
                  <Box
                    component="img"
                    src="/media/icon.png"
                    alt="Wineglass"
                    sx={{
                      width: `${glassSize}px`,
                      height: 'auto',
                    }}
                  />
                </Box>
              </Draggable>
            </Box>
            <Box sx={{ mt: 4 }}>
              <Typography gutterBottom>Adjust Wineglass Size</Typography>
              <Slider
                value={glassSize}
                min={80}
                max={500}
                onChange={handleSizeChange}
                aria-labelledby="wineglass-size-slider"
              />
            </Box>
            <Button variant="contained" color="primary" onClick={downloadImage} sx={{ mt: 4 }}>
              Download Image
            </Button>
          </>
        )}
      </Container>
    </Page>
  );
};

export default SimpleMeme;