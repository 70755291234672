import React, { useState, useEffect } from 'react';
import { useAccount, useSignMessage } from 'wagmi';
import { useSearchParams } from 'react-router-dom';
import PageRedux from 'components/PageRedux';
import { Box, Container, Typography, TextField, Button, Paper, CircularProgress, Snackbar, Alert, AlertColor, Link } from '@mui/material';
import { styled } from '@mui/system';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

interface SnackbarState {
  open: boolean;
  message: string;
  severity: AlertColor;
}

const Discord: React.FC = () => {
  const { address, isConnected } = useAccount();
  const { signMessageAsync } = useSignMessage();
  const [discordCode, setDiscordCode] = useState<string>('');
  const [nonce, setNonce] = useState<string>('');
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [registrationComplete, setRegistrationComplete] = useState<boolean>(false);
  const [snackbar, setSnackbar] = useState<SnackbarState>({
    open: false,
    message: '',
    severity: 'info',
  });

  useEffect(() => {
    const fetchDiscordCode = () => {
      const code = searchParams.get('discordCode');
      if (code) {
        setDiscordCode(code);
      }
    };

    fetchDiscordCode();
  }, [searchParams]);

  useEffect(() => {
    const fetchNonce = async () => {
      if (!discordCode) return;
      
      setLoading(true);
      try {
        const response = await fetch(`https://api.chatover.wine/api/discordNonce?discordCode=${discordCode}`);
        const { nonce } = await response.json();
        setNonce(nonce);
        console.log('Nonce:', nonce);
      } catch (error) {
        console.error('Error fetching nonce:', error);
        setSnackbar({
          open: true,
          message: 'Failed to fetch nonce. Please try again.',
          severity: 'error',
        });
      } finally {
        setLoading(false);
      }
    };

    if (discordCode) {
      fetchNonce();
    }
  }, [isConnected, discordCode]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    try {
      const signature = await signMessageAsync({ message: "Sign this message with your wallet: " + nonce + discordCode });
      const response = await fetch('https://api.chatover.wine/api/discordRegister', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          discordCode,
          walletAddress: address,
          signature
        })
      });

      if (response.ok) {
        setSnackbar({
          open: true,
          message: 'Registration successful! Please return to Discord.',
          severity: 'success',
        });
        setRegistrationComplete(true);
      } else {
        setSnackbar({
          open: true,
          message: 'Registration failed. Please try again.',
          severity: 'error',
        });
      }
    } catch (error) {
      console.error('Error during registration:', error);
      setSnackbar({
        open: true,
        message: 'An error occurred during registration. Please try again.',
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  return (
    <PageRedux backgroundImage={'/media/bg/bot5.webp'}>
      <Container maxWidth="sm" sx={{ mt: 4 }}>
        <StyledPaper elevation={3}>
          <Typography variant="h1" component="h1" gutterBottom>
            ChatOver.Wine
          </Typography>
          <Typography variant="h4" component="h1" gutterBottom>
            Discord Registration
          </Typography>
          {!registrationComplete ? (
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1, width: '100%' }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="discordCode"
                label="Discord Code"
                name="discordCode"
                value={discordCode}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDiscordCode(e.target.value)}
                disabled={loading}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={!isConnected || !nonce || loading}
              >
                {loading ? <CircularProgress size={24} /> : 'Sign Message & Register'}
              </Button>
            </Box>
          ) : (
            <Box sx={{ mt: 3, textAlign: 'center' }}>
              <Typography variant="h6" gutterBottom>
                Registration Complete!
              </Typography>
              <Typography variant="body1" gutterBottom>
                Your wallet has been successfully linked to your Discord account.
              </Typography>
              <Typography variant="body1" gutterBottom>
                Please return to your Discord chat and continue your conversation with the bot.
              </Typography>
              <Link
                href="https://discord.com/app"
                target="_blank"
                rel="noopener noreferrer"
                sx={{ display: 'inline-block', mt: 2 }}
              >
                <Button variant="contained" color="primary">
                  Open Discord
                </Button>
              </Link>
            </Box>
          )}
        </StyledPaper>
      </Container>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </PageRedux>
  );
};

export default Discord;