import React, { useState } from 'react';
import { Card, CardContent, Typography, CardMedia, Button, Box } from '@mui/material';
import Rating from '@mui/material/Rating';  // Import the Rating component from MUI

interface BotCardProps {
  botName: string;
  botDescription: string;
  botImage: string;
  botRating: number;  // Initial rating value
  onPrevBot: () => void;
  onNextBot: () => void;
  onRateBot: (newRating: number) => void;  // Function to handle rating changes
}

const BotCard: React.FC<BotCardProps> = ({
  botName,
  botDescription,
  botImage,
  botRating,
  onPrevBot,
  onNextBot,
  onRateBot
}) => {
  const [rating, setRating] = useState(botRating);  // State to manage the rating
  const [rated, setRated] = useState(false);  // State to check if user has rated

  const handleRatingChange = (event: React.ChangeEvent<{}>, newValue: number | null) => {
    if (newValue !== null) {
      setRating(newValue);
      setRated(true);
      onRateBot(newValue);  // Call the prop function to handle the rating change
    }
  };

  return (
    <Card sx={{ display: "flex", flexDirection: "column", alignItems: "stretch", justifyContent: "normal", textAlign: "center", gap: 1.5, width: "100%", padding: "0.5rem", borderRadius: "3px", bgcolor: "rgba(223, 223, 233, 0.95)" }}>
      <CardContent>
        <Typography gutterBottom variant="h1" component="div">
          {botName}
        </Typography>

 
      </CardContent>
      <CardMedia component="img" height={240} sx={{ objectFit: 'contain' }} image={botImage} alt="bot image" />
      <Typography variant="body1" color="text.secondary">
          Experience: 169,420
        </Typography>
         <Typography variant="body1" color="text.secondary">
          Marketcap: $10,000
        </Typography>
        <Typography variant="body1" color="text.secondary">
          {botDescription}
        </Typography>
      <Box display="flex" justifyContent="space-between" p={2}>
        
        <Button variant="contained" color="primary" onClick={onPrevBot}>
          Last
        </Button>
        <Rating
          name="simple-controlled"
          value={botRating}
          onChange={(event, newValue) => newValue !== null && onRateBot(newValue)}
          sx={{
            color: '#A52A2A', // Color of filled stars
            '& .MuiRating-iconEmpty': {
              color: 'grey' // Color of empty stars
            },
            '& .MuiRating-iconHover': {
              color: '#A52A2A' // Color on hover
            }
          }}
        />
        <Button variant="contained" color="primary" onClick={onNextBot}>
          Next
        </Button>
      </Box>
    </Card>
  );
};

export default BotCard;
