  import { useEffect, useState } from "react"
  import { useContractWrite, useAccount, useWriteContract, useWaitForTransactionReceipt, useSimulateContract } from "wagmi"
  import { utils, BigNumber } from "ethers"
  import {
    Box,
    Typography,
    Modal,
    Button,
    ButtonGroup,
    Card,
    CardMedia,
    CardContent,
    CardActions,
    styled
  } from "@mui/material"
  import {MULTIPLY_GAS_LIMIT } from "config"
  import { multiplyBigNumberByFloat, formatEtherFixed } from "utils/numbers"
 // import RadiantGiftMintABI from "abi/Minter.json"
  import VoidriteMintABI from "abi/Minter.json"
  import TokenView from "components/TokenView"
  import MintingButtonEnabled from "components/MintingButtonEnabled"
  import useWindowSize from "hooks/useWindowSize"
  import MintingButtonDisabled from "./MintingButtonDisabled"
  import axios from "axios"
  import React from "react"
  import { useDebounce } from "usehooks-ts"
  //import {BigNumber} from 'bignumber.js'
  import CircularProgress from '@mui/material/CircularProgress';
import { Group } from "@mui/icons-material"
  const MINT_CONTRACT_ADDRESS = "0xD2fF6D8c9989DFaD80a52393D72A4EDC51Ac1AB2";

  interface Props {
      isConnected: boolean,

      mintPrice: BigNumber,
      UserAddy: string,
      randomString: string,
  }
  interface NFTData {
    image: string;
    name: string;
    description: string;
    series: string;
    attributes: { trait_type: string; value: string }[];
    external_url: string;
  }
  const StyledCard = styled(Card)(({ theme }) => ({
  
    transition: "transform 0.3s ease-in-out",
    '&:hover': {
      transform: "scale(1.05)",
      boxShadow: "0px 0px 15px  rgba(92, 179, 163, 1)",
      backgroundColor: "rgba(0, 0, 0, 1)",
      filter: 'drop-shadow(0px 0px 15px rgba(65, 179, 163, 1))',
      '& img': {
      //  animation: `${wobble} 2.5s linear infinite, ${glow} 0.5s ease-in-out infinite`,
        transition: 'filter 0.15s ease-in-out',}
       //146	95	165
       // filter: 'drop-shadow(0px 0px 10px rgba(128, 226, 126, 0.9))',
       // transition: 'filter 0.15s ease-in-out',}
    },
  
    //width: 460,
    minHeight: 390,
    minWidth: 280,
    transform: "scale(1.05)",
      boxShadow: "0px 0px 15px  rgba(92, 179, 163, 1)",
      backgroundColor: "rgba(0, 0, 0, 1)",
      filter: 'drop-shadow(0px 0px 15px rgba(65, 179, 163, 1))',
      '& img': {
       //  animation: `${wobble} 2.5s linear infinite`,
       //146	95	165
        filter: 'drop-shadow(0px 0px 10px rgba(128, 226, 126, 0.9))',
        transition: 'filter 0.15s ease-in-out',}
  }));

  const RadiantGiftMintButton = ({ 
      isConnected,
      mintPrice,
      UserAddy,
      randomString
      }: Props) => {
       // const nftDataz: NftData | undefined = undefined; 
       const [nftData, setNftData] = useState<NFTData | undefined>();



        
        //let randomString = generateRandomString(10); 
        const { address } = useAccount();
      const windowSize = useWindowSize();

      const [dialog, setDialog] = useState("");
      const [mintingPreview, setMintingPreview] = useState(false);

      const handleMintingPreviewOpen = () => setMintingPreview(true);
      const handleMintingPreviewClose = () => setMintingPreview(false);
      const finalBuyprice = mintPrice ? mintPrice : BigNumber.from(0);

    
      const [isMetadataAvailable, setIsMetadataAvailable] = useState(true);

    const [mintingReveal, setMintingReveal] = useState(false);

    const handleMintingRevealOpen = () => setMintingReveal(true);
    const handleMintingRevealClose = () => setMintingReveal(false);

    const [tokenId, setTokenId] = useState(-1);
    const debouncedTokenId = useDebounce(tokenId)

    

const { data } = useSimulateContract({
  abi: VoidriteMintABI,
  address: MINT_CONTRACT_ADDRESS,
  functionName: "mintNFT",
  args: [UserAddy,randomString]
  
});
const { 
      data: hash, 
      isPending, 
      writeContract 
    } = useWriteContract(); 
/*
let customRequest = data.request ? {
    data: data.request?.data,
    from: config.request?.from,
    gasLimit: multiplyBigNumberByFloat(config.request?.gasLimit, MULTIPLY_GAS_LIMIT),
    to: config.request?.to,
    value: config.request?.value
  } : undefined
*/
async function submit(e: React.FormEvent<HTMLFormElement>) { 
  e.preventDefault() 
  const formData = new FormData(e.target as HTMLFormElement) 
  const tokenId = formData.get('tokenId') as string 
  writeContract({
    abi: VoidriteMintABI,
    address: MINT_CONTRACT_ADDRESS,
    functionName: "mintNFT",
    args: [UserAddy,randomString]
  })
} 


  //let writeData;
  const write = () => {
    writeContract({
      abi: VoidriteMintABI,
      address: MINT_CONTRACT_ADDRESS,
      functionName: "mintNFT",
      args: [UserAddy,randomString]
    })
  };
  const { data: receiptData, isSuccess } = useWaitForTransactionReceipt({
    hash: hash  
  });

  useEffect(() => {
    if (isSuccess && receiptData) {
      console.log("Transaction successful with receipt:", receiptData);
      // Transaction receipt logic here
      if (receiptData.logs && receiptData.logs.length > 0) {
        const log = receiptData.logs[1]; 
        console.log('Log: ', log);
        const tokenIdTopic = log.topics[3]; 

        //Refactor
        /*
        const Id = parseInt(tokenIdTopic, 16); 
        console.log('Token ID: ', Id);
        
        setIsMetadataAvailable(false);
        setTokenId(Id);
        */
        
    } else {
      console.log('No logs found in transaction receipt');
    }

    handleMintingPreviewOpen()
    setDialog("")  
    setTimeout(() => {
      //handleMintingPreviewClose();
    }, 2500);
    }
  }, [isSuccess, receiptData]);

  const [retryCount, setRetryCount] = useState(0);
const MAX_RETRIES = 5;

useEffect(() => {
  let isCancelled = false;
  
  const fetchNFTData = async (tokenId: number) => {
    if(tokenId === -1) {
    return;
    }
    while (retryCount < MAX_RETRIES && !isCancelled) {
      try {
        const res = await axios.get(`https://metadata.sagetowers.com/rgift/${tokenId}`);
        if (res.data) {
          setNftData(res.data);
          handleMintingPreviewClose();
          handleMintingRevealOpen();
          break;
        } else {
          setRetryCount(prev => prev + 1);
          await new Promise(resolve => setTimeout(resolve, 10000));
        }
      } catch (err) {
        console.error(err);
        setRetryCount(prev => prev + 1);
        await new Promise(resolve => setTimeout(resolve, 10000));
      }
    }
  };

  fetchNFTData(tokenId);

  return () => {
    isCancelled = true;
  };
}, [tokenId, retryCount]);
const resetMintingStates = () => {
  setNftData(undefined);
  setTokenId(0);  // Reset token ID
  setRetryCount(0);  // Reset retry count if you're implementing it
  setIsMetadataAvailable(false);  // If you decide to keep using this
};

const handleMinting = async () => {
  resetMintingStates();
  write?.();  // This triggers the minting process
};
    return (
      <>
    
          
            
          
        
            <Box sx={{backgroundColor:"#232333", m:"0px", zIndex:"3"} }>

      <MintingButtonEnabled message={"Get Item (100 LLM)"} contractPurchase={write}></MintingButtonEnabled>
    </Box>
    
          
        
      
        {
        !isConnected &&
          (
            <Typography fontWeight={800} fontStyle="italic">
              Connect to mint...
            </Typography>
          )
        }
        <Box marginTop={1}>
          <Typography fontStyle="italic">
            {dialog}
                    
            
          </Typography>
        </Box>
  <Modal
    open={mintingPreview}
    onClose={handleMintingPreviewClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
  <Box
    sx={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: {xs: '80%', sm: '80%', md:'40%', lg: '40%'},
      bgcolor: "black",
      border: "none",
      boxShadow: 0,
      padding: 1,
    }}
  >
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
          <StyledCard>
      <Typography margin={"1rem"} id="modal-modal-title" variant="h1" fontSize="1.2rem"  sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        Creating Radiant Gift...
      </Typography>
      <Box margin={"1rem"} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      
          <img src="media/gifts.png" alt="RadiantGift" style={{width: '80%', height: '80%'}} />
        
      </Box>
      <Box m={"1rem"} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <CircularProgress color="secondary" />
      </Box></StyledCard>
    </Box>
  </Box>

  </Modal>
  <Modal
    open={mintingReveal}
    onClose={handleMintingRevealClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
  <Box
  sx={{
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {xs: '80%', sm: '80%', md:'40%', lg: '40%'},
    bgcolor: "black",
    border: "none",
    boxShadow: 0,
    padding: 1,
  }}
>
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    }}
  > 
      {
        nftData && (
          <StyledCard>


      <Typography margin={"1rem"}   variant="h1" fontSize="1.25rem"  sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      {nftData.name}
    </Typography>
    <Box margin={1} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
     
        <img src={nftData.image.replace('ipfs://', 'https://ipfs.io/ipfs/')} alt={nftData.name} style={{ width: '50%', height: '50%'}} />
      
    </Box>
    <Typography maxWidth={"60%"}  m={"1rem"} mb={"1rem"} ml={"1rem"} variant="h2" fontSize="0.65rem"   >
                  {nftData.description}
                </Typography>
    <Box m={"1rem"} sx={{ display: "flex",
        flexDirection: "column",
        justifyContent: "left",
        alignItems: "left",}}>
               
                <Typography  m={0.2} variant="h2"fontSize="0.65rem"  >
                  <strong>Series:</strong> {nftData.series}
                </Typography>                                                      
                {nftData.attributes.map((attribute, index) => (
                  <Typography m={0.2} key={index} variant="h2"  fontSize="0.65rem"   >
                    <strong>{attribute.trait_type}:</strong> {attribute.value}
                  </Typography>
                ))}
</Box>
    

    
    </StyledCard>
        )
      }
    </Box>
  </Box>
</Modal>
      </>
    )
  }
  export default RadiantGiftMintButton
