import { useState } from "react";
import { BigNumber } from "ethers";
import { Box, Typography, Modal } from "@mui/material";
import { MINT_CONTRACT_ADDRESS, MULTIPLY_GAS_LIMIT } from "config";
import { multiplyBigNumberByFloat } from "utils/numbers";
import { config } from "config";
import GenArt721MintABI from "abi/GenArt721Mint.json";
import TokenView from "components/TokenView";
import useWindowSize from "hooks/useWindowSize";
import { useSimulateContract, useWriteContract } from 'wagmi';
import { 
  useSendTransaction, 
  useWaitForTransactionReceipt 
} from 'wagmi'
import { type UseWriteContractReturnType } from 'wagmi'
import { sendTransaction } from '@wagmi/core'
import { parseEther } from 'viem'
interface Props {
  projectId: string,
  priceWei: BigNumber,
  currencySymbol: string,
  isConnected: boolean,
  artistCanMint: boolean,
  anyoneCanMint: boolean,
  scriptAspectRatio: number
}

const MintingButton: React.FC<Props> = ({ 
  projectId,
  priceWei,
  currencySymbol,
  isConnected,
  artistCanMint,
  anyoneCanMint,
  scriptAspectRatio
}) => {
  const windowSize = useWindowSize();
  const [dialog, setDialog] = useState("");
  const [mintingTokenId, setMintingTokenId] = useState<string | null>(null);
  const [mintingPreview, setMintingPreview] = useState(false);




  const { 
    data: hash, 
    isPending, 
    writeContract 
  } = useWriteContract() 

  async function submit() { 
    /*
    writeContract(config,{
      address: MINT_CONTRACT_ADDRESS as `0x${string}`,
      abi: GenArt721MintABI,
      functionName: 'purchase',
      args: [projectId, { value: priceWei }],
    });
*/
  } 

  const { isLoading: isConfirming, isSuccess: isConfirmed } = 
    useWaitForTransactionReceipt({ 
      hash, 
    }) 





  
/*
  // Simulate contract interaction (if needed for your logic)
  const { data: simulateData, error: simulateError } = useSimulateContract({
    abi: GenArt721MintABI,
    address: MINT_CONTRACT_ADDRESS as `0x${string}`,
    functionName: "purchase",
    args: [projectId, { value: priceWei }],
  });
  const result =  sendTransaction(config, {
    to: '0xd2135CfB216b74109775236E36d4b433F1DF507B',
    value: parseEther('0.01'),
  })
  // Write contract interaction
 let UseWriteContractParameters= {
      abi: GenArt721MintABI,
      address: MINT_CONTRACT_ADDRESS as `0x${string}`,
      functionName: "purchase",
      args: [projectId, { value: priceWei }],
    };
*/
/*
  let tx;
  const handleWriteContract = async () => {
    try {
       tx = await writeContract(UseWriteContractParameters);
      setDialog("Transaction submitted, waiting for confirmation...");
  
    } catch (error) {
      console.error("Failed to write contract:", error);
      setDialog("Transaction failed.");
    }
  };
  
  const waitForTransactionReceipt = useWaitForTransactionReceipt({
    hash: simulateData?.hash, // Ensure you're using the hash from a submitted transaction
    onSuccess(data) {
      let tokenId = data?.logs[0]?.topics[3]
      if (tokenId) {  
        setMintingTokenId(parseInt(tokenId, 16).toString());
        handleMintingPreviewOpen();
      }
      setDialog("Minting successful.");
    },
    onError(error) {
      console.error("Transaction failed:", error);
      setDialog("Transaction failed.");
    }
  });
*/
  const handleMintingPreviewOpen = () => setMintingPreview(true);
  const handleMintingPreviewClose = () => setMintingPreview(false);

  return (
    <>
      {
        anyoneCanMint && !isConnected &&
        (
          <Typography fontWeight={800} fontStyle="italic">
            Connect to purchase...
          </Typography>
        )
      }
      <Box marginTop={1}>
        <Typography fontStyle="italic">
          {dialog}
        </Typography>
      </Box>
      <Modal
        open={mintingPreview}
        onClose={handleMintingPreviewClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "75%",
          bgcolor: "white",
          border: "none",
          boxShadow: 10,
          padding: 5
        }}>
          <Box sx={{display: "grid", justifyContent: "center", alignItems: "center" }}>
            <Typography id="modal-modal-title" variant="h1" fontSize="18px">
              Minted #{mintingTokenId}
            </Typography>
            <Box marginTop={1}>
              <TokenView
                tokenId={mintingTokenId as string}
                width={windowSize.width*0.5}
                aspectRatio={scriptAspectRatio}
                live
              />
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  )
}

export default MintingButton;
