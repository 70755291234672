import { useState } from "react"
import moment from "moment-timezone"
import { useAccount, useReadContracts } from "wagmi"
import { BigNumber } from "ethers"
import { Box } from "@mui/material"
import { CORE_CONTRACT_ADDRESS, MINT_CONTRACT_ADDRESS } from "config"
import GenArt721CoreABI from "abi/GenArt721Core.json"
import GenArt721MintABI from "abi/GenArt721Mint.json"
import MintingCountdown from "components/MintingCountdown"
import MintingProgress from "components/MintingProgress"
import MintingPrice from "components/MintingPrice"
import MintingButton from "components/MintingButton"

interface Props {
  projectId: string,
  artistAddress: string,
  scriptAspectRatio: number
}

const MintingInterface = ({ projectId, artistAddress, scriptAspectRatio }: Props) => {
  const [projectData, setProjectData] = useState<any | null>(null)
  const [projectPrice, setProjectPrice] = useState<any | null>(null)
  const [projectAuction, setProjectAuction] = useState<any | null>(null)
  const { address, isConnected } = useAccount()
 
const LLMContract = {
  address: '0x226B70c1Af7FeA262D252b5A16E55057bb1c79be', // Replace with your contract address
  abi: GenArt721CoreABI,
} as const;

const [totalMinted, setTotalMinted] = useState<BigNumber>(BigNumber.from(0));
const [currentPhase, setCurrentPhase] = useState<BigNumber>(BigNumber.from(0));
const [usdRetailPrice, setUsdRetailPrice] = useState<BigNumber>(BigNumber.from(0));


const { data, isError, error }  = useReadContracts({
  
contracts: [
{
  ...LLMContract,
  functionName: 'totalMinted',
 
  
},
{
  ...LLMContract,
  functionName: 'currentPhase',
 
},
{
  ...LLMContract,
  functionName: 'usdRetailPrice',
 
}
],})

  /*
const { data, isError, error }  = useReadContracts({
    
contracts: [
  {
    ...LLMContract,
    functionName: 'totalMinted',
   
    
  },
  {
    ...LLMContract,
    functionName: 'currentPhase',
   
  },
  {
    ...LLMContract,
    functionName: 'usdRetailPrice',
   
  }
],

})
useEffect(() => {
  if (data) {
    // Assuming the results are numbers (e.g., uint256) from the smart contract
    data.forEach((item, index) => {
      if (item.status === 'success' && typeof item.result === 'number') {
        const value =  BigNumber.from(item.result);
        switch (index) {
          case 0:
            setTotalMinted(value);
            break;
          case 1:
            setCurrentPhase(value);
            break;
          case 2:
            setUsdRetailPrice(value);
            break;
          default:
            console.warn('Unexpected data index:', index);
        }
      } else if (item.status === 'failure') {
        console.error('Error fetching data for index', index, ':', item.error);
      }
    });
  }
}, [data]);

  */

  if (!data || !projectData || !projectPrice || !projectAuction  || isError) {
    return null
  }

  const invocations = projectData.invocations.toNumber()
  const maxInvocations = projectData.maxInvocations.toNumber()
  const maxHasBeenInvoked = projectAuction.maxHasBeenInvoked
  const currencySymbol = projectPrice.currencySymbol
  //const currencyAddress = projectPrice.currencyAddress
  const currentPriceWei = projectPrice.tokenPriceInWei
  const priceIsConfigured = projectPrice.isConfigured
  const startPriceWei = projectAuction.startPrice
  const endPriceWei = projectAuction.basePrice
  const auctionStartUnix = projectAuction.timestampStart.toNumber()
  const auctionHasStarted = auctionStartUnix <= moment().unix()
  const auctionStartFormatted = moment.unix(auctionStartUnix).format("LLL")
  const auctionStartCountdown = moment.unix(auctionStartUnix).fromNow()
  //const priceDecayHalfLifeSeconds = projectAuction.priceDecayHalfLifeSeconds
  const isSoldOut = maxHasBeenInvoked || invocations >= maxInvocations
  const isPaused = projectData.paused
  const isArtist = isConnected && address?.toLowerCase() === artistAddress?.toLowerCase()
  const isNotArtist = isConnected && address?.toLowerCase() !== artistAddress?.toLowerCase()
  const artistCanMint = isArtist && priceIsConfigured && !isSoldOut && auctionHasStarted
  const anyoneCanMint = isNotArtist && priceIsConfigured && !isSoldOut && auctionHasStarted && !isPaused

  return (
    <Box>
      <MintingProgress 
        invocations={invocations} 
        maxInvocations={maxInvocations} 
        maxHasBeenInvoked={maxHasBeenInvoked}
      />
      {
        priceIsConfigured && !auctionHasStarted && 
        (
          <MintingCountdown 
            auctionStartFormatted={auctionStartFormatted} 
            auctionStartCountdown={auctionStartCountdown}
          />
        )
      }
      {
        priceIsConfigured && 
        (
          <MintingPrice 
            startPriceWei={startPriceWei}
            currentPriceWei={currentPriceWei}
            endPriceWei={endPriceWei}
            currencySymbol={currencySymbol}
          />
        )
      }
      <MintingButton
        projectId={projectId}
        priceWei={currentPriceWei}
        currencySymbol={currencySymbol}
        isConnected={isConnected}
        artistCanMint={artistCanMint}
        anyoneCanMint={anyoneCanMint}  
        scriptAspectRatio={scriptAspectRatio}  
      />
    </Box>
  )
}

export default MintingInterface