import { useState } from "react";
import {
  Box,
  Link,
  AppBar,
  Toolbar,
  Button,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import ClaimButton from "pages/ClaimButton";
import AddTokenButton from "./AddTokenButton";
import AddCustomChainButton from "./AddCustomChainButton";
import { useSendTransaction } from "wagmi";
import ChatMintButton from "pages/ChatMintButton";

const items = [
  {
    label: "Privacy Policy",
    url: "/privacy",
    enabled: true,
    target: "_self"
  },
  {
    label: "Terms and Conditions",
    url: "/terms",
    enabled: true,
    target: "_self"
  },
];

const Footer = () => {
  const { data: hash, sendTransaction } = useSendTransaction();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));


  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "rgba(255, 255, 255, 0.0)", boxShadow: "0px 0px 25px rgba(39, 170, 55, 0.0)" }}>
      <AppBar component="nav" position="sticky" elevation={1} sx={{ backgroundColor: "rgba(128, 226, 126, 0.0)", boxShadow: 0, zIndex: 100 }}>
        <Toolbar sx={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "rgba(128, 226, 126, 0.0)" }}>
          {/* Mobile Layout */}
          {isMobile && (
            <>
              <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 1, mb: 2 }}>
               
               
                <AddTokenButton />
                <AddCustomChainButton />
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 1, mb: 2 }}>
                <Link href="/" sx={{ display: "flex", width: '25vw', justifyContent: "center", mb: 1 }}>
                  <img src={`https://chatover.wine/media/logo.png`} alt="Sage Towers" width={'100%'} />
                </Link>
                {items.map((item) => (
                  <Link
                    key={item.label}
                    href={item.url}
                    target={item.target}
                    underline="hover"
                    sx={{ fontSize: 10, fontWeight: 300, color: item.enabled ? "primary" : "lightgrey", pointerEvents: item.enabled ? "auto" : "none" }}>
                    {item.label}
                  </Link>
                ))}
              </Box>
            </>
          )}
          {/* Desktop Layout */}
          {!isMobile && (
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ paddingRight: "35px", width: '9vw' }}>
                  <Link href="/" sx={{ display: "flex", width: '9vw' }}>
                    <img src={`https://chatover.wine/media/logo.png`} alt="Sage Towers" width={'100%'} />
                  </Link>
                </Box>
                <Box sx={{ display: { xs: "none", sm: "block" }, marginTop: "5px" }}>
                  {items.map((item) => (
                    <Link
                      key={item.label}
                      href={item.url}
                      target={item.target}
                      underline="hover"
                      sx={{ fontSize: 14, fontWeight: 600, color: item.enabled ? "primary" : "lightgrey", paddingRight: "25px", pointerEvents: item.enabled ? "auto" : "none" }}>
                      {item.label}
                    </Link>
                  ))}
                </Box>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            
                <AddTokenButton />
                <AddCustomChainButton />
                <Link href="https://dexscreener.com/solana/5Y3RHbBMDy9qEDamQz1sGFRB767nw6AnxirtugRJ3JNV" target="_blank" sx={{ m: 1 }}>
                  <img src={`https://chatover.wine/media/icons/dex.svg`} alt="Dex" height='28px' width='28px' />
                </Link>
                <Link href="https://solscan.io/token/DoVRzHXXicZucnuskdMg7DePhBHFkhvvhta5nf1deFoQ" target="_blank" sx={{ m: 1 }}>
                  <img src={`https://chatover.wine/media/icons/solana.svg`} alt="Scanner" height='28px' width='28px' />
                </Link>
                <Link href="https://t.me/chatoverwine" target="_blank" sx={{ m: 1 }}>
                  <img src={`https://chatover.wine/media/icons/telegram.svg`} alt="Telegram" height='27px' width='27px' />
                </Link>
                <Link href="https://twitter.com/chatover_wine" target="_blank" sx={{ m: 1 }}>
                  <img src={`https://chatover.wine/media/icons/twitter.svg`} alt="Twitter" height='27px' width='27px' />
                </Link>
                <Link href="https://amazastrophic.com" target="_blank" sx={{ m: 1 }}>
                  <img src={`https://chatover.wine/media/icons/amzcorp.png`} alt="Twitter" height='27px' width='27px' />
                </Link>
              </Box>
            </Box>
          )}
        </Toolbar>
        <Typography variant="body1" fontSize='11px' align="center" sx={{ mt: 2 }}>©2024 Chatover.wine</Typography>
      </AppBar>
    </Box>
  );
};

export default Footer;
