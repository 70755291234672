import React from 'react';
import { Typography, Paper, Button, Container, Card, CardContent, CardMedia } from '@mui/material';
import Page from 'components/Page';



  function Docs() {
    const botImages = [
      //'/media/bots/bot1.jpg',
      '/media/info1.png',
      '/media/info2.png',
      '/media/info3.png',
      '/media/info4.png',
      '/media/info5.png',
      '/media/info6.png',
      // Add more bot images as needed
    ];
    return (
      <Page>
        <Container maxWidth="md">
          {botImages.map((data, index) => (
            <Card key={index} sx={{ my: 2 }}>
              <CardMedia
                component="img"
                height="auto"
                image={botImages[index]} // Use .default for require() usage
      
              />
           
            </Card>
          ))}
        </Container>
      </Page>
    );
  }
export default Docs;
