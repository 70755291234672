import React from 'react';
import { Typography, Paper, Button, Container } from '@mui/material';
import Page from 'components/Page';

function FAQ() {
  return (
    <Page>
    <Container maxWidth="md" sx={{bgcolor:"rgba(223, 223, 233, 0.85)"}}>
     
        <Typography variant="h1" gutterBottom>
        FAQ
        </Typography>  
        <Typography variant="h1" gutterBottom>
        What chain?
        </Typography>
        <Typography paragraph>
        Solana for inital beta, CheeseChain for full launch at end of beta.
        </Typography>
        <Typography variant="h1" gutterBottom>
        How do the chatbot NFTS work?
        </Typography>
        <Typography paragraph>
        Creating a chatbot includes minting a new NFT. Owners can utilize their bespoke token to facilitate and manage interactions with their chatbot, enhancing user experience. Tokens facilitate transactions in a model that ensures equitable participation, with no presale or special allocations. Generated revenues are split between the NFT owner and Chatover.wine to sustain the ecosystem, including covering ongoing API costs.
        </Typography>
        <Typography variant="h1" gutterBottom>
        Is this like pump.fun or Friendtech
        </Typography>
        <Typography paragraph>
        Going to be like both. The creation of the chatbot NFT has a utility token launch with it. Its mostly for interacting with the bots, but only people who own tokens from your Chatover.wine NFTs can comment on your profile. So if your a creator you could make a bot for your fans to interact and generate content with. We have plans to add more classic social media functions. 
        </Typography>
        <Typography variant="h1" gutterBottom>
        What about chat bots and why?
        </Typography>
        <Typography paragraph>
        Chatbots powered by LLMs and crypto. Eventually they would have the ability to generate pictures, videos, music, and more tailored to them. We want to hook up all the AI tools to the chatbots that we can.
        </Typography>
        <Typography variant="h1" gutterBottom>
        What the rewards?
        </Typography>
        <Typography paragraph>
        You get tokens (points during beta) when interacting with chatbot. What exactly you can do with them would be up for the community to decide. At the very least you can be on the leaderboard.
        </Typography>
    
    </Container>
    </Page>
  );
}

export default FAQ;
