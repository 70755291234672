import React from 'react';
import { Stepper, Step, StepLabel, Typography, Box } from '@mui/material';
import Page from './Page';

function Roadmap() {
    const roadmapData = [
        { label: 'Launch Initial Chatbots', description: 'Engage users with original meme-inspired and relationship chatbots, powered by large language models for realistic conversations', completed: true },
        { label: 'Token Launch on Pump.fun', description: 'Launch of "Wine" ($WINE) to power utility within the platform - Time to Launch: SOON', completed: false },
        { label: 'Finish Leaderboards', description: 'Implement leaderboards for user engagement, chatbot ratings, and popular bot tokens', completed: false},
        { label: 'Finish Rooms', description: 'Club chat and an array of AI generation tools', completed: false},
        { label: 'Tokenomics and NFT Integration on Cheesechain', description: 'Introduce a bespoke token model and NFTs to enhance chatbot interactions and ensure equitable participation', completed: false },
        { label: 'Stake in the Wine Cellar', description: 'Put your Wine in the cellar and collect on the ecosystem fees', completed: false },
        { label: 'AI and Design Innovation', description: 'Utilize AI to aid in the creation and customization of unique NFT chatbots, enhancing user interaction and visual engagement', completed: false },
        { label: 'User Voice Capabilities', description: 'Introduce voice interaction features to enhance user communication', completed: false },
        { label: 'Multiplayer AI Games and Activities', description: 'Deploy multiplayer AI experiences with the chatbots enrich user engagement', completed: false },
      ];
  // Define the active step based on completion status; this example assumes nothing is completed.
  const activeStep = roadmapData.findIndex(step => !step.completed);

  return (
    <Page>
    <Box sx={{ width: '100%', bgcolor: 'rgba(223, 223, 233, 0.95)', padding:"3" }}>
    <Typography variant="h1">Road Map- Subject to Change</Typography>
      <Stepper activeStep={activeStep} orientation="vertical" sx={{ padding:"3", margin:"3" }}>
        {roadmapData.map((item, index) => (
          <Step key={item.label} completed={item.completed} sx={{ padding:"3", margin:"3" }}>
            <StepLabel sx={{ padding:"3", margin:"3" }} optional={index === roadmapData.length - 1 ? <Typography variant="h1"></Typography> : null}>
              <Typography variant="h2">{item.label}</Typography>
              <Typography variant="body1">{item.description}</Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box></Page>
  );
}

export default Roadmap;
