import { useParams } from "react-router-dom";
import Page from "components/Page";
import { Box, Button, Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import {
  useBalance,
  useAccount,
  useConnect,
  useDisconnect,
  useSignMessage,
  useContractReads
} from 'wagmi'

import VoidriteABI from 'abi/Voidrite.json' // Make sure you import the ABI of your contract here

import { BigNumber, ethers } from 'ethers'
import { ConnectButton } from "@rainbow-me/rainbowkit";
import Connect from "components/Connect";
import { Avatar } from '@mui/material';
import React, { useEffect, useState } from "react";

import InfoCard from "components/InfoCard";
import theme from "theme";
import Terms from "components/Terms";
import { Type } from "react-toastify/dist/utils";


const TermsPage = () => {
  
  return (
    <Page>
      <Box
        bgcolor="rgba(223, 223, 233, 0.85)"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          gap: 1.5,
          width: "100%",
          padding: "0.5rem",
          borderRadius: "3px",
        }}
      >
        <Typography variant="h1">
            Terms of Service
        </Typography>
        <Terms
        title="1. Introduction"
        text="Welcome to Chatover.wine (`We`, `Us`, or `Our`). This document serves as a legally binding agreement (`Terms and Conditions`) between you and Chatover.wine concerning your use of our AI chatbot service (`Service`). By using our Service, you agree to these Terms and Conditions in full."   
         />
           <Terms
        title="2. Registration and Access"
        text="You must be at least 18 years old to use the Services. You must provide complete and accurate data to register the account. You may not make your account open to others outside your organization, and you are responsible for all activities that occur with your account."   
         />
                 <Terms
        title="3. Privacy"
        text="Your privacy is critically important to us. Our Privacy Policy explains how we treat your personal data and protect your privacy when you use our Service. By using our Service, you agree that Chatover.wine can use your data in accordance with our Privacy Policy."   
         />
                 <Terms
        title="4. Intellectual Property"
        text="Unless otherwise stated, Chatover.wine and/or its licensors own the intellectual property rights for all material on Chatover.wine. All intellectual property rights are reserved."   
         />
                 <Terms
        title="5. OpenAI Usage"
        text="Chatover.wine utilizes OpenAI API. In accordance with OpenAI's use case policy, any information you provide while interacting with the Service is used to fulfill your request and improve the Service."   
         />
                 <Terms
        title="6. Restrictions"
        text="You are expressly and emphatically restricted from all of the following:

        Misusing our Service by knowingly introducing viruses, trojans, worms, or other material that is malicious or technologically harmful.
        Attempting to duplicate, reproduce, copy, sell, resell or exploit any portion of the Service, or access to the Service without express written permission by us."   
         />
                 <Terms
        title="7. Limitation of Liability"
        text="In no event shall Chatover.wine, nor any of its officers, directors and employees, be held liable for anything arising out of or in any way connected with your use of this Service, whether such liability is under contract, tort or otherwise, and Chatover.wine, including its officers, directors and employees shall not be liable for any indirect, consequential or special liability arising out of or in any way related to your use of this Service."   
         />
                 <Terms
        title="8. Indemnification"
        text="You hereby indemnify to the fullest extent Chatover.wine from and against any and all liabilities, costs, demands, causes of action, damages and expenses (including reasonable attorney’s fees) arising out of or in any way related to your breach of any of the provisions of these Terms."   
         />
                 <Terms
        title="8. Revisions and Errata"
        text="We reserve the right to modify these terms from time to time at our sole discretion. Therefore, you should review this page periodically. Your continued use of the Service after we post any modifications to the Terms on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Terms."   
         />
    
       
       </Box>
    </Page>
  );
};

export default TermsPage;
