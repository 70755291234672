import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import LandingPage from "pages/LandingPage"
import ProjectsPage from "pages/ProjectsPage"
import ProjectPage from "pages/ProjectPage"
import TokenPage from "pages/TokenPage"
//import Providers from "components/Providers"
import LoadingScreen from "components/LoadingScreen"
import SnapshotPage from "pages/SnapshotPage"
import ConnectPage from "pages/ConnectPage"

import TermsPage from "pages/TermsPage"
import PrivacyPage from "pages/PrivacyPage"
import StreamingPage from "pages/StreamingPage"
import GuidelinesPage from "pages/GuidelinesPage"
import LicensePage from "pages/LicensePage"
import Discord from "pages/Discord"


import RadiantGiftPage from "pages/RadiantGiftPage"
import GamePage from "pages/GamePage"
import LLMPage from "pages/LLMPage"
import { WagmiProvider, createConfig, http } from 'wagmi' 

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { RainbowKitProvider, Theme, connectorsForWallets, getDefaultConfig } from "@rainbow-me/rainbowkit"
import AppProvider from "components/Providers"

import "./styles.css";
import { AuthKitProvider } from '@farcaster/auth-kit';
import { SignInButton } from '@farcaster/auth-kit';
import ExtromonMintPage from "pages/ExtromonMintPage";
import ChatPage from "pages/ChatPage";
import Docs from "pages/Docs"
import Roadmap from "components/Roadmap"
import FAQ from "pages/FAQ"
import { injectedWallet, metaMaskWallet, rainbowWallet, walletConnectWallet } from '@rainbow-me/rainbowkit/wallets';

import Wine from "pages/Wine"
import { phantomWallet } from '@rainbow-me/rainbowkit/wallets';
import FrontPage from "pages/FrontPage"
import CookieConsent from "components/CookieConsent"
import BetaModal from "components/BetaModal"
import SimpleMeme from "pages/SimpleMeme"
import ChatBotForm from "pages/ChatBotForm"
import { WalletProvider } from "pages/WalletConnect"
import ChatbotNFT from "pages/ChatbotNFT"
import Bridge from "components/Bridge"
import StakingPage from "pages/StakingPage"
import { metaMask } from "wagmi/connectors"
import TwitterLogin from "components/TwitterLogin"
import BuyWine from "pages/BuyWine"
import ChatbotNFTAdmin from "pages/ChatbotNFTAdmin"
import { AuthProvider } from "components/AuthContext"
import ChatbotRoom from "pages/ChatbotRoom"
import ImageGenerator from "pages/ImageGenerator"
import Telegram from "pages/Telegram"
import TokenDepositPage from "pages/TokenDepositPage"
const customChain = {
  id: 383353,
  name: 'CheeseChain',
  network: 'CheeseChain',
  nativeCurrency: {
    name: 'Cheese',
    symbol: 'CHEESE',
    decimals: 18,
  },
  rpcUrls: {
    default: {
      http: ['https://rpc.cheesechain.xyz/http'],
       webSocket: ['wss://rpc.cheesechain.xyz/ws'],
    },
  },
  blockExplorers: {
    default: { name: 'CheeseChain Explorer', url: 'https://fetascan.xyz/' },
  },
  testnet: false, 
};
/*
Chain ID: 383353

RPC (HTTP) https://rpc.cheesechain.lol/http

RPC (WS) wss://rpc.cheesechain.lol/ws
*/



const config = getDefaultConfig({
  
  appName: 'chatover.wine',
  projectId: '731a10be83aed9071ea08e49fc2650c1',
  chains: [customChain],
  wallets: [
    {
      groupName: 'Recommended Wallets',
      wallets: [injectedWallet, rainbowWallet, walletConnectWallet, metaMaskWallet],
    }
  ],

 // ssr: true, // If your dApp uses server side rendering (SSR)
  
});


const configz = {
  rpcUrl: 'https://mainnet.optimism.io',
  domain: 'chatover.wine',
  siweUri: 'https://api.chatover.wine/api/loginFC',
};

const queryClient = new QueryClient() 
const myCustomTheme: Theme = {
  blurs: {
    modalOverlay: 'blur(8px)',
  },
  colors: {
    accentColor: '#A52A2A', // Bright Yellow background for the upcoming event button
    accentColorForeground: '#f0f0f0', // Charcoal Black for text
    actionButtonBorder: '#A52A2A', // Auburn Red for primary buttons
    actionButtonBorderMobile: '#A52A2A', 
    actionButtonSecondaryBackground: '#808080', // Monochrome Grey for secondary buttons
    closeButton: '#F44336', // Error state button color
    closeButtonBackground: '#232333',
    connectButtonBackground: 'rgba(0,0,0,0)',
    connectButtonBackgroundError: '#F44336',
    connectButtonInnerBackground: '#232333',
    connectButtonText: '#A52A2A',
    connectButtonTextError: '#F44336',
    connectionIndicator: '#808080',
    downloadBottomCardBackground: '#232333',
    downloadTopCardBackground: '#A52A2A',
    error: '#F44336',
    generalBorder: '#e5e7eb55', // Border color for general container
    generalBorderDim: '#808080',
    menuItemBackground: '#232333',
    modalBackdrop: 'rgba(0, 0, 0, 0.5)', // Dimmed background for overlay
    modalBackground: '#232333',
    modalBorder: '#808080',
    modalText: '#f0f0f0', // Pale Flesh for menu item accents
    modalTextDim: '#808080',
    modalTextSecondary: '#808080',
    profileAction: '#A52A2A',
    profileActionHover: '#F44336',
    profileForeground: '#232333',
    selectedOptionBorder: '#808080',
    standby: '#808080',
  },
  fonts: {
    body: 'Red Hat Text, "Anton", "Geometric", "Segoe UI", "Helvetica Neue", "Arial", "sans-serif"',
  },
  radii: {
    actionButton: '4px',
    connectButton: '4px',
    menuButton: '4px',
    modal: '6px',
    modalMobile: '6px',
  },
  shadows: {
    connectButton: '0 6px 12px #0000001f', // Box shadow for specific items
    dialog: '0 6px 12px #0000001f',
    profileDetailsAction: '0 6px 12px #0000001f',
    selectedOption: '0 6px 12px #0000001f',
    selectedWallet: '0 6px 12px #0000001f',
    walletLogo: '0 6px 12px #0000001f',
  },
};

// theme={darkTheme()}>


function App() {
  return (
    
   

           <WagmiProvider config={config}>
              <QueryClientProvider client={queryClient}>
                <RainbowKitProvider theme={myCustomTheme}>
                  <WalletProvider>
                <AppProvider>
                <AuthProvider>
        <Router>
          <AuthKitProvider config={configz}>
         
                  <LoadingScreen />
                  <CookieConsent />
                  <Routes>
                    <Route path="/chatbot/:tokenId" element={<ChatbotNFT/>} />
                   
                    <Route index element={<FrontPage />} />
                    <Route path="bridge" element={<Bridge />} />
                    <Route path="/chatbot/:tokenId/admin" element={<ChatbotNFTAdmin />} />
                    <Route path="/chatbot/:tokenId/aitools" element={<ImageGenerator />} />
                    <Route path="/chatbot/:tokenId/chatbotroom" element={<ChatbotRoom />} />
                    <Route path="/profile" element={<TwitterLogin />} />
                    <Route path="cellar" element={<StakingPage />} />
                    <Route path="telegramLink" element={<Telegram />} />
                    <Route path="discordLink" element={<Discord />} />
                    <Route path="evmdeposit/:tokenId" element={<TokenDepositPage />} />
                    <Route path="evmdeposit" element={<TokenDepositPage />} />
                    <Route path="buywine" element={<BuyWine />} />
                    <Route path="projects" element={<ProjectsPage />} />
                    <Route path="project/:projectId" element={<ProjectPage />} />
                    <Route path="token/:id" element={<TokenPage />} />
                    <Route path="snapshot" element={<SnapshotPage />} />
                    <Route path="profile" element={<ConnectPage />} />
                    <Route path="extromon" element={<ExtromonMintPage />} />
                    <Route path="terms" element={<TermsPage />} />
                    <Route path="privacy" element={<PrivacyPage />} />
                    <Route path="streaming" element={<StreamingPage />} />
                    <Route path="guidelines" element={<GuidelinesPage />} />
                    <Route path="license" element={<LicensePage />} />
                    <Route path="game" element={<GamePage />} />
                    <Route path="radiantgifts" element={<RadiantGiftPage />} />
                    <Route path="radiantgift" element={<RadiantGiftPage />} />
                    <Route path="llm" element={<LLMPage />} />
                    <Route path="bridge" element={<LLMPage />} />
                    <Route path="chat" element={<ChatPage botIndex={0} onBackToLeaderboard={() => {}} />} />
                    <Route path="docs" element={<Docs />} />
                    <Route path="roadmap" element={<Roadmap />} />
                    <Route path="faq" element={<FAQ />} />
                    <Route path="wine" element={<Wine />} />
                    <Route path="leaderboard" element={<FrontPage />} />
                    <Route path="makebot" element={<ChatBotForm />} />
                    <Route path="wineglass" element={<SimpleMeme />} />
                  </Routes>
            
          </AuthKitProvider>
        </Router>
        <ToastContainer
          autoClose={10000}
          position="bottom-right"
          theme="dark"
          newestOnTop
          pauseOnHover
          pauseOnFocusLoss
        />  
           </AuthProvider>
            </AppProvider>
            </WalletProvider>
    </RainbowKitProvider>
    </QueryClientProvider>
               </WagmiProvider>
              
    

  );
}
export default App
