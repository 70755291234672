import CssBaseline from "@mui/material/CssBaseline";
import "@rainbow-me/rainbowkit/styles.css";
import { ThemeProvider } from "@mui/material/styles";
import theme from "theme";
import { Chain, RainbowKitProvider, getDefaultWallets, Theme } from "@rainbow-me/rainbowkit";
//import { configureChains, createClient, WagmiConfig, jsonRpcProvider, publicProvider } from "wagmi";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
//import { GRAPHQL_URL } from "config";
import { arbitrum, arbitrumNova, arbitrumSepolia } from 'wagmi/chains';
import { GRAPHQL_URL, INFURA_KEY, EXPECTED_CHAIN_ID } from "config"
//  import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';
import { WagmiProvider, http, webSocket } from 'wagmi';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { getDefaultConfig } from '@rainbow-me/rainbowkit'
import { mainnet } from 'wagmi/chains'
//import { RainbowKitProvider } from '@rainbow-me/rainbowkit'



//import { SageTowers, arbitrumNova} from '@wagmi/chains'

const myCustomTheme: Theme = {
  blurs: {
    modalOverlay: '...',
  },
  colors: {
    accentColor: '#80e27e',
    accentColorForeground: '#232333',
    actionButtonBorder: '#009688 ',
    actionButtonBorderMobile: '#009688 ',
    actionButtonSecondaryBackground: '#232333',
    closeButton: '#F44336',
    closeButtonBackground: '#232333',
    connectButtonBackground: 'rgba(0,0,0,0)',
    connectButtonBackgroundError: '#F44336',
    connectButtonInnerBackground: '#232333',
    connectButtonText: '#009688',
    connectButtonTextError: '#009688',
    connectionIndicator: '#41b3a3',
    downloadBottomCardBackground: '#232333',
    downloadTopCardBackground: '#80e27e',
    error: '#F44336',
    generalBorder: '#fffff',
    generalBorderDim: '#009688',
    menuItemBackground: '#232333',
    modalBackdrop: '...',
    modalBackground: '#232333',
    modalBorder: '#41b3a3',
    modalText: '#009688  ',
    modalTextDim: '#41b3a3',
    modalTextSecondary: '#41b3a3',
    profileAction: '#80e27e',
    profileActionHover: '#F44336',
    profileForeground: '#232333',
    selectedOptionBorder: '#41b3a3',
    standby: '#41b3a3',
  },
  fonts: {
    body: 'Anton',
  },
  radii: {
    actionButton: '...',
    connectButton: '...',
    menuButton: '...',
    modal: '...',
    modalMobile: '...',
  },
  shadows: {
    connectButton: '...',
    dialog: '...',
    profileDetailsAction: '...',
    selectedOption: '...',
    selectedWallet: '...',
    walletLogo: '...',
  },
};

const client = new ApolloClient({
  uri: GRAPHQL_URL,
  cache: new InMemoryCache()
});
const mainnetChain = arbitrumNova; // For mainnet, use Arbitrum Nova
const testnetChain = arbitrumSepolia; // For testnet, use Arbitrum Sepolia
const snapshotClient = new ApolloClient({
  uri: 'https://hub.snapshot.org/graphql',
  cache: new InMemoryCache()
});
const config = getDefaultConfig({
  appName: 'Sage Towers',
  projectId: '5dba7d8776832d2979eb0462fa9524b8',
  chains: [
   // arbitrumNova,
    arbitrumSepolia
  ],
  transports: {
   // [arbitrumNova.id]: http(),
    [arbitrumSepolia.id]:  webSocket('wss://frequent-divine-spree.arbitrum-sepolia.quiknode.pro/'), 
  },
})
const queryClient = new QueryClient() 
//const queryClient = new QueryClient()
// Define Arbitrum chains based on the environment or configuration


// Determine the expected chains based on your environment or configuration
const expectedChains = EXPECTED_CHAIN_ID === mainnetChain.id ? [mainnetChain] : [testnetChain];
/*
const { chains, provider, webSocketProvider } = configureChains(
  expectedChains,
  [
    publicProvider(),
    // Add custom RPC providers if necessary
    jsonRpcProvider({
      rpc: (chain) => {
        if (chain.id === mainnetChain.id) return { http: "YOUR_ARBITRUM_NOVA_RPC_URL" };
        if (chain.id === testnetChain.id) return { http: "YOUR_ARBITRUM_SEPOLIA_RPC_URL" };
        return {};
      }
    }),
  ]
);
*//*
const { connectors } = getDefaultWallets({
  appName: "Your App Name",
  chains
});
*/
//
/*
const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
  webSocketProvider
});  </WagmiProvider>      </RainbowKitProvider>
<RainbowKitProvider theme = {myCustomTheme} >
 <WagmiProvider config={config}>
*/

interface Props {
  children: React.ReactNode
}

const AppProvider = ({children}:Props) => {
  return (  
   
   <QueryClientProvider client={queryClient}>
    
        <ApolloProvider client={client}>
          <ApolloProvider client={snapshotClient}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              {children}
            </ThemeProvider>
          </ApolloProvider>
        </ApolloProvider>
  
     </QueryClientProvider>
  
  );
};

export default AppProvider;