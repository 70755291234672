import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Button, TextField, Typography, Snackbar, Alert, Select, MenuItem, FormControl, InputLabel, Container, Link } from '@mui/material';
import Cookies from 'js-cookie';
import PageRedux from 'components/PageRedux';
import { useReadContract } from 'wagmi';
import NFTGate from './NFTGate';
interface NFTMetadata {
  name: string;
  description: string;
  image: string;
  attributes: { trait_type: string; value: string }[];
}
interface MainContentState {

  botBackgroundImage: string;
  botImage: string;
  botName: string;
  botDescription: string;
  botPrompt: string;
  chatHistory: { id: number; text: string }[];
  
}
const ImageGenerator: React.FC = () => {
  const [prompt, setPrompt] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [models, setModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState('');

  
const CONTRACT_ADDRESS_NFT = '0xA8675eaacFCB964a81e360B857e06dbedc2F003d';
const CONTRACT_ABI_NFT = [
  {
      "inputs": [
          {
              "internalType": "uint256",
              "name": "tokenId",
              "type": "uint256"
          }
      ],
      "name": "tokenURI",
      "outputs": [
          {
              "internalType": "string",
              "name": "",
              "type": "string"
          }
      ],
      "stateMutability": "view",
      "type": "function"
  },
  {
      "inputs": [
          {
              "internalType": "uint256",
              "name": "tokenId",
              "type": "uint256"
          }
      ],
      "name": "getTokenAddress",
      "outputs": [
          {
              "internalType": "address",
              "name": "",
              "type": "address"
          }
      ],
      "stateMutability": "view",
      "type": "function"
  },
  {
      "inputs": [
          {
              "internalType": "uint256",
              "name": "tokenId",
              "type": "uint256"
          }
      ],
      "name": "ownerOf",
      "outputs": [
          {
              "internalType": "address",
              "name": "",
              "type": "address"
          }
      ],
      "stateMutability": "view",
      "type": "function"
  }
];

  const getPathTokenID = () => {
    const pathname = window.location.pathname;
    const segments = pathname.split('/');
    return segments[2];
  };
  const [state, setState] = React.useState<MainContentState>({
    botBackgroundImage: '',
    botImage: '',
    botName: '',
    botDescription: '',
    botPrompt: '',
    chatHistory: [],
  });
  
  const tokenID = getPathTokenID();
  const { data: tokenUri } = useReadContract({
    address: CONTRACT_ADDRESS_NFT,
    abi: CONTRACT_ABI_NFT,
    functionName: 'tokenURI',
    args: [tokenID],
  });
  useEffect(() => {
    if (tokenUri) {
      console.log("Token URI: " + tokenUri);
      const jsonPart = tokenUri.toString().split(',')[1]; // Get the Base64 encoded JSON
      if (jsonPart) {
        const decodedJson = atob(jsonPart); // Decode Base64
        const parsedMetadata = JSON.parse(decodedJson); // Parse the JSON
        setNftMetadata(parsedMetadata); // Set the parsed metadata to state
        state.botName=parsedMetadata.name;
        state.botDescription=parsedMetadata.description;
        state.botImage=parsedMetadata.image;
      }
    }

  }, [tokenUri]);
  const [nftMetadata, setNftMetadata] = useState<NFTMetadata | null>(null);
  const [loggedIn, setLogin] = useState(false);
    const getToken = () => {
        const token = Cookies.get('jwtToken');
        if (token) {
         
            setLogin(true);
        } else {
            console.log('Token not found');
        }
        return token;
    };

    const setToken = (token: string, days: number) => {
        Cookies.set('jwtToken', token, { expires: days, secure: true, sameSite: 'Strict' });
        console.log('Token set:', token);
    };
    
    const clearToken = () => {
        Cookies.remove('jwtToken');
        console.log('Token cleared');
    };

    useEffect(() => {
        const requestInterceptor = axios.interceptors.request.use((config) => {


        const token = getToken();
      //  console.log('Token from interceptor:', token);
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
        });
    
        return () => {
        axios.interceptors.request.eject(requestInterceptor);
        };
    }, []);
    const [bg, setBG] = useState('');
  const fetchNFTData = async (tokenId: string) => {
    try {
        const response = await fetch(`https://api.chatover.wine/api/getNFTExtraImage?tokenId=${tokenId}`);
        const data = await response.json();
        if (response.ok) {
            console.log('NFT Data:', data);
            state.botBackgroundImage=data;
            setBG(data);
            console.log('NFT Data:', state.botBackgroundImage);
            return data;
        } else {
            console.log('Error fetching NFT:', data.message);
            return null;
        }
    } catch (error) {
        console.error('Network error:', error);
        return null;
    }
}

useEffect(() => {
  if (tokenID) {
      fetchNFTData(tokenID).then((nftData: any) => {
          if (nftData) {
              // Set state with fetched data
              console.log('NFT Loaded:', nftData);
          }
      });
  }
}, [tokenID]);
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  useEffect(() => {
    async function fetchModels() {
      setLoading(true);
      try {
        const response = await axios.get('https://api.chatover.wine/api/imagemodels');
        const modelNames = await response.data;
        setModels(modelNames);
        if (modelNames.length > 0) {
          setSelectedModel(modelNames[0]);
        }
      } catch (error) {
        console.error('Failed to fetch models:', error);
      }
      setLoading(false);
    }

    fetchModels();
  }, []);

  const handleSubmit = async (useMemePrompt: boolean) => {
    setLoading(true);
    const submissionPrompt = useMemePrompt ? prompt : prompt;
    try {
      const response = await axios.post('https://api.chatover.wine/api/generateimage', { prompt: submissionPrompt, modelKey: selectedModel, botID: tokenID }, {
        headers: {
          Authorization: `Bearer ${getToken()}`
        }
      });
      console.log('Image generated:', response.data[0]);
      setImageUrl(response.data[0]);
    } catch (error) {
      console.error('Error generating image:', error);
      if (axios.isAxiosError(error) && error.response && error.response.status === 500) {
        setError('There was an issue with the image prompt. Please try a different prompt.');
        setOpenSnackbar(true);
      }
      else   if (axios.isAxiosError(error) && error.response && error.response.status === 403) {
        if(nftMetadata){
        setError(`You need to hold 10,000 ${nftMetadata?.name} coins to use this.`);
        setOpenSnackbar(true);
        }
      }
    } finally {
      setLoading(false);
    }
  };
  const URL = "../chatbot/"+tokenID;
fetchNFTData(tokenID);

  return (
    <PageRedux backgroundImage={bg}> 
       <Container maxWidth="sm" sx={{ mt: 4 , bgcolor: "rgba(223, 223, 233, 0.95)"}}>
       <NFTGate>
       <Box my={2} width="100%" maxHeight="100%"  >
       <Button variant="contained" color="primary" href = {URL} sx={{ m: 1 }}>
          Back to Main
        </Button>

        {nftMetadata && (
          <>
          <Typography variant="h1" gutterBottom>
          {nftMetadata.name}
          </Typography>
     

        
            
              
          
              <Box flexDirection="column" alignItems="center" sx={{ display: 'flex', justifyContent: 'center',  p: 2 }}>

                <img src={nftMetadata.image} alt={nftMetadata.name} style={{ maxWidth: '50%', borderRadius: '10px' }} />
      
              </Box>
             
              <Typography variant="body1">
                {nftMetadata.description}
              </Typography>
          
</>
           
          )}
        </Box>
       
       
        {loggedIn && (
          <>
        <Typography variant="h6" gutterBottom>
          Image Generator
        </Typography>
        <Typography variant="h6" gutterBottom>
          Community Gallery Coming Soon
        </Typography>
       
        <FormControl fullWidth  sx={{ mt: 4 }}>
          <InputLabel id="model-select-label">Model</InputLabel>
          <Select
            labelId="model-select-label"
            id="model-select"
            value={selectedModel}
            label="Model"
            onChange={(e) => setSelectedModel(e.target.value)}
            disabled={loading}
           
          >
            {models.map((model, index) => (
              <MenuItem key={index} value={model}>
                {model}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label="Enter image or meme prompt"
          variant="outlined"
          size="small"
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          fullWidth
          disabled={loading}
          sx={{ mt: 4, mb:4 }}
        />
        <Button
          onClick={() => handleSubmit(false)}
          variant="contained"
          color="primary"
          style={{ margin: '10px 0' }}
          disabled={loading || models.length === 0}
        >
          {loading ? 'Generating...' : 'Generate Image'}
        </Button></>)}
      
        {imageUrl && (
          <img src={imageUrl} alt="Generated from prompt" style={{ marginTop: '20px', maxWidth: '100%', height: 'auto' }} />
        )}
               {!loggedIn && (
  <Typography variant="h1">
  Log in to use!
</Typography>
)}
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            {error}
          </Alert>
        </Snackbar>
         </NFTGate>
      </Container>
    </PageRedux>
  );
};

export default ImageGenerator;
