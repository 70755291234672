import React, { useEffect, useState } from 'react';
import { useAccount, useConnect, useDisconnect, useSignMessage } from 'wagmi';
import { RainbowKitProvider, ConnectButton } from '@rainbow-me/rainbowkit';
import { Button, Box } from '@mui/material';
import axios from 'axios';
import Cookies from 'js-cookie';

const ConnectWallet = () => {
  const { address, isConnected } = useAccount();
  const { connect, connectors } = useConnect();
  const { disconnect } = useDisconnect();
  const { signMessageAsync } = useSignMessage();
  const [loggedIn, setLoggedIn] = useState(false);

  const setCookie = (name: string, value: any, days: number) => {
    Cookies.set(name, value, { expires: days, secure: true, sameSite: 'Strict' });
  };

  const clearCookie = (name: string) => {
    Cookies.remove(name);
  };

  
  const handleSignMessage = async () => {
    try {
      const message = 'To verify your wallet and login to https://Chatover.wine, sign this message';
      const signature = await signMessageAsync({ message });

      const loginResponse = await axios.post('https://api.chatover.wine/api/login', {
        signature,
        message,
        address,
      });

      if (loginResponse.data.isValid) {
        setLoggedIn(true);
        setCookie('jwtToken', loginResponse.data.token, 1);
        console.log('Login successful');
      } else {
        console.error('Login failed: Invalid signature');
      }
    } catch (error) {
      console.error('Signing failed:', error);
    }
  };

  useEffect(() => {
    const checkSession = async () => {
      const token = Cookies.get('jwtToken');
      if (!token) return;

      try {
        const response = await axios.get('https://api.chatover.wine/api/validate-session', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        if (response.data.isValid) {
          setLoggedIn(true);
          console.log('Session validated');
        }
      } catch (error) {
        console.error('Session validation failed:', error);
        clearCookie('jwtToken');
      }
    };

    checkSession();
  }, [setLoggedIn, clearCookie]);

  const handleDisconnect = () => {
    disconnect();
    clearCookie('jwtToken');
    setLoggedIn(false);
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
      {!isConnected ? (
        <ConnectButton />
      ) : (
        <>
          {loggedIn ? (
            <Button variant="contained" color="primary" onClick={handleDisconnect}>
              Logout
            </Button>
          ) : (
            <Button variant="contained" color="primary" onClick={handleSignMessage}>
              Login
            </Button>
          )}
        </>
      )}
    </Box>
  );
};

export default ConnectWallet;