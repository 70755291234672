import React, { useState, useEffect } from 'react';
import { FormControl, FormLabel, TextField, Checkbox, FormControlLabel, Select, MenuItem, Box, SelectChangeEvent, Typography } from '@mui/material';

interface TopSelectionsProps {
    name: string;
    setName: React.Dispatch<React.SetStateAction<string>>;
    sex: string;
    setSex: React.Dispatch<React.SetStateAction<string>>;
    isMature: boolean;
    setIsMature: React.Dispatch<React.SetStateAction<boolean>>;
    voice: boolean;
    setVoice: React.Dispatch<React.SetStateAction<boolean>>;
  }
  
  const TopSelections: React.FC<TopSelectionsProps> = ({
    name, setName, sex, setSex, isMature, setIsMature, voice, setVoice
  }) => {
  //  const [name, setName] = useState('');
   // const [isMature, setIsMature] = useState(false);
   // const [sex, setSex] = useState('');  const [sex, setSex] = useState('male'); 
   // const [voice, setVoice] = useState(true);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    const handleMatureChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsMature(event.target.checked);
    };

    const handleSexChange = (event: SelectChangeEvent<string>) => {
        setSex(event.target.value);
    };

    const handleVoiceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setVoice(event.target.checked);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 600);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Box
            bgcolor="rgba(223, 223, 233, 0.0)"
            sx={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                gap: 1.5,
                width: "100%",
                padding: "0.5rem",
                borderRadius: "3px",
            }}
        >
            <Typography variant="body1">
                Your Info:
            </Typography>
            <FormControl variant="outlined" sx={{ width: '100%', minWidth: 120 }}>
                <FormLabel>Name</FormLabel>
                <TextField
                    value={name}
                    onChange={handleNameChange}
                    fullWidth
                />
            </FormControl>

            <FormControl variant="outlined" sx={{ width: '100%', minWidth: 120 }}>
                <FormLabel>Sex</FormLabel>
                <Select
                    value={sex}
                    onChange={handleSexChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    fullWidth
                >
                    <MenuItem value="male">
                        Male
                    </MenuItem>
                    <MenuItem value="female">Female</MenuItem>
                    
                </Select>
            </FormControl>

            <FormControlLabel
                control={<Checkbox checked={isMature} onChange={handleMatureChange} />}
                label="Mature"
                sx={{ width: '100%' }}
            />

            <FormControlLabel
                control={<Checkbox checked={voice} onChange={handleVoiceChange} />}
                label="Voice"
                sx={{ width: '100%' }}
            />
        </Box>
    );
};

export default TopSelections;
