import React, { useEffect, useState } from 'react';
import { useReadContract, useWriteContract, useWaitForTransactionReceipt, useAccount } from 'wagmi';
import { TextField, Button, Typography, Box } from '@mui/material';
import { parseEther } from 'ethers/lib/utils';
import Page from 'components/Page';
import { ethers } from 'ethers';
import PageRedux from 'components/PageRedux';

const CONTRACT_ADDRESS = '0x2F171Fab888aACcbdb9261764c9e2Bd59F245adE'; // Replace with the actual contract address
const CONTRACT_ABI =  [
    {
        "inputs": [],
        "name": "buyTokens",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "pricePerToken",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "getTokenBalance",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }
]; // Replace with the actual contract ABI

const BuyWine = () => {
  const [amount, setAmount] = useState('');
  const [statusMessage, setStatusMessage] = useState('');
  const [statusSeverity, setStatusSeverity] = useState('info');

  const { data: pricePerToken } = useReadContract({
    address: CONTRACT_ADDRESS,
    abi: CONTRACT_ABI,
    functionName: 'pricePerToken',
  });

  const { data: tokenBalance } = useReadContract({
    address: CONTRACT_ADDRESS,
    abi: CONTRACT_ABI,
    functionName: 'getTokenBalance',
  });

  const [priceAsString, setPriceAsString] = useState('not available');
  const [balanceAsString, setBalanceAsString] = useState('not available');

  // Function to update formatted values
  useEffect(() => {
    if (pricePerToken) {
        const weiBigNumber = ethers.utils.formatEther(pricePerToken.toString()); // Convert input string to BigNumber in wei
       
        const weiBigInt = BigInt(pricePerToken.toString());
      //const formattedPrice = BigInt(parseEther(pricePerToken.toString()));
      setPriceAsString(weiBigNumber.toString()); // Convert BigInt back to string for display
    }
    if (tokenBalance) {
      const formattedBalance = ethers.utils.formatEther(tokenBalance.toString());
      setBalanceAsString(formattedBalance.toString()); // Convert BigInt back to string for display
    }
  }, [pricePerToken, tokenBalance]); // Dependency array to track changes
  
  const { data: hash, error, isPending, writeContract } = useWriteContract();
  const { isLoading: isConfirming, isSuccess: isConfirmed, data: receiptData } = useWaitForTransactionReceipt({ hash });
  const account = useAccount();
  const handleClick =  () => {

    const ethValueBigInt = BigInt(parseEther(amount).toString());
     writeContract({
      address: CONTRACT_ADDRESS,
      abi: CONTRACT_ABI,
      functionName: 'buyTokens',
      value: ethValueBigInt,
   
    });
  };
  useEffect(() => {
    if (isPending) {
      setStatusMessage('Transaction is pending...');
      setStatusSeverity('info');
     // setOpen(true);
    } else if (isConfirming) {
      setStatusMessage('Transaction is confirming...');
      setStatusSeverity('info');
      //setOpen(true);
    } else if (isConfirmed) {
      setStatusMessage('Transaction confirmed!');
      setStatusSeverity('success');
      console.log("Did it");
     // onApprovalSuccess(); 
     // setOpen(true);
    } else if (error) {
      setStatusMessage(`Error: ${error.message}`);
      setStatusSeverity('error');
      //setOpen(true);
    }
  }, [isPending, isConfirming, isConfirmed, error]);

  useEffect(() => {
    if (isConfirmed && receiptData) {
      console.log("Transaction successful with receipt:", receiptData);
      setTimeout(() => {
        //setOpen(false);
      }, 2500);
    }
  }, [isConfirmed, receiptData]);

  const handleClose = () => {
   // setOpen(false);
  };

  const handleBuyTokens = async () => {
    if (!amount) {
      setStatusMessage('Please enter an amount.');
      setStatusSeverity('error');
      return;
    }

    setStatusMessage('Initiating token purchase...');
    setStatusSeverity('info');

    try {
      const tx = await handleClick();
      setStatusMessage('Transaction pending...');
    //  await tx.wait();
      setStatusMessage('Tokens purchased successfully!');
      setStatusSeverity('success');
    } catch (error) {
      console.error('Error buying tokens:', error);
      setStatusMessage('Error buying tokens. Please try again.');
      setStatusSeverity('error');
    }
  };

  return (
 
        <PageRedux backgroundImage={'/media/cell.jpg'}>
     <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '85vh', // Adjusted height for less vertical space
        backgroundColor: "rgba(223, 223, 233, 0.0)",
      }}
    >
     
      <Box
        sx={{
          maxWidth: 600,
          padding: 3,
          backgroundColor: "rgba(223, 223, 233, 0.8)",
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(10,10,10,0.1)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          minHeight: '35vh',
          gap: 2,
          border: '1px solid rgba(0, 0, 0, 0.1)', // Optionally added a subtle border
        }}
      >
    
      <Typography variant="h4" gutterBottom>
        Buy Wine on CheeseChain
      </Typography>
      <Box margin={"1rem"} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <img src="./media/icon.png" alt="Status" style={{ width: '30%', height: '30%' }} />
              </Box>
      <Typography>Price per token: {priceAsString} Cheese</Typography>
      <Typography>Tokens available: {balanceAsString}</Typography>
      <TextField
        label="Amount"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
        fullWidth
        margin="normal"
      />
      <Button variant="contained" color="primary" onClick={handleClick}>
        Buy Tokens
      </Button>
      {statusMessage && (
        <Typography color={statusSeverity}>{statusMessage}</Typography>
      )}
    
    </Box>
    </Box>
    
    </PageRedux>
  );
};

export default BuyWine;