import { useParams } from "react-router-dom";
import Page from "components/Page";
import { Box, Button, Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import {
  useBalance,
  useAccount,
  useConnect,
  useDisconnect,
  useSignMessage,
  useContractReads
} from 'wagmi'

import VoidriteABI from 'abi/Voidrite.json' // Make sure you import the ABI of your contract here

import { BigNumber, ethers } from 'ethers'
import { ConnectButton } from "@rainbow-me/rainbowkit";
import Connect from "components/Connect";
import { Avatar } from '@mui/material';
import React, { useEffect, useState } from "react";

import InfoCard from "components/InfoCard";
import theme from "theme";
import Terms from "components/Terms";
import { Type } from "react-toastify/dist/utils";


const PrivacyPage = () => {
  
  return (
    <Page>
      <Box
         bgcolor="rgba(223, 223, 233, 0.85)"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          gap: 1.5,
          width: "100%",
          padding: "0.5rem",
          borderRadius: "3px",
        }}
      >
        <Typography variant="h1">
        Privacy Policy
        </Typography>
        <Terms
        title="1. Introduction"
        text="Welcome to Chatover.wine, This Privacy Policy explains what information we collect, how we use it, and your rights in relation to it. By using our service, you acknowledge that you have read and understood this Privacy Policy."   
         />
                 <Terms
        title="2. Information We Collect"
        text="To provide the best possible service to you, we collect the following types of information:

        User Inputs: Any data that you voluntarily provide while interacting with our Service is collected and stored. This data includes, but is not limited to, text inputs, audio inputs, and visual inputs.
        
        Metadata: Information related to your use of the Service, such as the time and date of your interactions, is collected."   
         />
                 <Terms
        title="3. How We Use Your Information"
        text="We primarily use your information to enhance our Service and provide you with a personalized experience. Specifically, we use your information for the following purposes:

        AI Embeddings: We convert your data into numerical vectors and use it to train our AI system. This helps us improve our Service and create a more life-like and realistic metaverse.
        
        Service Improvement: We use the data you provide to understand how you interact with our Service and to make improvements.
        
        Please note that once your data has been converted into a vector and added to our database, it cannot be removed. This is because your data is transformed and aggregated in a way that it no longer can be associated with you personally and thus becomes part of the collective intelligence of our Service."   
         />
                 <Terms
        title="4. Sharing of Your Information"
        text="We do not share your personal data with any third parties, except as necessary to provide the Service or as required by law. This may include sharing with service providers or legal authorities."   
         />
                 <Terms
        title="5. Your Rights"
        text="You have certain rights concerning your data, subject to local data protection laws. Depending on the applicable laws these rights may include:

        To access your personal data held by us.
        To know more about how we process your data.
        To rectify any inaccurate or incomplete personal data we hold about you.
        To withdraw consent to our use of your personal data.
        However, please note that due to the nature of our Service, once your data has been provided and converted into AI vectors, it cannot be removed or deleted."   
         />
                 <Terms
        title="6. Changes to This Policy"
        text="We may revise this Privacy Policy from time to time. The most current version of the policy will govern our processing of your personal data and will always be available to you."   
         />

       
       </Box>
    </Page>
  );
};

export default PrivacyPage;
