import { useParams } from "react-router-dom";
import Page from "components/Page";
import DiscordLogin from "components/DiscordLogin";
import { Box, Button, Grid, Typography } from "@mui/material";
import { AccountCircle, JoinInnerSharp } from "@mui/icons-material";
//import TwitterLogin from "components/TwitterLogin";
import { useProfile, ProfileData } from '../hooks/useProfile';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { getBalance, switchNetwork } from '@wagmi/core'
import {
  useAccount,
  useBalance,
  useConnect,
  useContractReads,
  useDisconnect,
  useEnsAvatar,
  useEnsName,
  useSignMessage,
 // useNetwork,
} from 'wagmi'
import { formatUnits } from 'viem' 
import { useSignInMessage, useProfile as useFarcasterProfile, SignInButton } from '@farcaster/auth-kit';
import '@farcaster/auth-kit/styles.css';
import { AuthKitProvider } from '@farcaster/auth-kit';
import Connect from "components/Connect";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { BigNumber } from "ethers";
//import VoidriteStake from "components/VoidriteStake";
//import VoidriteUnstake from "components/VoidriteUnstake";
//import VoidriteABI from 'abi/Voidrite.json'
import { EXPECTED_CHAIN_ID, config } from "config";
const ConnectPage = () => {

 // const { chain, chains } = useNetwork();
  const { address , connector, isConnected } = useAccount();
// const { data: ensAvatar } = useEnsAvatar({ address,chainId: 1 }   )
 /// const { data: ensName } = useEnsName({ address,chainId: 1 })
 const { connect, connectors, error,  } =
    useConnect()
  const { disconnect } = useDisconnect()
/*
const voidriteContract = {
  address: '0x3ab85c1ED41A9f8275f7a446DaF5D7426e8eC839', // Replace with your contract address
  abi: VoidriteABI,
}
*/
const [StakedBalance, setStakedBalance] = useState<any | BigNumber>(BigNumber.from(0));
const [VoidriteBalance, setVoidriteBalance] = useState<any | BigNumber>(BigNumber.from(0));
const [RemainingCooldownTime, setRemainingCooldownTime] = useState<any |  BigNumber>(BigNumber.from(0));
/*
const { data, isError, refetch } = useContractReads({
contracts: [
 
  {
    ...voidriteContract,
    functionName: 'getStakerBalance',
    args: [address],
  },
  {
    ...voidriteContract,
    functionName: 'getRemainingCooldownTime',
    args: [address],
  },
  {
    ...voidriteContract,
    functionName: 'balanceOf',
    args: [address, BigNumber.from(1)],
  }
],
watch:false,
onSuccess(data) {
    setStakedBalance((data[0]as BigNumber));
    setRemainingCooldownTime((data[1] as BigNumber))
    setVoidriteBalance((data[2] as BigNumber))
  },
})
*/
//const [Curr, setStakedBalance] = useState<any | BigNumber>(BigNumber.from(0));

/*
const addCustomNetwork = async () => {
  if (typeof window.ethereum !== 'undefined') {
      try {
          // Define network parameters
          const params = {
            chainId: '0x55d76c9', 
             chainName: 'Sage Towers',
            nativeCurrency: {
              name: 'Sage Stone',
              symbol: 'SGSTN',
              decimals: 18,
            },
            rpcUrls: ['https://chain.sagetowers.com/'],
            blockExplorerUrls: ['https://scan.sagetowers.com/'],
          };
  
          // Add Ethereum network
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [params],
          });
        } catch (error) {
          console.error(error);
        }
  } else {
      alert('Please install Wallet');
  }
};
*/


const { message, signature } = useSignInMessage();
//useSignIn({
 // onSuccess: ({ fid }) => console.log('Your fid:', fid),
//});
// now lets use useVerifySignInMessage
const { profile, isAuthenticated } = useFarcasterProfile();
const [verificationStatus, setVerificationStatus] = useState('');

const verifySignatureWithServer = async () => {
  if (!isAuthenticated) {
    alert('Please sign in first.');
    return;
  }

  try {
    // Assuming profile contains an address or an identifier to derive it
    // This could be the fid, username, or another unique identifier from which you can derive the address

    const userAddress = profile?.custody; // Hypothetical example, adjust based on actual data
    console.log(userAddress);
    const response = await fetch('https://api.sagetowers.com/verify-signature', {
      method: 'POST', 
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        message,
        signature,
        userAddress, // Send this to your backend for verification
      }),
    });

    const data = await response.json();
    console.log(JSON.stringify(data));
    if (data.success) {
      setVerificationStatus('Signature verified successfully!');
    } else {
      setVerificationStatus('Failed to verify signature.');
    }
  } catch (error) {
    console.error('Error verifying signature:', error);
    setVerificationStatus('Error during verification process.');
  }
};



  const {
    data: signMessageData,
    signMessage: signMessageFunction,
  } = useSignMessage();

  const [profileData, setProfileData] = useState<any>(null);

  //need an effect to check for is authenticated then verify signature only one time
  useEffect(() => {
    if (isAuthenticated) {
      verifySignatureWithServer();
    }
  }
  , [isAuthenticated]);
  

  useEffect(() => {
    if (signMessageData) {
      handleClaimAllTokens();
    }
  }, [signMessageData]);

 interface Token {
  tokenId: string;
  claimed: boolean;
  timeLeft?: number;
}

  useEffect(() => {
    if (address) {
      fetchProfileData(address);
    }
  }, [address]);

  const fetchProfileData = async (walletAddress: string) => {
    // Fetch user profile data
    const response = await fetch(`https://api.sagetowers.com/getprofile/${walletAddress}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });

    if (response.ok) {
      const profile = await response.json();
      setProfileData(profile);
    }
  };

  const handleClaimAllTokens = async () => {
    if (profileData) {
      const message = `Sign this message to claim tokens from your Voidrite NFTs :${profileData.walletAddress}`;
   
      if (!signMessageData) {
        await signMessageFunction({
          message,
        });
      } else {
        console.log('Signature:' + signMessageData);
        // ... rest of the code
      }
      try {
       // const claimResult = await claimAllTokens(profileData.walletAddress, signMessage);
        const response = await fetch(`https://api.sagetowers.com/claim/${profileData.walletAddress}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            signMessageData,
          }),
          credentials: 'include',
        });
      
        if (response.ok) {
          const dataz = await response.json();
          //const tokensClaimed = 20 * dataz.message;
          setShowClaimButton(false);
          toast.success(dataz.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          fetchProfileData(profileData.walletAddress);
        } else {
         
        }
        
    
      } catch (error) {
       
      }
    }
  };

  async function canClaim(walletAddress: string) {
    try {
      const response = await fetch(`https://api.sagetowers.com/canClaim/${walletAddress}`);
      const data = await response.json();
      return data.canClaim;
    } catch (error) {
      console.error('Error checking claim status:', error);
      return false;
    }
  }
  const [UserBalance, setUserBalance] = useState<any | string>("0");
 
  const fetchLLMBalance = async (walletAddress: `0x${string}` ) => {
    if(walletAddress === "0x0000000000000000000000000000000000000000"){
       // setUserBalance(0);
    }
    /*
const balance = getBalance(config, {
  address: '0x4557B18E779944BFE9d78A672452331C186a9f48',
})
    */
    const bal = await getBalance(config, {address: walletAddress,  token:"0x226B70c1Af7FeA262D252b5A16E55057bb1c79be"});  
   // setUserBalance(bal.formatted);
   setUserBalance(formatUnits(bal.value, bal.decimals));
console.log(formatUnits(bal.value, bal.decimals)); 
  //  console.log(bal.formatted);  
  };
  
  const [showClaimButton, setShowClaimButton] = useState(false);

useEffect(() => {
  if (address) {
    canClaim(address).then((result) => setShowClaimButton(result));
    fetchLLMBalance(address);
  }
}, [address]);

const _VoidriteBalance = VoidriteBalance ? VoidriteBalance.toNumber() : 0;
const _StakedBalance = StakedBalance ? StakedBalance.toNumber() : 0;  
const _RemainingCooldownTime = RemainingCooldownTime ? RemainingCooldownTime.toNumber() : 0;
//const num =  BigNumber.from(1);

  return (
    <Page>
      {address &&(
      <Box
        bgcolor="rgba(23, 23, 33, 0.85)"
        mt="1.5vh"
        sx={{
          boxShadow: "0px 0px  25px rgba(128, 226, 126, 0.5)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          gap: 2,
          width: "100%",
          padding: "1rem",
          borderRadius: "4px",
        }}
      >
         <Box
    >
     {profileData && (
  <>
   
{!profileData.discordId &&(
<Box sx={{mt:"0rem" , mb:"-0.5rem"}} >

        <DiscordLogin profileData={profileData} setProfileData={setProfileData} />
    
    <SignInButton />
       </Box>
)}
    <Typography sx={{ mb: 0}} variant="h1">
     Welcome { address.substring(0, 6) + "..." + address.substring(address.length - 4, address.length) }, 
  
    </Typography>
    <Typography variant="h4" sx={{ mb: "2vh" }}>
      {profileData.sageExperience ? profileData.sageExperience : 0} Sage XP
    </Typography>

    <Typography variant="h4" sx={{ mb: "2vh" }}>
      {profileData.sageRewards ? profileData.sageRewards : 0} Sage Points
    </Typography>

    <Typography variant="h4" sx={{ mb: "2vh" }}>
      {UserBalance ? UserBalance : 0} LLM
    </Typography>
     { !showClaimButton && StakedBalance < 1 && (
        <Button size="medium"
          variant="outlined"
          color="inherit"
          href="../sagestone" target="_self" sx={{ mb: 0.25 }}>
          Stake Sage Stone Points to earn rewards
        </Button>
     )}
        {showClaimButton && (
      <>
         <Button
          size="medium"
          variant="outlined"
          color="inherit"
          onClick={handleClaimAllTokens}
        >
          Claim Rewards
        </Button>
       
      </>
    )}
  </>
)}

       {!profileData && (
         <> <Typography variant="h1">Connect Accounts</Typography>
     <Typography  variant="subtitle2" sx={{ m: 0.5 }}>
           Unlock LLM Tokens, Sage Points, Discord Ranks by connecting your accounts.
          </Typography>     
        <Box sx={{ display: "flex",  gap: 1  ,  flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",}}>
       <Box sx={{mt:"1rem" , mb:"1rem", }} >
        <DiscordLogin profileData={profileData} setProfileData={setProfileData} />
        <SignInButton />
    
       </Box>
        </Box>
        <Box sx={{ display: "flex",  gap: 3  ,  flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",}}>
        <img src="media/icons/discord.svg" alt="Discord" height='40px' width='40px' />
        
        
        
        <img src="media/icons/sagechain.svg" alt="Discord" height='40px' width='40px' />
      </Box>
      
       </>
      )}
    
    
    </Box>
 

     </Box>
        )}
        

        {!address &&(
      <Box
        bgcolor="rgba(23, 23, 33, 0.85)"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          gap: 2,
          width: "100%",
          padding: "1rem",
          borderRadius: "4px",
        }}
      >
  <Typography variant="h1">Sage Profile</Typography>
  <Typography variant="body1">

  Connect your wallet to your Discord account to unlock LLM Tokens and Discord Ranks.
  
  </Typography>
  <Connect/>
         </Box>)}
        {address &&(
         <Box
         mt={"1rem"}
        bgcolor="rgba(23, 23, 33, 0.85)"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent:  'space-between',
          textAlign: "center",
          
          width: "100%",
          boxShadow: "0px 0px  25px rgba(128, 226, 126, 0.5)",
          padding: "1.0rem",
          borderRadius: "3px",
         
        }}
      >
        
          
         <Typography variant="h1" fontSize={"2.70rem"} pt={-1 } pb={2}>SAGE STONE POINT STAKING</Typography>
         <Typography variant="h4" sx={{ mb: "2vh" }}>
      More info coming soon!
    </Typography>
        
  </Box>
  )}
    </Page>
  );
};

export default ConnectPage;
