/*

Utility and Purpose
$WINE is the primary utility token used across the Chatover.wine platform. It serves several critical functions:
Minting NFTs: Users spend $WINE to create new NFTs, which are essentially unique, meme-inspired chatbots.
Exclusive Interactions: $WINE allows users to unlock special interactions and features with these chatbot NFTs.
Governance: Holders of $WINE can participate in decision-making processes that influence the development and policies of the platform. This includes voting on update and proposals that affect the service and functionality.

Economic Model
The economic model of $WINE ensures a fair and equitable distribution, with no presale or special allocations for insiders. This helps maintain a level playing field among all users. The generated revenue from token usage (like minting NFTs) is reinvested into sustaining the ecosystem, such as covering API costs for the underlying technology and funding further development.
*/

import React from 'react';
import { Typography, Paper, Button, Container } from '@mui/material';
import Page from 'components/Page';
import PageRedux from 'components/PageRedux';
const mainImage1= '/media/icon.png';
const mainImage2= '/media/bot4.png';
const mainImage3= '/media/bot6.png';
const backgroundImage = '/media/winebg.webp';

function Wine() {
  return (
    <Page>
       <Container maxWidth="md" sx={{ bgcolor: "rgba(223, 223, 233, 0.85)" }}>
        <Typography variant="h1" gutterBottom>
          $WINE- the meme token of Chatover.wine
        </Typography>
        <Typography variant="h2" gutterBottom>
          Launching on Pump.fun SOON
        </Typography>

        {/* Image 1 */}
        <img src={mainImage1} alt="Wine Token" style={{ width: '50%', height: 'auto', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}/>

        <Typography variant="h1" gutterBottom>
          Utility and Purpose:
        </Typography>
        <Typography paragraph>
          $WINE is the primary utility token used across the Chatover.wine platform. It serves several critical functions:
          </Typography>
          <Typography paragraph>
          Minting NFTs: Users spend $WINE to create new NFTs, which are essentially unique, meme-inspired chatbots.
          </Typography>
          <Typography paragraph>
          Interactions: $WINE along with the chatbots memecoin allows the user to interact with the AI services.
          </Typography>
          <Typography paragraph>
          Governance: Holders of $WINE can participate in decision-making processes that influence the development and policies of the platform. This includes voting on update and proposals that affect the service and functionality.
      
      
        </Typography>

       
        <Typography variant="h1" gutterBottom>
          Economic Model:
        </Typography>
        <Typography paragraph>
          By using pump.fun the economic model of $WINE ensures a fair and equitable distribution, with no presale or special allocations for insiders. This helps maintain a level playing field among all users. The generated revenue from token usage (like minting NFTs) is reinvested into sustaining the ecosystem, such as covering API costs for the underlying technology and funding further development.
        </Typography>
        <Typography variant="h1" gutterBottom>
          Staking:
        </Typography>
        <Typography paragraph>
         A staking system is in the works. When you put your Wine in the Wine Cellar you collect a portion of fees from WINE use in the ecosystem. </Typography>
          

     </Container>
    </Page>
  );
}

export default Wine;