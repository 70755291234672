import React, { ChangeEvent, FormEvent, useState, useEffect, useCallback } from 'react';
import { Container, Box, TextField, Button, Typography, CircularProgress, Modal, Card, styled } from '@mui/material';
import { useWriteContract, useWaitForTransactionReceipt, useReadContract, useAccount } from 'wagmi';
import Page from 'components/Page';
import axios from 'axios';
import { BigNumber, ethers } from 'ethers';
import ChatMintButton from './ChatMintButton';
import ApproveButton from './ApproveButton'; // Adjust the import path as necessary
import PageRedux from 'components/PageRedux';
import NFTGate from './NFTGate';


const CONTRACT_ADDRESS = '0x56e5776B59c3dfB42bA9B45733Cf618F6D128Ec5';
const CONTRACT_ABI = [
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "spender",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "value",
        "type": "uint256"
      }
    ],
    "name": "approve",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "owner",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "spender",
        "type": "address"
      }
    ],
    "name": "allowance",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
];

const CONTRACT_ADDRESS_NFT = '0xA8675eaacFCB964a81e360B857e06dbedc2F003d';
const CONTRACT_ABI_NFT = [
  {
    "inputs": [],
    "name": "mintPrice",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "string",
        "name": "name",
        "type": "string"
      },
      {
        "internalType": "string",
        "name": "description",
        "type": "string"
      },
      {
        "internalType": "string",
        "name": "image",
        "type": "string"
      },
      {
        "internalType": "string",
        "name": "coinTicker",
        "type": "string"
      },
      {
        "internalType": "string",
        "name": "coinName",
        "type": "string"
      },
      {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "mintNFT",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  }
];

const StyledCard = styled(Card)(({ theme }) => ({
  transition: "transform 0.3s ease-in-out",
  '&:hover': {
    transform: "scale(1.05)",
    boxShadow: "0px 0px 15px  rgba(192, 179, 163, 1)",
    backgroundColor: "rgba(0, 0, 0, 1)",
    filter: 'drop-shadow(0px 0px 15px rgba(165, 179, 163, 1))',
    '& img': {
      transition: 'filter 0.15s ease-in-out',
    },
  },
  minHeight: 390,
  minWidth: 280,
  transform: "scale(1.05)",
  boxShadow: "0px 0px 15px  rgba(192, 179, 163, 1)",
  backgroundColor: "rgba(0, 0, 0, 1)",
  filter: 'drop-shadow(0px 0px 15px rgba(165, 179, 163, 1))',
  '& img': {
    filter: 'drop-shadow(0px 0px 10px rgba(165, 179, 163,  0.9))',
    transition: 'filter 0.15s ease-in-out',
  },
}));

const NftMintingForm = () => {
  const [coinName, setCoinName] = useState('');
  const [nftName, setNftName] = useState('');
  const [image, setImage] = useState<File | null>(null);
  const [coinImage, setCoinImage] = useState<File | null>(null);
  const [coinTicker, setCoinTicker] = useState('');
  
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [coinImagePreview, setCoinImagePreview] = useState<string | null>(null);
  const [imageUrl, setImageUrl] = useState(''); 
  const [coinImageUrl, setCoinImageUrl] = useState(''); 
  const [amount, setAmount] = useState('0');
  const [finalAmount, setFinalAmount] = useState('0');
  const [approvalStatus, setApprovalStatus] = useState<string | null>(null);
  const [statusSeverity, setStatusSeverity] = useState<'info' | 'success' | 'error'>('info');
  const [open, setOpen] = useState(false);
  const [description, setDescription] = useState('');
  const [isMinting, setIsMinting] = useState<boolean>(false);
  const [tokenId, setTokenId] = useState<string | null>(null); 
  const [mintData, setMintData] = useState(null);

  //const { account: accountAddress } = useAccount();
  const [mintPrice, setMintPrice] = useState(BigNumber.from(0));
  const [allowance, setAllowance] = useState(BigNumber.from(0));
  const account = useAccount()
  let accountAddress = account.address;
  console.log(accountAddress);
  const { data: mintPriceData } = useReadContract({
    address: CONTRACT_ADDRESS_NFT,
    abi: CONTRACT_ABI_NFT,
    functionName: 'mintPrice',
  });

  const { data: allowanceDataz, refetch: refetchAllowance } = useReadContract({
    address: CONTRACT_ADDRESS,
    abi: CONTRACT_ABI,
    functionName: 'allowance',
    args: [accountAddress, CONTRACT_ADDRESS_NFT],
  });

  useEffect(() => {
    console.log("mintPriceData" + mintPriceData)
    if (mintPriceData) {
      setMintPrice(BigNumber.from(mintPriceData));
    }
  }, [mintPriceData]);

  useEffect(() => {
    console.log("allowanceDataz" + allowanceDataz + accountAddress);
    if (allowanceDataz) {
      setAllowance(BigNumber.from(allowanceDataz));
    }
    else{
      setAllowance(BigNumber.from(0));
    }
  }, [allowanceDataz]);

  useEffect(() => {
    if (accountAddress) {
        refetchAllowance();
          //setAllowance(BigNumber.from(allowanceDataz));
      // Optionally, you can add logic here if you need to do something when the account changes
    }
  }, [accountAddress]);

  useEffect(() => {
    // Convert amount to BigNumber and add to mintPrice
    if(amount !== ""){
    const amountWei = ethers.utils.parseUnits(amount, "ether");

    // Add the adjusted amount to the current mintPrice
    const updatedMintPrice = mintPrice.add(amountWei);
  
    // Optionally convert the updated mintPrice back to a string if necessary
    const finalAmountValue = updatedMintPrice.toString();
    
    // Update the finalAmount state
    setFinalAmount(finalAmountValue);
  
    // Logging the updated final amount for debugging
    console.log(`Updated Final Amount: ${finalAmountValue}`);
    }
  }, [amount, mintPrice]);

  const handleImageChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const selectedFile = event.target.files[0];
      setImage(selectedFile);
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(selectedFile);
      const url = await uploadImage(selectedFile);
      setImageUrl(url);
    }
  };

  const uploadImage = async (file: File): Promise<string> => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post('https://api.chatover.wine/api/upload-image', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data.uploadResult.url;
    } catch (error) {
      console.error('Error uploading image:', error);
      return "";
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleApprovalSuccess = () => {
    // Update the allowance state to reflect the approval
    setAllowance(mintPrice); // assuming the approval sets the allowance to the mint price
  };

  return (
    <PageRedux backgroundImage={'/media/bg/bot5.webp'}>
      <Container maxWidth="sm" sx={{ bgcolor: "rgba(223, 223, 233, 0.95)" }}>
      <NFTGate>
        <Typography variant="h1" sx={{ mb: 2 }}>Mint Chatbot and Token</Typography>
        <TextField fullWidth label="Chatbot Name" sx={{ mb: 2 }} value={nftName} onChange={e => setNftName(e.target.value)} />
        <TextField fullWidth label="Chatbot Description" sx={{ mb: 2 }} value={description} onChange={e => setDescription(e.target.value)} />
        <Box component="form" noValidate sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginTop: "1rem" }}>
          <Box sx={{ position: 'relative', overflow: 'hidden', borderRadius: 1, '& input': { position: 'absolute', opacity: 0, top: 0, right: 0, bottom: 0, left: 0, width: '100%', height: '100%', cursor: 'pointer' } }}>
            <Button variant="contained" component="label" htmlFor="image-upload">Upload Image</Button>
            <input id="image-upload" type="file" onChange={handleImageChange} />
          </Box>
          {imagePreview && <img src={imagePreview} alt="Image Preview" style={{ maxWidth: '100%', marginBottom: '10px' }} />}

    
          {coinImagePreview && <img src={coinImagePreview} alt="Coin Image Preview" style={{ maxWidth: '100%', marginBottom: '10px' }} />}
          <TextField fullWidth label="Coin Name" value={coinName} onChange={e => setCoinName(e.target.value)} />
          <TextField fullWidth label="Coin Ticker" value={coinTicker} onChange={e => setCoinTicker(e.target.value)} />
          <TextField fullWidth label="Dev Supply (Pay with Wine)" value={amount} onChange={e => setAmount(e.target.value)} />
          {allowance.gte(mintPrice) ? (
            <ChatMintButton
              nftName={nftName}
              description={description}
              imageUrl={imageUrl}
              coinTicker={coinTicker}
              coinName={coinName}
              amount={  Math.floor(Number(ethers.utils.formatUnits(finalAmount, 18))).toString() }
            />
          ) : (
            <ApproveButton onApprovalSuccess={handleApprovalSuccess} />
          )}

          <Typography variant="body1" sx={{ mt: 1 }}>
            Cost to deploy: { ethers.utils.formatEther(finalAmount) ? `${ethers.utils.formatEther(finalAmount)}` : 'Loading...'} $WINE
          </Typography>
        </Box>
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: { xs: '80%', sm: '80%', md: '40%', lg: '40%' }, bgcolor: "none", border: "none", boxShadow: 0, padding: 1 }}>
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
              <StyledCard>
                <Typography margin={"1rem"} id="modal-modal-title" variant="h1" fontSize="1.2rem" sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>{approvalStatus}</Typography>
                <Box margin={"1rem"} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <img src={imageUrl} alt="Status" style={{ width: '30%', height: '30%' }} />
                </Box>
                {statusSeverity === 'info' && (
                  <Box m={"1rem"} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <CircularProgress color="secondary" />
                  </Box>
                )}
                {statusSeverity === 'success' && (
                  <>
                    <Typography variant="body1" sx={{ mt: 1, color: 'green' }}>
                      Transaction successful! Your NFT has been minted.
                    </Typography>
                    {tokenId && (
                      <Typography variant="body1" sx={{ mt: 1, color: 'green' }}>
                        Token ID: {tokenId}
                      </Typography>
                    )}
                  </>
                )}
                {statusSeverity === 'error' && (
                  <Typography variant="body1" sx={{ mt: 1, color: 'red' }}>
                    {approvalStatus}
                  </Typography>
                )}
              </StyledCard>
            </Box>
          </Box>
        </Modal>
        </NFTGate>
      </Container>
    </PageRedux>
  );
};

export default NftMintingForm;
