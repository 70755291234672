import * as React from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import { createTheme, PaletteColor } from "@mui/material/styles";
import { LinkProps } from "@mui/material/Link";

declare module "@mui/material/styles" {
  interface Palette {
    upcoming: PaletteColor;
  }
  interface PaletteOptions {
    upcoming: PaletteColor;
  }
}

declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides {
    upcoming: true;
  }
}

const LinkBehavior = React.forwardRef<
  any,
  Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] }
>((props, ref) => {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />;
});

const { palette } = createTheme();

const theme = createTheme({
  palette: {
    success: {
      main: "#39FF14" // Neon Green for success.
    },
    error: {
      main: "#F44336" // No change needed here as it’s a standard color for errors.
    },
    upcoming: palette.augmentColor({
      color: {
        main: "#FFD700" // Bright Yellow for upcoming events.
      },
    }),
    primary: {
      main: "#A52A2A", // Auburn Red for primary elements.
      contrastText: "#FFFFFF", // Pure White for text on primary elements.
    },
    secondary: {
      main: "#808080", // Monochrome Grey for secondary elements.
      contrastText: "#36454F", // Charcoal Black for text on secondary elements.
    },
  },
  typography: {
    fontFamily: [
      "Cinzel",
      "Geometric",
      "Segoe UI",
      "Helvetica Neue",
      "Arial",
      "sans-serif",
      "Red Hat Text",
      "Anton"
    ].join(","),
  },
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          fontFamily: "Red Hat Text",
          alignContent: "right",
          alignitems: "right",
          fontSize: "0.7rem",
          color: "#FFD8C0", // Pale Flesh for menu text to warm up the area.
          boxShadow: "0px 0px  25px rgba(128, 226, 126, 0.5)",
          borderRadius: "2",
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },
  },
});

theme.typography.h1 = {
  fontFamily: "Anton",
  fontWeight: "bold",
  fontSize: "36px",
  color: "#A52A2A", // Auburn Red for main headings (H1).
};
theme.typography.h2 = {
  fontFamily: "Red Hat Text",
  fontWeight: "bold",
  fontSize: "24px",
  color: "#A52A2A", // Auburn Red for secondary headings (H2).
};
theme.typography.body1 = {
  fontFamily: "Red Hat Text",
  fontWeight: "regular",
  fontSize: "16px",
  color: "#36454F", // Charcoal Black for body text.
};

export default theme;