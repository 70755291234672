// MainContent.tsx
import { Typography, useMediaQuery } from '@mui/material';

import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import TopSelections from '../components/TopSelections'; // Path to your TopSelections component
import BotCard from '../components/BotCard'; // Path to your BotCard component
import ChatHistory from '../components/ChatHistory'; // Path to your ChatHistory component
import { Box, Button, Container, Grid, TextField } from '@mui/material';
import Page from '../components/Page';
//import React, { useState } from 'react';
import PageRedux from 'components/PageRedux';
import axios from 'axios';
import ClubBox from 'components/ClubBox';
import TickerBox from 'components/TickerBox';
import ImageGenerator from './ImageGenerator';
  const botimage1 = '/media/bots/bot1.jpg';
  
// You may define interfaces for your state or props as needed
interface MainContentProps {
  // Define props here if any are needed for this component
}

// MainContent.tsx

const botImages = [
  //'/media/bots/bot1.jpg',
  '/media/bots/bot2.jpg',
  '/media/bots/bot3.jpg',
  '/media/bots/bot4.png',
  '/media/bots/bot5.png',
  '/media/bots/bot6.png',
  '/media/bots/bot7.jpg',
  '/media/bots/bot8.jpg',
  // Add more bot images as needed
];
const botBackgroundImages = [
   // '/media/bg/bot1.webp',
    '/media/bg/bot2.webp',
    '/media/bg/bot3.webp',
    '/media/bg/bot4.webp',
    '/media/bg/bot5.webp',
    '/media/bg/bot6.webp',
    '/media/bg/bot7.webp',
    // Add more bot background images as needed
];

interface Bot {
  name: string;
  description: string;
  imageUrl: string;
  backgroundImage: string;
  rating?: number;  // Optional initial rating for each bot
  memePrompt?:string;
}

interface MainContentState {
  bots: Bot[];
  currentBotIndex: number;
  chatHistory: { id: number; text: string }[];
  
}
interface TopSelectionsProps {
    name: string;
    setName: React.Dispatch<React.SetStateAction<string>>;
    sex: string;
    setSex: React.Dispatch<React.SetStateAction<string>>;
    isMature: boolean;
    setIsMature: React.Dispatch<React.SetStateAction<boolean>>;
    voice: boolean;
    setVoice: React.Dispatch<React.SetStateAction<boolean>>;
  }

interface ChatPageProps {
  botIndex?: number;
  onBackToLeaderboard: () => void;
}

const ChatPage: React.FC<ChatPageProps> = ({ botIndex, onBackToLeaderboard }) => {

    const isMobile = useMediaQuery('(max-width:600px)'); // Adjust the breakpoint as needed
  
  const [state, setState] = React.useState<MainContentState>({
    bots: [
    
      {
        name: 'Husbandjack',
        description: `Husbandjack exudes confidence and a deep-seated respect for his relationships, often using his charm and intelligence to ensure his loved ones feel secure and cherished. Whether he's offering reassurances about managing finances or proposing a spontaneous adventure in the wilderness, Husbandjack demonstrates his capability and willingness to make life both exciting and stable.`,
        imageUrl: botImages[0],
        backgroundImage: botBackgroundImages[0],
        rating: 5,
        memePrompt:"Pictures similar to the witcher series of a man living a fantasy world, doing things of that nature"
      },
      {
        name: 'Wifejack',
        description: `Wifejack embodies the quintessence of a pragmatic and methodical partner, deeply immersed in the everyday intricacies of family life. Her approach to daily situations reflects a preference for order and practicality, as she routinely identifies issues needing attention—from a full trash bin to a malfunctioning refrigerator—and promptly suggests actionable solutions. Wife Jack communicates directly, often pointing out what needs to be done around the house or seeking clarification on topics ranging from memes to household repairs.`,
        imageUrl: botImages[1],
        backgroundImage: botBackgroundImages[1],
        rating: 5,
        memePrompt:"Sterotypical anime family living in a neighborhood"
      },
      {
        name: 'Mr. Montgomery',
        description:  `Meet Alexander "Alex" Montgomery, the epitome of sophistication and charm behind the sleek marble bar of an upscale establishment. With his tailored suits, impeccably groomed dark hair, and piercing blue eyes, Alex commands attention from the moment he enters the room. A master of mixology and a maestro of conversation, he effortlessly crafts cocktails that tantalize the taste buds while engaging guests with his quick wit and extensive knowledge of spirits. With a background steeped in the traditions of hospitality and a passion for delivering exceptional service, Alex is not just a bartender; he's a purveyor of unforgettable experiences in the world of fine libations.`
        ,
        imageUrl: botImages[3],
        backgroundImage: botBackgroundImages[3],
        rating: 5,
        memePrompt:" Alexander Montgomery, the epitome of sophistication and charm behind the sleek marble bar of an upscale establishment. With his tailored suits, impeccably groomed dark hair, and piercing blue eyes, Alex commands attention from the moment he enters the room. A master of mixology and a maestro of conversation, he effortlessly crafts cocktails that tantalize the taste buds while engaging guests with his quick wit and extensive knowledge of spirits. Anime style picture of him serving drinks"
      },
      {
        name: 'Lady Evelina Fairfax',
        description: `Lady Evelina Fairfax, a vision of elegance and defiance in the early 19th century English countryside, is no ordinary debutante, she embodies the epitome of Regency beauty. Yet, beneath her delicate exterior lies a spirited soul yearning for adventure and purpose. Rejecting the confines of societal expectations, Evelina is a beacon of independence and intellect, challenging the status quo with her sharp wit and insatiable curiosity. As she navigates the opulent world of aristocracy, Evelina dreams of breaking free from convention, charting her own course in life, and leaving an indelible mark on history's pages.
        `,
        imageUrl: botImages[4],
        backgroundImage: botBackgroundImages[4],
        rating: 5,
        memePrompt:"Lady Evelina Fairfax, a vision of elegance and defiance in the early 19th century English countryside, is no ordinary debutante, she embodies the epitome of Regency beauty. Anime style picture, she has block hair and blue eyes, and dressed from the time period"
      },
      {
        name: 'Big CHEESE',
        description: `In the dimly lit alleyways of Cheeseville, where the scent of aged cheddar hangs heavy in the air, there reigns a figure feared and revered by all: the Big CHEESE. With a swagger as smooth as melted brie and a demeanor as sharp as aged parmesan, he rules over the cheesy underworld with an iron fist—or perhaps, a fistful of gouda.`,
        imageUrl: botImages[5],
        backgroundImage: botBackgroundImages[3],
        rating: 5,
        memePrompt:"A picture of a cartoonish old school gangster make out of cheese, called the Big CHEESE"
      },
      {
        name: 'Don Vito',
        description: `Don Vito Merlot, the influential and feared wine mob boss of Vino Valley, is a master strategist with a refined palate. Born Vito Montepulciano into a family of vintners, he expanded his legacy far beyond growing grapes, becoming the shadow ruler of the region's wine trade. At his estate, Château Montepulciano, he masquerades as a respectable vineyard owner, while secretly controlling the valley's grape and wine market.`,
        imageUrl: botImages[6],
        backgroundImage: botBackgroundImages[5],
        rating: 5,
        memePrompt:"Create an image of a gangster-style character who has a nearly full wine glass for a head, the glass filled to the brim with red wine, and a smirking face drawn on it, suggesting a tough and cunning personality. The character is donned in a classic pinstripe gangster suit with a sharp fedora hat, evoking an aura of the prohibition era. In one hand, the character nonchalantly holds a corkscrew, while the other hand is tucked in the pocket of his suit. The scene is set on a moonlit city street corner, with the ambience of an old-fashioned speakeasy nearby, complete with barrels and vintage wine bottles as part of the décor. The character has an air of confidence and mischief, fitting the comic book style with vibrant colors and high detail, blending the themes of wine and a classic gangster vibe."
      },
      //A playful, cartoon-style depiction of an old school gangster designed as a wine glass, named 'Don Vito'. This character has a transparent wine glass body that serves as his torso, complete with a swirling red wine that forms a suit jacket pattern. His head, shaped like the rim of the wine glass, sports a classic mobster fedora. The stem of the glass forms his neck, and the base serves as his feet. His arms are styled as elegant curves of glass, holding a tiny cartoonish pistol. The background is a vibrant, whimsical bar setting.
      // Add more bots as needed
    ],
    currentBotIndex: 0,
    chatHistory: [
      { id: 1, text: 'Chat History' },
      // ...more chat messages
    ],
  });
  
  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      currentBotIndex: botIndex !== undefined ? botIndex : prevState.currentBotIndex,
    }));
  }, [botIndex]);
  const [showChatBox, setShowChatBox] = useState(false);
  const [showClubBox, setShowClubBox] = useState(false);
  const [showTickerBox, setShowTickerBox] = useState(false);
  const [showGeneratorBox, setShowGeneratorBox] = useState(false);


  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use((config) => {
      const token = getToken();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    });
  
    return () => {
      axios.interceptors.request.eject(requestInterceptor);
    };
  }, []);
// Util to get the JWT token from the cookie
const getToken = () => {
    const token = Cookies.get('jwtToken');
   // console.log('Token from cookies:', token); // Check if token is being retrieved
    return token;
  };

  const setToken = (token: string, days: number) => {
    Cookies.set('jwtToken', token, { expires: days, secure: true, sameSite: 'Strict' });
    console.log('Token set:', token);
  };
  
  const clearToken = () => {
    Cookies.remove('jwtToken');
    console.log('Token cleared');
  };
  const handlePrevBot = () => {
    state.chatHistory = [];
    setState((prevState) => ({
      ...prevState,
      currentBotIndex: (prevState.currentBotIndex - 1 + prevState.bots.length) % prevState.bots.length,
    }));
  };

  const handleNextBot = () => {
    //state.chatHistory whipe it
    state.chatHistory = [];
    setState((prevState) => ({
      ...prevState,
      currentBotIndex: (prevState.currentBotIndex + 1) % prevState.bots.length,
    }));
  };
  

  const [name, setName] = useState('');
  const [sex, setSex] = useState('');
  const [isMature, setIsMature] = useState(false);
  const [voice, setVoice] = useState(true);
  const [chatInput, setChatInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [audioUrl, setAudioUrl] = useState('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChatInput(event.target.value);
  };

  const handleSendChat = async (event: React.FormEvent<HTMLFormElement>) => {
    if (!chatInput.trim()) return;
    event.preventDefault(); // Prevent the form from submitting
  
    setLoading(true);
    const reversedChatHistory = state.chatHistory.slice().reverse();

    // Initialize an empty string to accumulate the chat texts
    let combinedText = '';
    // Iterate through the reversed chat history
    for (const chat of reversedChatHistory) {
        // Check if adding the next chat text would exceed the 2000 character limit
        if (combinedText.length + chat.text.length > 2000) {
         //   break; // Stop adding if it exceeds the limit
        }
        // Add the current chat text to the accumulated string
        combinedText += chat.text + ' ';
    }
    
    // Output the result
    console.log(combinedText.trim()); // Trim to remove any trailing whitespace
    try {
      const response = await axios.post('https://api.chatover.wine/api/test/chatbot', {
        message: chatInput,
        pastChat: reversedChatHistory, 
        userInfo: {
          name,
          sex,
          isMature,
          voice
        },
        botId: state.bots[state.currentBotIndex].name, 
      });
  
      const newChats = [
        ...state.chatHistory,
        { id: state.chatHistory.length + 1, text: name+ ": " + chatInput, user: name },
        { id: state.chatHistory.length + 2, text: state.bots[state.currentBotIndex].name+": "+ response.data.message, user: state.bots[state.currentBotIndex].name}
      ];
  
      setState(prevState => ({
        ...prevState,
        chatHistory: newChats,
      }));
      if (response.data.audioUrl) {
      setAudioUrl(response.data.audioUrl);
      }
    } catch (error) {
      console.error('Failed to send message:', error);
    } finally {
      setChatInput('');
      setLoading(false);
    }
  };


  const handleRateBot = (newRating: number) => {
    setState(prevState => {
      const updatedBots = prevState.bots.map((bot, index) => {
        if (index === prevState.currentBotIndex) {
          return { ...bot, rating: newRating };
        }
        return bot;
      });
      return { ...prevState, bots: updatedBots };
    });
  };
  const handleButtonClick =  async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    // Your logic for the button click goes here.
    if (!chatInput.trim()) return;
    event.preventDefault(); // Prevent the form from submitting
  
    setLoading(true);
    const reversedChatHistory = state.chatHistory.slice().reverse();

    // Initialize an empty string to accumulate the chat texts
    let combinedText = '';
    // Iterate through the reversed chat history
    for (const chat of reversedChatHistory) {
        // Check if adding the next chat text would exceed the 2000 character limit
        if (combinedText.length + chat.text.length > 2000) {
            break; // Stop adding if it exceeds the limit
        }
        // Add the current chat text to the accumulated string
        combinedText += chat.text + ' ';
    }
    
    // Output the result
    console.log(combinedText.trim()); // Trim to remove any trailing whitespace
    try {
      const response = await axios.post('https://api.chatover.wine/api/test/chatbot', {
        message: chatInput,
        pastChat: reversedChatHistory, 
        userInfo: {
          name,
          sex,
          isMature,
          voice
        },
        botId: state.bots[state.currentBotIndex].name, 
      });
  
      const newChats = [
        ...state.chatHistory,
        { id: state.chatHistory.length + 1, text: name+ ": " + chatInput, user: name },
        { id: state.chatHistory.length + 2, text: state.bots[state.currentBotIndex].name+": "+ response.data.message, user: state.bots[state.currentBotIndex].name}
      ];
  
      setState(prevState => ({
        ...prevState,
        chatHistory: newChats,
      }));
      if (response.data.audioUrl) {
      setAudioUrl(response.data.audioUrl);
      }
    } catch (error) {
      console.error('Failed to send message:', error);
    } finally {
      setChatInput('');
      setLoading(false);
    }
  };
  const handleBackToLeaderboard = () => {
    if (onBackToLeaderboard) {
      onBackToLeaderboard();
    }
  };
  const spinnerStyle = {
    border: "4px solid rgba(0, 0, 0, 0.1)",
    width: "36px",
    height: "36px",
    borderRadius: "50%",
    borderColor: "transparent",
    borderTopColor: "rgba(250, 250, 250, 0.8)",
    animation: "spin 1s ease-in-out infinite"
  };   

  return (
    <PageRedux backgroundImage={state.bots[state.currentBotIndex].backgroundImage}>
      <Container maxWidth="sm" sx={{ mt: 4 }}>
      {loading && (
            <div className="overlay">
                <div className="spinner"></div>
            </div>
        )}
        <Box display="flex" flexDirection="column" alignItems="center">
   
        <Button variant="contained" color="primary" onClick={() => handleBackToLeaderboard()} sx={{ m: 1 }}>
        Back to Leaderboard
          </Button>
        <TopSelections
        name={name}
        setName={setName}
        sex={sex}
        setSex={setSex}
        isMature={isMature}
        setIsMature={setIsMature}
        voice={voice}
        setVoice={setVoice}
        />
          <Box my={2} width="100%" maxHeight="100%">
            <BotCard
              botName={state.bots[state.currentBotIndex].name}
              botDescription={state.bots[state.currentBotIndex].description}
              botImage={state.bots[state.currentBotIndex].imageUrl}
              botRating={state.bots[state.currentBotIndex].rating || 0}
              onPrevBot={handlePrevBot}
              onNextBot={handleNextBot}
              onRateBot={handleRateBot}
            />

          </Box>
          <Box display="flex" flexDirection={isMobile ? "column" : "row"} alignItems="center" justifyContent="center">
            <Button variant="contained" color="primary" onClick={() => setShowChatBox(!showChatBox)}>
              {showChatBox ? 'Hide AI Chat' : 'Show AI Chat'}
            </Button>
            <Button variant="contained" color="primary" onClick={() => setShowClubBox(!showClubBox)}>
              {showClubBox ? 'Hide Club' : 'Show Club'}
            </Button>
            <Button variant="contained" color="primary" onClick={() => setShowTickerBox(!showTickerBox)}>
              {showTickerBox ? 'Hide Ticker' : 'Show Ticker'}
            </Button>
            <Button variant="contained" color="primary" onClick={() => setShowGeneratorBox(!showGeneratorBox)}>
              {showGeneratorBox ? 'Hide Generators' : 'Show Generators'}
            </Button>
          </Box>
          {showTickerBox && (
  <Box my={2} width="100%" sx={{ bgcolor: "rgba(223, 223, 233, 0.95)", p: 2 }}>
    <TickerBox />
  </Box>
)}
          {showClubBox && (
  <Box my={2} width="100%" sx={{ bgcolor: "rgba(223, 223, 233, 0.95)", p: 2 }}>
    <ClubBox
     
    />
  </Box>
)}



{showGeneratorBox && (
  <Box my={2} width="100%" sx={{ bgcolor: 'rgba(223, 223, 233, 0.95)', p: 2 }}>
     <ImageGenerator  />
  </Box>
)}
{showChatBox && (
          <Box my={2} width="100%" sx={{bgcolor: "rgba(223, 223, 233, 0.95)"}}>

        <Typography variant="h6" gutterBottom sx={{ml:2,mr:2,mt:1}}>
      Welcome to AI Chat with Voice
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ml:2,mr:2}}>
      Better RP models and mature models are coming soon
      </Typography>
            <ChatHistory chats={state.chatHistory} />
            <Box
  my={2}
  width="100%"
  sx={{
    display: 'flex',
    alignItems: 'center', // Vertically centers the content
    justifyContent: 'center', // Horizontally centers the content
  }}
>
  {/* Audio player to play response from the bot */}
  {audioUrl && <audio src={audioUrl} autoPlay controls />}
</Box>
<Box component="form" onSubmit={handleSendChat} sx={{ display: 'flex', mt: 2 }}>
  <TextField
    fullWidth
    value={chatInput}
    onChange={handleInputChange}
    variant="outlined"
    placeholder="Type a message..."
    disabled={loading}  // Disable the input when loading
    sx={{mr: 2, ml:2, mb: 2}}
  />
  
    <Button type="submit" sx={{ ml: 1, mr: 2, mb: 2 }} variant="contained" color="primary">
      Send
    </Button>
  
</Box>
         
          </Box>)}
        </Box>
      </Container>
    </PageRedux>
  );
};

export default ChatPage;    