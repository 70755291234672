import React, { createContext, useState, useEffect, ReactNode } from 'react';

interface Wallet {
  address: string;
  // Add other wallet properties as needed
}

interface WalletContextType {
  wallet: Wallet | null;
  connectWallet: (newWallet: Wallet) => void;
  disconnectWallet: () => void;
}

export const WalletContext = createContext<WalletContextType | undefined>(undefined);

interface WalletProviderProps {
  children: ReactNode;
}

export const WalletProvider: React.FC<WalletProviderProps> = ({ children }) => {
  const [wallet, setWallet] = useState<Wallet | null>(null);

  useEffect(() => {
    const savedWallet = localStorage.getItem('wallet');
    if (savedWallet) {
      setWallet(JSON.parse(savedWallet));
    }
  }, []);

  const connectWallet = (newWallet: Wallet) => {
    setWallet(newWallet);
    localStorage.setItem('wallet', JSON.stringify(newWallet));
  };

  const disconnectWallet = () => {
    setWallet(null);
    localStorage.removeItem('wallet');
  };

  return (
    <WalletContext.Provider value={{ wallet, connectWallet, disconnectWallet }}>
      {children}
    </WalletContext.Provider>
  );
};
