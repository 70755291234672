import React from 'react';
import { Button } from '@mui/material';
import { switchNetwork } from '@wagmi/core';
import { switchChain } from '@wagmi/core';
import { arbitrumNova } from 'viem/chains';
import { config } from 'config'
const SwitchNetworkButton = () => {
  const handleSwitchNetwork = async () => {
    try {
      const network = await switchChain(config, { chainId: arbitrumNova.id })
      // Handle the response here if needed
      console.log('Network switched:', network);
    } catch (error) {
      console.error('Failed to switch network:', error);
    }
  };

  return (
    <Button variant="contained" color="primary" onClick={handleSwitchNetwork}>
      Switch to Mainnet
    </Button>
  );
};

export default SwitchNetworkButton;
