
import Page from "components/Page";
import { Box, Button,Grid, Link, Typography } from "@mui/material";
import { formatUnits } from 'viem' 
import {
  useAccount,
  useAccountEffect,
  useConnect,
  useDisconnect,
 // useNetwork
} from 'wagmi'
//import Web3 from 'web3';
import { BigNumber } from 'ethers'

import Connect from "components/Connect";

import RadiantGiftMintCard from "components/RadiantGiftMintCard";

import InfoCard from "components/InfoCard";

import { EXPECTED_CHAIN_ID, config } from "config";

import { fetchBalance, getBalance } from 'wagmi/actions'
import { debounce } from "lodash";
import  { useEffect, useState } from "react";
import RadiantGiftGallery from "components/RadiantGiftGallery";
import ExtromonViewer from "components/ExtromonViewer";
import axios from "axios";
import ExtromonMintButton from "components/ExtromonMintButton";
const ExtromonMintPage = () => {
  const { address ,isConnected } = useAccount();
 // const { connectors, error, isLoading, pendingConnector } = useConnect();
  //const { disconnect } = useDisconnect();
  //const connect = useConnect({
    //onSuccess(data) {
     // console.log('Connect', data)
   // },
 // })
  //const radiantGiftContract = {
   // address: '0xD2fF6D8c9989DFaD80a52393D72A4EDC51Ac1AB2', // Replace with your contract address
   // abi: RadiantGiftABI,
  //}
  
  function generateRandomString(length : number) {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  /*
  const account = useAccount({
    onConnect({ address, connector, isReconnected }) {
      console.log('Connected', { address, connector, isReconnected })
      let tmpaddress = address ? address : '0x3ab85c1ED41A9f8275f7a446DaF5D7426e8eC839';
      const bal = getBalance(config,{address: tmpaddress});
      console.log(bal);
    },
    onDisconnect() {
      console.log('Disconnected')
    },
  })*/

  interface ExtromonAttributes {
    [key: string]: number | string;
  }
  
  interface ExtromonData {
      name: string;
    title: string;
    description: string;
    image: string;
    collectionName: string;
    series: string;
    rarity: string;
    element: string;
    personality: string;
    alignment: string;
    attributes: ExtromonAttributes;
    website: string;
    externalUrl: string;
    license: string;
    aiGenerated: string;
    aiModel: string;
    roleplayPrompt: string;
  }
  const account =  useAccountEffect({ 
    onConnect({ address, connector, isReconnected }) {
     // console.log('connected', data)
      console.log('Connected', { address, connector, isReconnected })
      let tmpaddress = address ? address : '0x3ab85c1ED41A9f8275f7a446DaF5D7426e8eC839';
      const bal = getBalance(config,{address: tmpaddress});
      //.log(bal);
    },
    onDisconnect() {
      console.log('disconnected')
    },
  })
  const { chain } = useAccount() ;

  const [MintPrice, setMintPrice] = useState<any | BigNumber>(0.0025);

  const [CurrentSupply, setCurrentSupply] = useState<any | Number>(0);
  const [CurrentAddress, setCurrentAddress] = useState<any | string>("0x3ab85c1ED41A9f8275f7a446DaF5D7426e8eC839");
useEffect(() => {
  if (address) { 
    const interval = setInterval(() => {
     if (address) {
       
         fetchLLMBalance(address);
        }
    }, 12000); 
  }
  }, []);
const [UserBalance, setUserBalance] = useState<any | string>("0");

useEffect(() => {
  try{
  if (address) {
    setCurrentAddress(address);
      console.log("Chain here:"+ chain?.id)
      if(chain?.id ===  EXPECTED_CHAIN_ID) {
        SetIsCorrectNetwork(true);   
       fetchLLMBalance(address);
      } else {
       if(isConnected){
      // addCustomNetwork();
      SetIsCorrectNetwork(true);   
      fetchLLMBalance(address);
       }  
      }
    }
  }
  catch(err) {}
  }, [address, chain?.id, debounce]);
 
  const fetchLLMBalance = async (walletAddress: `0x${string}` ) => {
    const bal = await getBalance(config,{address: walletAddress, token:"0x226B70c1Af7FeA262D252b5A16E55057bb1c79be"});  
    //formatUnits(result.value, result.decimals) 
    setUserBalance(formatUnits(bal.value, bal.decimals));
    console.log("LLM BAL:"+formatUnits(bal.value, bal.decimals));  
  };
  const [currentPlane, setCurrentPlane] = useState<number>(0);
  const randomExtromonID = Math.floor(Math.random() * 15).toString();
  useEffect(() => {
    const fetchExtromonData = async (tokenID: string) => {
      try {
        // Assuming setLoading is a state setter function from useState
       // setLoading(true); // Update your loading state as necessary
    
        const url = `https://metadata.sagetowers.com/emon/${tokenID}`;
        const response = await axios.get(url); // axios automatically infers response type as any
        const data = response.data;
        console.log("Data: "+data);
    
        const transformedData: ExtromonData = {
          name: data.name,
          title: data.name,
          description: data.description,
          image: `https://sage-towers-one.quicknode-ipfs.com/ipfs/${data.image.split('//')[1]}`,
          collectionName: data.collection_name,
          series: data.series,
          rarity: data.rarity,
          element: data.element,
          personality: data.personality,
          alignment: data.alignment,
          attributes: data.attributes.reduce((acc: ExtromonAttributes, attribute: { trait_type: string; value: number | string }) => {
            acc[attribute.trait_type] = attribute.value;
            return acc;
          }, {}),
          website: data.website,
          externalUrl: data.external_url,
          license: data.license,
          aiGenerated: data.ai_generated ? 'Yes' : 'No',
          aiModel: data.ai_model,
          roleplayPrompt: data.roleplayprompt,
        };
    
        // Assuming setExtromonData is a state setter function from useState
        setExtromonData(transformedData);
      } catch (error) {
        console.error("Error fetching Extromon data:", error);
      } finally {
       // setLoading(false); // Update your loading state as necessary
      }
    };
  
    // Initial fetch
    //get random extromon by using a random number fomr 0 to 15
    const randomExtromonID = Math.floor(Math.random() * 15).toString();
    fetchExtromonData(randomExtromonID);
  
  
      // Set up the interval to fetch new data
      //const intervalId = setInterval(() => {
      //  const nextPlaneIndex = (currentPlane + 1) % planeTypes.length; // Cycle through the plane types
       // setCurrentPlane(nextPlaneIndex);
     //   fetchExtromonData(planeTypes[nextPlaneIndex]);
    //  }, 100000); // Change the URL every 10 seconds
  
      // Clear the interval when the component unmounts
     // return () => clearInterval(intervalId);
  
  
  }, [currentPlane]);
  
 // fetchExtromonData(randomExtromonID);

const [IsCorrectNetwork, SetIsCorrectNetwork] = useState<boolean>(false);
const [extromonData, setExtromonData] = useState<ExtromonData >();
const addCustomNetwork = async () => {
  if (typeof window.ethereum !== 'undefined') {
      try {
          const params = {
            chainId: '0x55d76c9', 
             chainName: 'Sage Towers',
            nativeCurrency: {
              name: 'Sage Stone',
              symbol: 'SGSTN',
              decimals: 18,
            },
            rpcUrls: ['https://chain.sagetowers.com/'],
            blockExplorerUrls: ['https://scan.sagetowers.com/'],
          };
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [params],
          });
        } catch (error) {
          console.error(error);
        }
  } else {
      alert('Please install Wallet');
  }
};
const goToNextExtromon =async () => {

  //get random extromon by using a random number fomr 0 to 15
  const randomExtromonID = Math.floor(Math.random() * 35).toString();
  const url = `https://metadata.sagetowers.com/emon/${randomExtromonID}`;
    const response = await axios.get(url); // axios automatically infers response type as any
    const data = response.data;
    console.log(data);

    const transformedData: ExtromonData = {
      name: data.name,
      title: data.name,
      description: data.description,
      image: `https://sage-towers-one.quicknode-ipfs.com/ipfs/${data.image.split('//')[1]}`,
      collectionName: data.collection_name,
      series: data.series,
      rarity: data.rarity,
      element: data.element,
      personality: data.personality,
      alignment: data.alignment,
      attributes: data.attributes.reduce((acc: ExtromonAttributes, attribute: { trait_type: string; value: number | string }) => {
        acc[attribute.trait_type] = attribute.value;
        return acc;
      }, {}),
      website: data.website,
      externalUrl: data.external_url,
      license: data.license,
      aiGenerated: data.ai_generated ? 'Yes' : 'No',
      aiModel: data.ai_model,
      roleplayPrompt: data.roleplayprompt,
    };

    // Assuming setExtromonData is a state setter function from useState
    setExtromonData(transformedData);
};


const rng = generateRandomString(10);
const CurrentSupplyString = CurrentSupply? CurrentSupply.toString() : "0";
const numUserBalance = parseFloat(UserBalance);
  return (
    <Page>
      {!address &&(
      <Box
        bgcolor="rgba(23, 23, 33, 0.85)"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          gap: 1.5,
          width: "100%",
          padding: "0.5rem",
          borderRadius: "3px",
        }}
      >
    
    <Typography variant="h1" fontSize={"3.75rem"}>Extromon: Gen 1</Typography> 
      <>  
 
        <Typography variant="h3" fontSize={"1.0rem"}>Embark on a Journey with Your Virtual Pet</Typography>
        <Connect/>
        <Typography variant="h2" fontSize={"1.30rem"} pt={2} pb={1}>Interact, Grow, and Explore in the Extromon Universe</Typography>
        <Typography pt={"3.5vh"} variant="h3" fontSize={"1.0rem"}>Token Standard: ERC721</Typography>
        <Typography variant="h3" fontSize={"1.0rem"}>Blockchain: Arbitrum Nova</Typography>
        <Typography variant="h3" fontSize={"1.0rem"}>Total Supply: 9,001</Typography>
        <Typography variant="h3" fontSize={"1.0rem"}>Generative Agents: LLM Based</Typography>
        <Typography variant="h3" fontSize={"1.0rem"}>Gameplay: Battle, Debate, Adventure, Quest</Typography>
        <Typography variant="h3" fontSize={"1.0rem"}>Rarity Tiers: Common to Legendary</Typography>
        <Typography variant="h3" fontSize={"1.0rem"}>Hunger Mechanics & Stat Growth</Typography>
        <Typography variant="h3" fontSize={"1.0rem"}>"Prompt to Earn" Model</Typography>
        <Typography variant="h3" fontSize={"1.0rem"}>Dynamic World Evolution: GPT Tick System</Typography>
        <Typography variant="body1" fontSize={"1.1rem"}>Engage and grow with your Extromon, contributing to the AGI evolution.</Typography>
      </> 
  
         </Box>
   )}

        {address &&(
      <Box
        bgcolor="rgba(23, 23, 33, 0.85)"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent:  'space-between',
          textAlign: "center",
          
          width: "100%",
          
          padding: "0.25rem",
          borderRadius: "6px",
         
        }}
      >
  <Grid container mt={"0.1vh"}  style={{width: '100%'}} display="flex" justifyContent="center" alignItems="center" spacing={4}>
 
</Grid>  
<div>
  
{numUserBalance >= 1000000000 && (
            <Typography  mt={2} mb={2} variant="h1" fontSize="1.40rem">
              
            </Typography>)}

      
        </div>
        <Typography variant="h1" fontSize={"3.75rem"}>Extromon</Typography> 
      <>  
  
        <Typography variant="h3"  mt={2} mb={3} fontSize={"1.0rem"}>Embark on a Journey with Your Virtual Pet</Typography>
       <ExtromonMintButton 
          isConnected={isConnected}
          mintPrice={MintPrice}
          UserAddy={address}
          randomString={"WE ARE RETARDS"}
        />
        <Typography variant="h2" fontSize={"1.30rem"} pt={2} pb={1}>Interact, Grow, and Explore in the Sage Towers Universe</Typography>
        
        <Typography pt={"3.5vh"} variant="h3" fontSize={"1.0rem"}>Token Standard: ERC721</Typography>
        <Typography variant="h3" fontSize={"1.0rem"}>Blockchain: Arbitrum Nova</Typography>
        <Typography variant="h3" fontSize={"1.0rem"}>Total Supply: 9,001</Typography>
        <Typography variant="h3" fontSize={"1.0rem"}>Generative Agents: LLM Based</Typography>
        <Typography variant="h3" fontSize={"1.0rem"}>Gameplay: Battle, Debate, Adventure, Quest</Typography>
        <Typography variant="h3" fontSize={"1.0rem"}>Rarity Tiers: Common to Legendary</Typography>
        <Typography variant="h3" fontSize={"1.0rem"}>Hunger Mechanics & Stat Growth</Typography>
        <Typography variant="h3" fontSize={"1.0rem"}>Prompt to Earn Model</Typography>
        <Typography variant="h3" fontSize={"1.0rem"}>Dynamic World Evolution: GPT Tick System</Typography>
        <Typography variant="body1"   mb={2} fontSize={"1.1rem"}>Engage and grow with your Extromon, contributing to the AGI birth.</Typography>
      </> 
 
      </Box>)}
      <Box
       sx={{
        mt: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          gap: 1.5,
          width: "100%",
          height: "100%",
          padding: "0.5rem",
          borderRadius: "3px",
        }}
      >
        <Box
       sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          gap: 1.5,
          width: "100%",
          height: "100%",
          //padding: "0.5rem",
          borderRadius: "3px",
          //bgcolor: "rgba(22,22,32,0.5)",
      boxShadow: "0px 0px 15px rgba(128, 226, 126, 0.9)", 
      backgroundColor: "rgba(23, 23, 33, 1)",
      filter: 'drop-shadow(0px 0px 15px rgba(128, 226, 126, 0.9))',
padding: 2,
        }}
      >

<Typography variant="h1" pt={0.25} pb={1}>Previously Generated:</Typography>
<Box sx={{flexDirection: "column",
        justifyContent: "center",
        alignItems: "center", maxWidth:"75vw"}}>
             {extromonData && (
      <ExtromonViewer content={extromonData} goToNextContent={goToNextExtromon} />
    )}</Box>
        <InfoCard
       title="A New Era of Interactive NFTs"
       text="Join the Extromons Universe in the Sage Towers metaverse, featuring 9,001 Virtual Pet NFTs. Engage with your Extromon in Discord to earn cryptocurrency rewards, contributing to the development of AGI through the 'prompt to earn' model."
         imageUrl="media/extromon2.png"
   />

<InfoCard
         title="Shape Reality with Conversations"
         text="The Extromons world evolves with your interaction. Through the 'GPT tick' system, engage in meaningful dialogues with your Extromon, influencing their actions and the metaverse at large, fostering a living ecosystem that grows with every conversation."
       imageUrl="media/extromon1.png"
   />
      <InfoCard
   title="The Emergence of Extromons"
   text="In the aftermath of the Void Cataclysm, the city of Ardor witnessed the birth of Extromons, creatures born from the chaotic merger of elemental energies and creatures. These beings, emerging from the elemental dimensions, seek partners to master their newfound abilities."imageUrl="media/extromon4.png"
   />

<InfoCard
   title="Elemental Bonds and the Whisperwind Codex"
   text="Humans of Ardor, resonating with the elemental essence of Extromons, became Elemental Partners. Through the guidance of the ancient Whisperwind Codex, they learned to communicate and nurture the growth of their Extromons, harnessing their abilities for the city's rebirth."
     imageUrl="media/extromon3.png"
   />


          
       </Box>
       </Box>
    </Page>
  );
};

export default ExtromonMintPage;


