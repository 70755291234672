import React, { ReactNode, useEffect, useState } from 'react';
import { useAccount, useConnect, useReadContract } from 'wagmi';
import { BigNumber, ethers } from 'ethers';

// Replace with your ERC1155 contract address and ABI
const ERC1155_CONTRACT_ADDRESS = '0xc27DeA0F4873A474F6592Ea71b9C4a41319bEB27';
const ERC1155_CONTRACT_ABI = [
    // The ABI entry for the balanceOf function
    {
      "constant": true,
      "inputs": [
        {
          "name": "account",
          "type": "address"
        },
        {
          "name": "id",
          "type": "uint256"
        }
      ],
      "name": "balanceOf",
      "outputs": [
        {
          "name": "",
          "type": "uint256"
        }
      ],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
    },
    // The ABI entry for the uri function
    {
      "constant": true,
      "inputs": [
        {
          "name": "id",
          "type": "uint256"
        }
      ],
      "name": "uri",
      "outputs": [
        {
          "name": "",
          "type": "string"
        }
      ],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
    }
  ];

const REQUIRED_TOKEN_ID = 1; // Replace with the ID of the required ERC1155 token
interface URI {

    botBackgroundImage: string;
    botImage: string;
    botName: string;
    botDescription: string;
   
  } 
interface ContentLockProps {
    children: ReactNode;
  }
  
  const NFTGate: React.FC<ContentLockProps> = ({ children }) => {
  const { address } = useAccount();
  const [hasAccess, setHasAccess] = useState(false);
    const [nftMetadata, setNftMetadata] = useState<any>(null);
    const [state, setState] = React.useState<URI>({
        botBackgroundImage: '',
        botImage: '',
        botName: '',
        botDescription: '',
      });

  const { data: nftBalanceData, refetch: refetchNftBalance } = useReadContract({
    address: ERC1155_CONTRACT_ADDRESS,
    abi: ERC1155_CONTRACT_ABI,
    functionName: 'balanceOf',
    args: [address, REQUIRED_TOKEN_ID],
  });
  const { data: nftBalanceData2, refetch: refetchNftBalance2 } = useReadContract({
    address: ERC1155_CONTRACT_ADDRESS,
    abi: ERC1155_CONTRACT_ABI,
    functionName: 'balanceOf',
    args: [address, 2],
  });
  const { data: tokenUri, refetch: refetchURI } = useReadContract({
    address: ERC1155_CONTRACT_ADDRESS,
    abi: ERC1155_CONTRACT_ABI,
    functionName: 'uri',
    args: [address, REQUIRED_TOKEN_ID],
  });
  useEffect(() => {
    if (address) {
      refetchNftBalance();
      refetchNftBalance2();
    }
  }, [address, refetchNftBalance, refetchNftBalance2, refetchURI]);

  useEffect(() => {
    
        if (tokenUri) {
            console.log("Token URI: " + tokenUri);
            const jsonPart = tokenUri.toString().split(',')[1]; // Get the Base64 encoded JSON
            if (jsonPart) {
              const decodedJson = atob(jsonPart); // Decode Base64
              const parsedMetadata = JSON.parse(decodedJson); // Parse the JSON
              setState(parsedMetadata); // Set the parsed metadata to state
              state.botName=parsedMetadata.name;
              state.botDescription=parsedMetadata.description;
              state.botImage=parsedMetadata.image;
            }
          }
    
  }, [tokenUri]);

  useEffect(() => {
    if (address) {
      refetchNftBalance();
    }
  }, [address, refetchNftBalance]);

  useEffect(() => {
    if (nftBalanceData) {
      const balance = BigNumber.from(nftBalanceData);
      if(!hasAccess){
      setHasAccess(balance.gt(0));
    }
    }
  }, [hasAccess, nftBalanceData]);
  useEffect(() => {
    if (nftBalanceData2) {
      const balance = BigNumber.from(nftBalanceData2);
      if(!hasAccess){
      setHasAccess(balance.gt(0));
      }
    }
  }, [hasAccess, nftBalanceData2]);

  if (!address) {
    return <div>Please connect your wallet to access this content.</div>;
  }

  if (!hasAccess) {
    return <div>You do not have the required NFT for early access.</div>;
  }

  return <>{children}</>;
};

export default NFTGate;
