  import React from 'react';
  import {
    Container,
    Box,
    Typography,
    Button,
  } from '@mui/material';
  import { Link as RouterLink } from 'react-router-dom';
  import Marquee from 'react-marquee-slider';

  import Header from './Header';
  import VideoBackground from '../utils/VideoBackground';
  import Footer from './Footer';
  import { Height } from '@mui/icons-material';
import HeaderSolana from './HeaderSolana';
  const backgroundImages = [
   // '/media/bg/bot1.webp',
   // '/media/bg/bot2.webp', // Example path, replace with actual path
    //'/media/winebridge2.jpg',  // Example path, replace with actual path      backgroundImage: `url(${backgroundImage})`, // Set the background image
   // '/media/winebridge.jpg' ,
    '/media/winebridge2.jpg'
  ];
  
  interface Props {
    children: React.ReactNode;
  }
  
  const Page = ({ children }: Props) => {
    // Function to pick a random background image
    const getRandomBackgroundImage = () => {
      const index = Math.floor(Math.random() * backgroundImages.length);
      return backgroundImages[index];
    };
  
    const backgroundImage = getRandomBackgroundImage();



  


    return (
      <Box
      sx={{
        bgcolor: 'black',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover', // Cover the entire space of the Box

        backgroundPosition: 'center',
        backgroundAttachment: 'fixed', // Add this line
        minHeight: '100vh', // Set a minimum height to ensure the background covers the entire viewport
    
        
      }}
    >
        
          <HeaderSolana />
  
          <main>
            <Container  sx={{ padding: 2, minHeight:"88vh",}}>{children}</Container>
          </main>
          <footer>
          
          
        </footer>
      
        
      </Box>
    );
  };

  export default Page;
