import * as React from 'react';
import { useEffect, useState } from 'react';
import { useAccount, useWaitForTransactionReceipt, useWriteContract } from 'wagmi';
import {
  Box,
  Typography,
  Modal,
  Button,
  CircularProgress,
  Card,
  styled
} from '@mui/material';

const CONTRACT_ADDRESS = '0x56e5776B59c3dfB42bA9B45733Cf618F6D128Ec5';
const CONTRACT_ADDRESS_NFT = '0xA8675eaacFCB964a81e360B857e06dbedc2F003d';
const APPROVAL_AMOUNT = '100000000000000000000000'; // The amount to approve (in wei)

const CONTRACT_ABI = [
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "spender",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "approve",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
];
interface ApproveButtonProps {
    onApprovalSuccess: () => void;
  }
  
const StyledCard = styled(Card)(({ theme }) => ({
  transition: "transform 0.3s ease-in-out",
  '&:hover': {
    transform: "scale(1.05)",
    boxShadow: "0px 0px 15px rgba(192, 179, 163, 1)",
    backgroundColor: "rgba(0, 0, 0, 1)",
    filter: 'drop-shadow(0px 0px 15px rgba(165, 179, 163, 1))',
    '& img': {
      transition: 'filter 0.15s ease-in-out',
    },
  },
  minHeight: 390,
  minWidth: 280,
  transform: "scale(1.05)",
  boxShadow: "0px 0px 15px rgba(192, 179, 163, 1)",
  backgroundColor: "rgba(0, 0, 0, 1)",
  filter: 'drop-shadow(0px 0px 15px rgba(165, 179, 163, 1))',
  '& img': {
    filter: 'drop-shadow(0px 0px 10px rgba(165, 179, 163,  0.9))',
    transition: 'filter 0.15s ease-in-out',
  },
}));

const ApproveButton: React.FC<ApproveButtonProps> = ({ onApprovalSuccess }) => {
  const [open, setOpen] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const [statusSeverity, setStatusSeverity] = useState<'info' | 'success' | 'error'>('info');

  const { data: hash, error, isPending, writeContract } = useWriteContract();
  const { isLoading: isConfirming, isSuccess: isConfirmed, data: receiptData } = useWaitForTransactionReceipt({ hash });
  const account = useAccount();
  const handleClick = () => {
    setStatusMessage('Transaction initiated...');
    setStatusSeverity('info');
    setOpen(true);

    writeContract({
      address: CONTRACT_ADDRESS,
      abi: CONTRACT_ABI,
      functionName: 'approve',
      args: [CONTRACT_ADDRESS_NFT, APPROVAL_AMOUNT],
    });
  };

  useEffect(() => {
    if (isPending) {
      setStatusMessage('Transaction is pending...');
      setStatusSeverity('info');
      setOpen(true);
    } else if (isConfirming) {
      setStatusMessage('Transaction is confirming...');
      setStatusSeverity('info');
      setOpen(true);
    } else if (isConfirmed) {
      setStatusMessage('Transaction confirmed!');
      setStatusSeverity('success');
      console.log("Did it");
      onApprovalSuccess(); 
      setOpen(true);
    } else if (error) {
      setStatusMessage(`Error: ${error.message}`);
      setStatusSeverity('error');
      setOpen(true);
    }
  }, [isPending, isConfirming, isConfirmed, error]);

  useEffect(() => {
    if (isConfirmed && receiptData) {
      console.log("Transaction successful with receipt:", receiptData);
      setTimeout(() => {
        setOpen(false);
      }, 2500);
    }
  }, [isConfirmed, receiptData]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        disabled={isPending || isConfirming}
        onClick={handleClick}
        endIcon={isPending || isConfirming ? <CircularProgress size={20} /> : null}
        sx={{ margin: "0.5rem" }}
      >
        {isPending ? 'Confirming...' : 'Approve Tokens'}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: '80%', sm: '80%', md: '40%', lg: '40%' },
            bgcolor: "none",
            border: "none",
            boxShadow: 0,
            padding: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <StyledCard>
              <Typography
                margin={"1rem"}
                id="modal-modal-title"
                variant="h1"
                fontSize="1.2rem"
                sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
              >
                {statusMessage}
              </Typography>
              <Box margin={"1rem"} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <img src="./media/icon.png" alt="Status" style={{ width: '30%', height: '30%' }} />
              </Box>
              <Box m={"1rem"} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <CircularProgress color="secondary" />
              </Box>
            </StyledCard>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ApproveButton;
