import * as React from 'react';
import { useEffect, useState } from 'react';
import { useAccount, useReadContract, useWaitForTransactionReceipt, useWriteContract } from 'wagmi';
import {
  Box,
  Typography,
  Modal,
  Button,
  CircularProgress,
  Card,
  styled,
  TextField
} from '@mui/material';
import { BigNumber, ethers } from 'ethers';
import { parseEther } from 'ethers/lib/utils'; 
import { useNavigate } from 'react-router-dom';

import ApproveStakeButton from './ApproveStakeButton';
import PageRedux from 'components/PageRedux';
import AddTokenButton from 'components/AddTokenButton';
import AddXTokenButton from 'components/AddXTokenButton';

const CONTRACT_ADDRESS_STAKING = '0xa69B4933138EBCCa895963Dd05E24D47cb012eF4';
const CONTRACT_ABI_STAKING = [
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_amount",
        "type": "uint256"
      }
    ],
    "name": "enter",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_share",
        "type": "uint256"
      }
    ],
    "name": "leave",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "account",
        "type": "address"
      }
    ],
    "name": "balanceOf",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getAddressCurrentValue",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },{
    "constant": true,
    "inputs": [],
    "name": "totalSupply",
    "outputs": [{"name": "", "type": "uint256"}],
    "payable": false,
    "stateMutability": "view",
    "type": "function"
}
];

const CONTRACT_ADDRESS_TOKEN = '0x56e5776B59c3dfB42bA9B45733Cf618F6D128Ec5';
const CONTRACT_ABI_TOKEN = [
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "spender",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "value",
        "type": "uint256"
      }
    ],
    "name": "approve",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "owner",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "spender",
        "type": "address"
      }
    ],
    "name": "allowance",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "account",
        "type": "address"
      }
    ],
    "name": "balanceOf",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getAddressCurrentValue",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },{
    "inputs": [
      {
        "internalType": "address",
        "name": "account",
        "type": "address"
      }
    ],
    "name": "balanceOf",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
];
  
const StyledCard = styled(Card)(({ theme }) => ({
  transition: "transform 0.3s ease-in-out",
  '&:hover': {
    transform: "scale(1.05)",
    boxShadow: "0px 0px 15px rgba(192, 179, 163, 1)",
    backgroundColor: "rgba(0, 0, 0, 1)",
    filter: 'drop-shadow(0px 0px 15px rgba(165, 179, 163, 1))',
    '& img': {
      transition: 'filter 0.15s ease-in-out',
    },
  },
  minHeight: 390,
  minWidth: 280,
  transform: "scale(1.05)",
  boxShadow: "0px 0px 15px rgba(192, 179, 163, 1)",
  backgroundColor: "rgba(0, 0, 0, 1)",
  filter: 'drop-shadow(0px 0px 15px rgba(165, 179, 163, 1))',
  '& img': { 
    filter: 'drop-shadow(0px 0px 10px rgba(165, 179, 163,  0.9))',
    transition: 'filter 0.15s ease-in-out',
  },
}));

const ContractInteractionButton: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [depositAmount, setDepositAmount] = useState('');
  const [withdrawAmount, setWithdrawAmount] = useState('');
  const [stakedAmount, setStakedAmount] = useState(BigNumber.from(0));
  const [wineAmount, setWineAmount] = useState(BigNumber.from(0));
  const [totalXWine, setTotalXWine] = useState(BigNumber.from(0));
  const [withdrawalValue, setWithdrawalValue] = useState(BigNumber.from(0));
  const [statusMessage, setStatusMessage] = useState('');
  const [statusSeverity, setStatusSeverity] = useState<'info' | 'success' | 'error'>('info');
  const [errorMessage, setErrorMessage] = useState('');
  const { data: hash, error, isPending, writeContract } = useWriteContract();
  const { isLoading: isConfirming, isSuccess: isConfirmed, data: receiptData } = useWaitForTransactionReceipt({ hash });
  const [approvalGranted, setApprovalGranted] = useState(false);
  const navigate = useNavigate();
  const account = useAccount()
  const [allowance, setAllowance] = useState(BigNumber.from(0));
  let accountAddress = account.address;

  const handleDepositChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setDepositAmount(event.target.value);
  };
  
  const handleWithdrawChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setWithdrawAmount(event.target.value);
  };
  
  const handleEnter = () => {
    if (!depositAmount) {
      setStatusMessage('Please enter a deposit amount.');
      setStatusSeverity('error');
      return;
    }
    setStatusMessage('Initiating deposit...');
    setStatusSeverity('info');
    setOpen(true);
    
    writeContract({
      address: CONTRACT_ADDRESS_STAKING,
      abi: CONTRACT_ABI_STAKING,
      functionName: 'enter',
      args: [parseEther(depositAmount)],
    });
  };
  
  const handleLeave = () => {
    if (!withdrawAmount) {
      setStatusMessage('Please enter a withdrawal amount.');
      setStatusSeverity('error');
      return;
    }
    setStatusMessage('Initiating withdrawal...');
    setStatusSeverity('info');
    setOpen(true);
    
    writeContract({
      address: CONTRACT_ADDRESS_STAKING,
      abi: CONTRACT_ABI_STAKING,
      functionName: 'leave',
      args: [parseEther(withdrawAmount)],
    });
  };

  const { data: allowanceDataz, refetch: refetchAllowance } = useReadContract({
    address: CONTRACT_ADDRESS_TOKEN,
    abi: CONTRACT_ABI_TOKEN,
    functionName: 'allowance',
    args: [accountAddress, CONTRACT_ADDRESS_STAKING],
  });

  useEffect(() => {
    console.log("allowanceDataz" + allowanceDataz + accountAddress);
    if (allowanceDataz) {
      setAllowance(BigNumber.from(allowanceDataz));
      console.log("allowanceDataz" + allowance);
     // console.log("allowanceDataz" + allowanceDataz + accountAddress);
   
     setApprovalGranted(true);
    }
    else{
      setAllowance(BigNumber.from(0));
      console.log("allowanceDataz" + allowance);
    }
  }, [allowanceDataz]);

  useEffect(() => {
    if (accountAddress) {
        refetchAllowance();
        console.log("allowanceDataz" + allowance);
        refetchBal();
        refetchValue();
          //setAllowance(BigNumber.from(allowanceDataz));
      // Optionally, you can add logic here if you need to do something when the account changes
    }
  }, [accountAddress]);

  const { data: stakedData,refetch: refetchBal } = useReadContract({ 
    address: CONTRACT_ADDRESS_STAKING,
    abi: CONTRACT_ABI_STAKING,
    functionName: 'balanceOf',
    args: [accountAddress],
  });

  useEffect(() => {
    console.log("stakedData" + stakedData + accountAddress);
    if (stakedData) {
      console.log("stakedData" + stakedData); 
      setStakedAmount(BigNumber.from(stakedData));
    }
  }, [stakedData]);

  useEffect(() => {
    if (stakedData) {
      refetchBal();

    }
  }, [stakedData]);

  
  const { data: wineData,refetch: refetchWineBal } = useReadContract({
    address: CONTRACT_ADDRESS_TOKEN,
    abi: CONTRACT_ABI_TOKEN,
    functionName: 'balanceOf',
    args: [CONTRACT_ADDRESS_STAKING],
  });

  useEffect(() => {
    console.log("wineData" + wineData + accountAddress);
    if (wineData) {
      console.log("wineData" + wineData); 
      setWineAmount(BigNumber.from(wineData));
    }
  }, [wineData]);

  useEffect(() => {
    if (wineData) {
      refetchWineBal();

    }
  }, [wineData]);



  const { data: currentValueData, refetch: refetchValue } = useReadContract({
    address: CONTRACT_ADDRESS_STAKING,
    abi: CONTRACT_ABI_STAKING,
    functionName: 'totalSupply',
  });
  useEffect(() => {
    if (currentValueData) {
      refetchValue();
       // console.log("allowanceDataz" + allowance);
          //setAllowance(BigNumber.from(allowanceDataz));
      // Optionally, you can add logic here if you need to do something when the account changes
    }
  }, [currentValueData, refetchValue]);

  useEffect(() => {
    console.log("currentValueData" + currentValueData + accountAddress);
    if (currentValueData) {
      //console.log("currentValueData" + currentValueData); 
      setTotalXWine(BigNumber.from(currentValueData));
    }
  }, [currentValueData]);


  const onApprovalSuccess = () => {
    console.log('Approval successful!');
    // Optionally navigate or trigger other UI updates
  };
 
  const calculateWithdrawalValue = () => {
    if (totalXWine.eq(0)) { // Check if totalXWine is zero to avoid division by zero
      return BigNumber.from(0);
    }
    console.log("stakedAmount" + stakedAmount);
    console.log("wineAmount" + wineAmount);
    console.log("totalXWine" + totalXWine);
    
    //    return (myXWine * totalWine) / totalXWine;  // Calculate the value of sender's xWine in terms of Wine
    return stakedAmount.mul(wineAmount).div(totalXWine);
  };

  useEffect(() => {
    if (stakedAmount && wineAmount && totalXWine) {
      const calculatedValue = calculateWithdrawalValue();
      setWithdrawalValue(calculatedValue); // Assuming you have a state to store this value
    }
  }, [stakedAmount, wineAmount, totalXWine]); // Re-compute when any of these values change
  
  
  // Callback function to be called when approval is successful
  const handleApprovalSuccess = () => {
    console.log('Approval has been granted');
    setApprovalGranted(true);
  };

  useEffect(() => {
    let timer: string | number | NodeJS.Timeout | undefined;

    if (isPending) {
      setStatusMessage('Transaction is pending...');
      setStatusSeverity('info');
      setOpen(true);
    } else if (isConfirming) {
      setStatusMessage('Transaction is confirming...');
      setStatusSeverity('info');
      setOpen(true);
    } else if (isConfirmed) {
      setStatusMessage('Transaction confirmed!');
      setStatusSeverity('success');
      setOpen(true);
  
      timer = setTimeout(() => {
        setOpen(false);
      refetchValue();
      refetchWineBal();
      refetchBal();
      }, 6000);

    } else if (error) {
      setStatusMessage(`Transaction failed`);
      setStatusSeverity('error');
      setErrorMessage(error.message);
      setOpen(true);
      timer = setTimeout(() => {
        setOpen(false);
      }, 5000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isPending, isConfirming, isConfirmed, error]);
  const isAllowanceSufficient = allowance.gte(parseEther('1.0'));
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <PageRedux backgroundImage={'/media/cell.jpg'}>
     <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '85vh', // Adjusted height for less vertical space
        backgroundColor: "rgba(223, 223, 233, 0.0)",
      }}
    >
     
      <Box
        sx={{
          maxWidth: 600,
          padding: 3,
          backgroundColor: "rgba(223, 223, 233, 0.8)",
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(10,10,10,0.1)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          minHeight: '45vh',
          gap: 2,
          border: '1px solid rgba(0, 0, 0, 0.1)', // Optionally added a subtle border
        }}
      >
          <Typography variant="h1" gutterBottom>
            The Wine Cellar
          </Typography>
          <Typography variant="h2" gutterBottom>
          Stake Wine for xWine
          </Typography>
          <Typography variant="body1" gutterBottom>
          Collect fees collected by chatover.wine
          </Typography>
          <Typography variant="body1" gutterBottom>
          You can enter and leave at any time
          </Typography>
          {!approvalGranted ? (
            <ApproveStakeButton onApprovalSuccess={handleApprovalSuccess} />
          ) : (
            <>

             <Typography variant="h6" gutterBottom>
  Your Staked Wine: { parseFloat(ethers.utils.formatEther(stakedAmount)).toFixed(0)} xWine
</Typography>
<Typography variant="h6" gutterBottom>
  Potential Withdrawal Value: {withdrawalValue ? ethers.utils.formatEther(withdrawalValue) : '0'} Wine
</Typography>
<TextField
    label="Deposit Amount"
    variant="outlined"
    value={depositAmount}
    onChange={handleDepositChange}
    helperText="Enter the amount of Wine to deposit"
    type="number"
    InputLabelProps={{
      shrink: true,
    }}
    fullWidth
  />
  <Button
    variant="contained"
    color="primary"
    disabled={isPending || isConfirming}
    onClick={handleEnter}
    endIcon={isPending || isConfirming ? <CircularProgress size={20} /> : null}
    sx={{ margin: "0.5rem" }}
  >
    {isPending ? 'Confirming...' : 'Deposit'}
  </Button>
  <TextField
    label="Withdraw Amount"
    variant="outlined"
    value={withdrawAmount}
    onChange={handleWithdrawChange}
    helperText="Enter the amount of xWine to withdraw"
    type="number"
    InputLabelProps={{
      shrink: true,
    }}
    fullWidth
  />
  <Button
    variant="contained"
    color="primary"
    disabled={isPending || isConfirming}
    onClick={handleLeave}
    endIcon={isPending || isConfirming ? <CircularProgress size={20} /> : null}
    sx={{ margin: "0.5rem" }}
  >
    {isPending ? 'Confirming...' : 'Withdraw'}
  </Button>
  <Typography variant="body1" gutterBottom>
  Make sure you approve enough to spend if you haven't already
</Typography>
  <ApproveStakeButton onApprovalSuccess={handleApprovalSuccess} />
  <AddXTokenButton></AddXTokenButton>
            </>
          )}
          </Box>
          <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: '80%', sm: '80%', md: '40%', lg: '40%' },
            bgcolor: "none",
            border: "none",
            boxShadow: 0,
            padding: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <StyledCard>
            <Typography
                margin={"1rem"}
                id="modal-modal-title"
                variant="h1"
                fontSize="1.2rem"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  wordWrap: 'break-word',
                  overflowWrap: 'break-word',
                  whiteSpace: 'normal',
                  maxWidth: '90%',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              >
            {statusMessage}
              </Typography>
              {statusSeverity === 'error' && (
                <Typography
                  margin={"1rem"}
                  color="error"
                  sx={{
                    wordWrap: 'break-word',
                    overflowWrap: 'break-word',
                    whiteSpace: 'normal',
                    maxWidth: '90%',
                  }}
                >
                  {errorMessage}
                </Typography>
              )}
              {statusSeverity !== 'success' && (
                <>
                <Box m={"1rem"} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <CircularProgress color="secondary" />
                </Box>
                  <Box margin={"1rem"} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <img src="./media/icon.png" alt="Status" style={{ width: '30%', height: '30%' }} />
                </Box></>
              )}

{statusSeverity === 'success' && (
                <>
                <Box m={"1rem"} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Typography
                  margin={"1rem"}
                  color="error"
                  sx={{
                    wordWrap: 'break-word',
                    overflowWrap: 'break-word',
                    whiteSpace: 'normal',
                    maxWidth: '90%',
                  }}
                >
                 Finished
                </Typography>
                </Box>
                  <Box margin={"1rem"} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <img src="./media/icon.png" alt="Status" style={{ width: '30%', height: '30%' }} />
                </Box></>
              )}
            </StyledCard>
          </Box>
        </Box>
      </Modal>
      </Box>
    </PageRedux>
  );
};

export default ContractInteractionButton;
