import React, { createContext, useContext, useState, ReactNode } from 'react';
import Cookies from 'js-cookie';

// Define a type for the context state
type AuthContextType = {
  loggedIn: boolean;
  setLoggedIn: (loggedIn: boolean) => void;
  setCookie: (name: string, value: string, days: number) => void;
  clearCookie: (name: string) => void;
};

// Create a Context with a default value of null, but it is typed to AuthContextType
const AuthContext = createContext<AuthContextType | null>(null);

// Export a hook to access the context
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === null) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

// Define props type for AuthProvider component
type AuthProviderProps = {
  children: ReactNode;
};

// AuthProvider component to provide state
export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(false);

  // Methods to manipulate cookies directly within the context
  const setCookie = (name: string, value: string, days: number) => {
    Cookies.set(name, value, { expires: days, secure: true, sameSite: 'Strict' });
  };

  const clearCookie = (name: string) => {
    Cookies.remove(name);
  };

  // Value provided to context consumers
  const value = {
    loggedIn,
    setLoggedIn,
    setCookie,
    clearCookie
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
