import { useState } from "react"
import { 
  Box,
  Link,
  AppBar,
  Toolbar,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  ButtonGroup
} from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"
import Connect from "components/Connect"
import { Typography } from "@mui/material"
import Select from 'react-dropdown-select';
import { useNavigate } from 'react-router-dom';
import DropdownMenu from "./DropdownMenu"
import DropdownMenuTokens from "./DropDownMenuTokens"
import ConnectSolana from "./ConnectSolana"
import ConnectWallet from "./ConnectWallet"
import WalletBalance from "pages/WalletBalance"
//import { useWallet } from './WalletContext';
//import { CustomConnectButton } from "./CustomConnectButton"
const options = [
  { label: 'Project 1', value: '/project1' },
  { label: 'Project 2', value: '/project2' },
];



const HeaderSolana = () => {
  //const { wallet, connectWallet } = useWallet();
  const navigate = useNavigate();
  const [leaveTimer, setLeaveTimer] = useState<any>(null);
  const handleProjectsMouseOver = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    clearTimeout(leaveTimer);
    setAnchorEl(event.currentTarget);
  };
  const handleMenuMouseLeave = () => {
    const timer: any = setTimeout(() => {
      setAnchorEl(null);
    }, 12500); // Delay in milliseconds
    setLeaveTimer(timer);
  };
  
  
  const handleProjectsMouseOut = () => {
    const timer = setTimeout(() => {
      setAnchorEl(null);
    }, 500); // Delay in milliseconds
    setLeaveTimer(timer);
  };
  
const items = [
  /*
  {
    label: "Bridge",
    url: "/bridge",
    enabled: true,
    target: "_self"
     <Link pr="1.25rem" pl="1.25rem" variant="h1" href="/bridge" sx={{fontSize:"1.0rem", alignItems:"center", alignContent:"center", textDecoration: 'none' }}>
        Bridge
      </Link> href="https://amazastophic.gitbook.io/chatoverwine"  target="_blank"
  },
  */
  {
    label: "Bridge",
    url: "/bridge",
    enabled: true,
    target: "_self"
  },
  {
    label: "Cellar",
    url: "/cellar",
    enabled: true,
    target: "_self"
  },
  {
    label: "Latest Bot",
    url: "/leaderboard",
    enabled: true,
    target: "_self"
  },
  {
    label: "Gitbook",
    url: "https://amazastophic.gitbook.io/chatoverwine",
    enabled: true,
    target: "_blank"
  },


 
]


const handleClose = () => {
  setAnchorEl(null);
};
const [projects, setProjects] = useState([
  {label: "Project 1", url: "/project1"},
  {label: "Project 2", url: "/project2"},
  // Add more projects as needed
]);

  const [mobileOpen, setMobileOpen] = useState(false)
const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const projectsOptions = projects.map((project) => ({
    label: project.label,
    value: project.url,
  }));
  const drawer = (
    <Box background-color={"#d4cfcf"} onClick={handleDrawerToggle} sx={{textAlign: "center", backgroundColor:"#d4cfcf"} }>
      <List dense>
        {items.map((item) => (
          <ListItem sx={{"&:hover": {backgroundColor: "#d4cfcf"}}} key={item.label} disablePadding>
            <ListItemButton component={Link} href={item.url} target={item.target} sx={{textAlign: "left", pointerEvents: item.enabled ? "auto" : "none"}}>
              <ListItemText primary={item.label} primaryTypographyProps={{fontSize: 18, fontWeight: 600, color: item.enabled ? "primary" : "lightgrey"}}/>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  )
  return (
    <Box sx={{width: "100%",  display: "flex", justifyContent: "center", backgroundColor: "#d4cfcf "}}>
      <AppBar component="nav"  position="sticky" elevation={1} sx={{backgroundColor: "#d4cfcf ", boxShadow: 0, zIndex: 100}}>
        <Toolbar sx={{width: "100%", height:"30px", display: "flex", margin: "auto", justifyContent: "space-between", backgroundColor: "#d4cfcf "}}>
          <Box sx={{display: "flex", backgroundColor: "#d4cfcf ", justifyContent: "center", alignItems: "center"}}>
            <IconButton
              color="default"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{mr: 2, display: {sm: "none"}}}>
              <MenuIcon/>
            </IconButton>
            <Box sx={{paddingRight: "30px", width:'16vw', display: {xs: "none", sm: "none", md: "flex", lg:"flex" }}}>
              <Link href="/" sx={{display: "flex", width:'16vw'}}>
              <img src={`https://chatover.wine/media/logo.png`} alt="Sage Towers" width={'100%'}  />
              </Link>
            </Box>
            <Box sx={{paddingRight: "1px", width:'40vw', display: {xs: "flex", sm: "flex", md: "none", lg:"none" }}}>
              <Link href="/" sx={{display: "flex", width:'40vw'}}>
              <img src={`https://chatover.wine/media/logo.png`} alt="Sage Towers" width={'50%'}  />
              </Link>
            </Box>
       
    <Box sx={{display: {xs: "none", sm: "block"}, alignItems:"center", alignContent:"center"}}>
      <ButtonGroup sx={{alignItems:"center", alignContent:"center"}} variant="text" aria-label="text button group">

      <Link pr="1.25rem" pl="1.25rem" variant="h1" href="/bridge" sx={{fontSize:"1.0rem", alignItems:"center", alignContent:"center", textDecoration: 'none' }}>
        Bridge
      </Link>
      <Link pr="1.25rem" pl="1.25rem" variant="h1" href="/cellar" sx={{fontSize:"1.0rem", alignItems:"center", alignContent:"center", textDecoration: 'none' }}>
        Cellar
      </Link>
      <Link pr="1.25rem" pl="1.25rem" variant="h1" href="/leaderboard" sx={{fontSize:"1.0rem", alignItems:"center", alignContent:"center", textDecoration: 'none' }}>
        Latest Bot
      </Link>
       <Link pr="1.25rem" pl="1.25rem" variant="h1" href="https://amazastophic.gitbook.io/chatoverwine"  target="_blank" rel="noopener noreferrer"  sx={{fontSize:"1.0rem", alignItems:"center", alignContent:"center", textDecoration: 'none' }}>
        Gitbook
      </Link>

    
   

     </ButtonGroup>

    </Box>
          </Box>
          <Box sx={{ display: {xs: "none", sm: "none", md: "flex", lg:"flex" },  flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",}}>
            <Box>
       
      
            <Link href="https://dexscreener.com/solana/5Y3RHbBMDy9qEDamQz1sGFRB767nw6AnxirtugRJ3JNV" target="_blank" sx={{ m:1}} >
        <img  src={`https://chatover.wine/media/icons/dex.svg`} alt="Dex" height='28px' width='28px' />
        </Link>
        
        <Link href="https://t.me/chatoverwine" target="_blank" sx={{ m:1}} >
        <img src={`https://chatover.wine/media/icons/telegram.svg`} alt="Telegram" height='27px' width='27px' />
        </Link>
        
          <Link href="https://twitter.com/chatover_wine" target="_blank" sx={{ m:1}} >
        <img src={`https://chatover.wine/media/icons/twitter.svg`} alt="Twitter" height='27px' width='27px' />
        </Link>

        </Box>
        <Box display="flex" alignItems="center" sx={{ gap: 2 }}>
          
       
          
        </Box>
          </Box>
          <Box sx={{ display: {xs: "flex", sm: "flex", md: "none", lg:"none" },  gap: 1  ,  flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",}}>
            <Box sx={{ display: { xs: 'flex', sm: 'flex', md: 'none', lg: 'none' }, gap: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
          
         
        </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer variant="temporary" open={mobileOpen} onClose={handleDrawerToggle} ModalProps={{keepMounted: true}}
          sx={{
            
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              backgroundColor:"#d4cfcf",
              boxSizing: "border-box",
              width: 240
            }
          }}
        >     
          {drawer}
          <Box marginTop={'2vh'} sx={{ display: {xs: "flex", sm: "flex", md: "none", lg:"none" },  gap: 5  ,  flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",}}>
            <Box>
           
    
          
        <Link href="https://dexscreener.com/solana/5Y3RHbBMDy9qEDamQz1sGFRB767nw6AnxirtugRJ3JNV" target="_blank" sx={{ Height:"28px" ,m:1}} >
        <img  src= {`https://chatover.wine/media/icons/dex.svg`} alt="Dex" height='28px' width='28px' />
        </Link>
      
        <Link href="https://t.me/chatoverwine" target="_blank" sx={{ m:1}} >
        <img src={`https://chatover.wine/media/icons/telegram.svg`} alt="Telegram" height='27px' width='27px' />
        </Link>    
        <Link href="https://twitter.com/chatover_wine" target="_blank" sx={{ m:1}} >
        <img src={`https://chatover.wine/media/icons/twitter.svg`} alt="Twitter" height='27px' width='27px' />
        </Link>
 
        </Box>
        </Box>
        </Drawer>
      </Box>
    </Box>
  )
}

export default HeaderSolana