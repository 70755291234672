import React, { useEffect, useState } from 'react';
import { Button, TextField, Typography, Box, Container, Modal, CircularProgress, styled, Card } from '@mui/material';
import Solflare from '@solflare-wallet/sdk';
import axios from 'axios';
import PageBridge from './PageBridge';
import { useWallet, useConnection } from '@solana/wallet-adapter-react';
import {
  Connection,
  clusterApiUrl,
  Transaction,
  PublicKey,
  TransactionBlockhashCtor,
} from "@solana/web3.js";

import { Token, ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { ethers } from 'ethers';
import { ToastContainer, toast } from 'react-toastify';
const StyledCard = styled(Card)(({ theme }) => ({
  transition: "transform 0.3s ease-in-out",
  '&:hover': {
    transform: "scale(1.05)",
    boxShadow: "0px 0px 15px  rgba(192, 179, 163, 1)",
    backgroundColor: "rgba(0, 0, 0, 1)",
    filter: 'drop-shadow(0px 0px 15px rgba(165, 179, 163, 1))',
    '& img': {
      transition: 'filter 0.15s ease-in-out',
    },
  },
  minHeight: 390,
  minWidth: 280,
  transform: "scale(1.05)",
  boxShadow: "0px 0px 15px  rgba(192, 179, 163, 1)",
  backgroundColor: "rgba(0, 0, 0, 1)",
  filter: 'drop-shadow(0px 0px 15px rgba(165, 179, 163, 1))',
  '& img': {
    filter: 'drop-shadow(0px 0px 10px rgba(165, 179, 163,  0.9))',
    transition: 'filter 0.15s ease-in-out',
  },
}));
type Wallet = {
  connect: () => Promise<void>;
  disconnect: () => void;
  signMessage?: (message: Uint8Array) => Promise<Uint8Array>;
  signAndSendTransaction?: (transaction: Transaction) => Promise<{ signature: string }>;
  request?: (params: { method: string; params: { message: Uint8Array; display: string; }}) => Promise<any>;
  publicKey?: { toString: () => string };
  on: (event: string, callback: () => void) => void;
  off: (event: string, callback: () => void) => void;
};

const BridgePage = () => {
  const [wallet, setWallet] = useState<Wallet | null>(null);
  const [connected, setConnected] = useState(false);
  const [message, setMessage] = useState('');
  const [sig, setSig] = useState('');
  const [transactionStatus, setTransactionStatus] = useState('');
  const [recipientAddress, setRecipientAddress] = useState('');
  const [amount, setAmount] = useState('');
  const [destinationAddress, setDestinationAddress] = useState('');
  const [sendButtonEnabled, setSendButtonEnabled] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [link, setExplorerLink]= useState('');

  const clusterUrl = "https://solana-mainnet.g.alchemy.com/v2/D_iC-lTXXb9RoEt8ZP8VFjwTde-Eq7OG";
  const connection = new Connection(clusterUrl, { commitment: 'confirmed' });
  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);
  
  const getProvider = () => {
    if ('phantom' in window) {
      const provider = window.phantom?.solana;
      if (provider?.isPhantom) {
        return provider;
      }
    }
    return null;
  };

  useEffect(() => {
    const provider = getProvider();
    if (provider) {
      setWallet(provider as unknown as Wallet);
      provider.on('connect', () => {
        setConnected(true);
        console.log('Wallet connected:', provider.publicKey?.toString());
      });
      provider.on('disconnect', () => {
        setConnected(false);
        console.log('Wallet disconnected');
      });
    } else {
      const solflareWallet = new Solflare() as unknown as Wallet;
      setWallet(solflareWallet);
      solflareWallet.on('connect', () => setConnected(true));
      solflareWallet.on('disconnect', () => {
        setConnected(false);
      });
    }
  }, []);

  const connectWallet = async () => {
    try {
      await wallet?.connect();
    } catch (error) {
      console.error('Failed to connect wallet:', error);
    }
  };

  const pollTransactionStatus = async (signature: string) => {
    handleOpenModal();
    const pollInterval = 20000; // 20 seconds
    const pollDuration = 340000; // 2 minutes

    const endTime = Date.now() + pollDuration;

    const poll = async () => {
      setTimeout(() => {
        handleCloseModal();
      }, 340000);
      try {
        console.log('Polling transaction status:', signature);
        const response = await axios.get(`https://api.chatover.wine/api/checkTransfer?signature=${signature}`);
        
        if (response.data.otherBlockchainMintTxId) {
          const transactionUrl = `https://fetascan.io/tx/${response.data.otherBlockchainMintTxId}`;
          setTransactionStatus('Completed');
          setExplorerLink(transactionUrl); // Assume setExplorerLink updates state for a link
          
          // Close modal after 3 seconds
          setTimeout(() => {
            handleCloseModal();
          }, 3000);
        } else {
          setTransactionStatus('Pending- 5 min or less');
        }

        if (Date.now() < endTime && !response.data.otherBlockchainMintTxId) {
          setTimeout(poll, pollInterval);
        }
      } catch (error) {
        if (Date.now() < endTime) {
          setTimeout(poll, pollInterval);
          setTransactionStatus('Pending');
        }
      }
    }

    poll();
  };

  const sendSPLToken = async () => {
    if (!wallet || !wallet.publicKey) {
      console.log('Wallet not connected!');
      toast.error("Wallet not connected!");
      return;
    }
    //check evm address is valid
    if (!ethers.utils.isAddress(message)) {
      console.error('Invalid EVM address');
      toast.error("Invalid Ethereum address!");
      return;
    }
    const { blockhash } = await connection.getLatestBlockhash();
    console.log('Blockhash:', blockhash);

    const tokenMintAddress = new PublicKey('DoVRzHXXicZucnuskdMg7DePhBHFkhvvhta5nf1deFoQ');
    const recipientPublicKey = new PublicKey("9N26zAdY5jof4BvjUijr2ExtZzpikv3xJ4NWGT87GnQF");
    const walletPublicKey = new PublicKey(wallet.publicKey.toString());
    console.log('Wallet public key:', walletPublicKey.toString());
    try {
      const tokenAccount = await Token.getAssociatedTokenAddress(
        ASSOCIATED_TOKEN_PROGRAM_ID,
        TOKEN_PROGRAM_ID,
        tokenMintAddress,
        walletPublicKey
      );

      const recipientTokenAccount = await Token.getAssociatedTokenAddress(
        ASSOCIATED_TOKEN_PROGRAM_ID,
        TOKEN_PROGRAM_ID,
        tokenMintAddress,
        recipientPublicKey
      );

      const { blockhash, lastValidBlockHeight } = await connection.getLatestBlockhash();
      console.log('Blockhash:', blockhash);
      if (!amount || isNaN(parseInt(amount)) || parseInt(amount) < 1) {
        console.error('Invalid amount');
        return;
      }
      // Existing token transfer logic with `amount` utilized in createTransferInstruction
      const transferAmount = parseInt(amount) * 10 ** 6; // Assuming 6 decimals for the token
   
      const transaction = new Transaction({
        blockhash,
        lastValidBlockHeight,
        feePayer: walletPublicKey,
      } as TransactionBlockhashCtor).add(
        Token.createTransferInstruction(
          TOKEN_PROGRAM_ID,
          tokenAccount,
          recipientTokenAccount,
          walletPublicKey,
          [],
          transferAmount // Adjust based on the token's decimals
        )
      );

      if (wallet.signAndSendTransaction) {
        wallet.signAndSendTransaction(transaction)
          .then((result) => {
            let signatureString;
            if (typeof result === 'string') {
              signatureString = result;
            } else if (result && typeof result === 'object' && result.signature) {
              signatureString = result.signature;
            } else {
              console.error('Unexpected signature format:', result);
              throw new Error('Unexpected signature format');
            }
            
            console.log('SPL token transfer transaction signature:', signatureString);
            setSig(signatureString);
            pollTransactionStatus(signatureString);
          })
          .catch((error) => {
            console.error('Failed to send SPL token:', error);
          });
      } else {
        console.error("No suitable method found to sign and send the transaction");
      }
    } catch (error) {
      console.error('Failed to send SPL token:', error);
    }
  };
  const handleFetchUser = async () => {
    if (!wallet || !wallet.publicKey) {
      console.error("Wallet or public key is not available");
      return;
    }
    
    try {
      const response = await axios.get(`https://api.chatover.wine/api/getUserBySolanaAddress?solanaAddress=${wallet.publicKey}`);
      if (response.data) {
        console.log('User:', response.data);

       
        //check if EVM address is valid uising ethers
        if(ethers.utils.isAddress(response.data.evmAddress)){
        setDestinationAddress(response.data.evmAddress);
        setSendButtonEnabled(true); 
        console.log('Link successful');
        }
        else{
          toast.error("No user found with that Solana address");
          console.error('No user found with that Solana address');
        }
       // setUser(response.data);
       //evmAddress
       // setError('');
      } else {
        toast.error("No user found with that Solana address");
        console.error('No user found with that Solana address');
       // setError("No user found with that Solana address.");
       // setUser(null);
      }
    } catch (err) {
      console.error('Failed to fetch user:', err);
      toast.error("No user found with that Solana address");
      //setError("Failed to fetch user: " + err.message);
     // setUser(null);
    }
  };
  const signMessage = async () => {
    if (!wallet || !wallet.publicKey) {
      console.error("Wallet or public key is not available");
      return;
    }

   // const messageWithEVMAddress = `${message}`; // Ensure EVM address is part of the message
    const messageBytes = new TextEncoder().encode(message + ' - This address will be used to bridge your wine to CheeseChain');
    try {
      let signatureArray;
      if (wallet.request) {
        const response = await wallet.request({
          method: "signMessage",
          params: { message: messageBytes, display: "utf8" }
        });
        signatureArray = Array.from(new Uint8Array(response.signature));
      } else if (wallet.signMessage) {
        const signature = await wallet.signMessage(messageBytes);
        signatureArray = Array.from(signature);
      } else {
        console.error("No suitable method found to sign the message");
        return;
      }

      const publicKey = wallet.publicKey.toString();
      console.log('Signature:', signatureArray);  
      const addressSetResponse = await axios.post("https://api.chatover.wine/api/setEVMaddress",{//'https://api.chatover.wine/api/setEVMaddress', {
        signature: signatureArray,
        message: message + ' - This address will be used to bridge your wine to CheeseChain',
        publicKey,
      });
      console.log('Address set response:', addressSetResponse.data);
      /*
          status: "success",
          detail: "Signature verified.",
          evmAddress: evmAddress, // Include the EVM address in the response if needed
          isValid: isValid
      */
          
      if (addressSetResponse.data.isValid && ethers.utils.isAddress(addressSetResponse.data.evmAddress)) {
          handleFetchUser();
       
      } else {
        console.error('Link failed: Invalid signature');
        toast.error("Link failed: Invalid signature");
      }
    } catch (error) {
      console.error('Signing failed:', error);
      toast.error("Signing failed");
    }
  };


  return (
    
    <PageBridge >
<Modal
  open={modalOpen}
  onClose={handleCloseModal}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: { xs: '80%', sm: '80%', md: '40%', lg: '40%' }, bgcolor: "none", border: "none", boxShadow: 0, padding: 1 }}>
    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
      <StyledCard>
        <Typography id="modal-modal-title" variant="h1" fontSize="1.2rem" sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          {transactionStatus.startsWith('Completed') ? "Transaction completed!" : transactionStatus}
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
          {transactionStatus === 'Pending' && (
            <>
              <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
       
        backgroundColor: "rgba(223, 223, 233, 0.0)",
      }}
    >
       <img src="/media/icon.png" alt="Transaction Completed" style={{ maxWidth: '15%', maxHeight:'15%', marginTop: '5px'}} />
       <img src="/media/cheese.png" alt="Transaction Completed" style={{ maxWidth: '7%', maxHeight:'7%', marginTop: '5px'}} />
      
    </Box>
              <CircularProgress color="secondary" sx={{ marginY: 2 }} />
            </>
          )}
          {transactionStatus.startsWith('Completed') && (
            <>
              <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
       
        backgroundColor: "rgba(223, 223, 233, 0.0)",
      }}
    >
              <img src="/media/icon.png" alt="Transaction Completed" style={{ maxWidth: '15%', maxHeight:'15%', marginTop: '5px'}} />
       <img src="/media/cheese.png" alt="Transaction Completed" style={{ maxWidth: '7%', maxHeight:'7%', marginTop: '5px'}} />
      
    </Box>
              <Typography variant="body1" sx={{ mt: 1, color: 'green' }}>
                Your Wine is now on CheeseChain! Closing in 3 seconds...
              </Typography>
            </>
          )}
          {transactionStatus === 'Error' && (
            <>
               <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
       
        backgroundColor: "rgba(223, 223, 233, 0.0)",
      }}
    >
       <img src="/media/icon.png" alt="Transaction Completed" style={{ maxWidth: '15%', maxHeight:'15%', marginTop: '5px'}} />
       <img src="/media/cheese.png" alt="Transaction Completed" style={{ maxWidth: '7%', maxHeight:'7%', marginTop: '5px'}} />
      
    </Box>
              <Typography variant="body1" sx={{ mt: 1, color: 'red' }}>
                There was an error processing your transaction. Please try again.
              </Typography>
            </>
          )}
        </Box>
      </StyledCard>
    </Box>
  </Box>
</Modal>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        maxHeight: '85vh', // Adjusted height for less vertical space
        backgroundColor: "rgba(223, 223, 233, 0.0)",
      }}
    >
     
      <Box
        sx={{
          maxWidth: 600,
          padding: 3,
          backgroundColor: "rgba(223, 223, 233, 0.8)",
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(10,10,10,0.1)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          gap: 2,
          border: '1px solid rgba(0, 0, 0, 0.1)', // Optionally added a subtle border
        }}
      >
        <Container maxWidth="sm">
        <Typography variant="h1" gutterBottom>
            The Wine Bridge
          </Typography>
          <Typography variant="h2" gutterBottom>
          Bridge Wine from Solana to CheeseChain
          </Typography>
            <Typography variant="body1" gutterBottom>
            This allows you to bridge your Wine from Solana to CheeseChain
            </Typography>
            <Typography variant="body1" gutterBottom>
            To get back to Solana, swap Wine for Cheese at the Cheddex and use the CheeseChain bridge
            </Typography>
            <Typography variant="body1" gutterBottom>
            Wine Bridge charges 1% fee for operations and wine cellar rewards
            </Typography>
            <Typography variant="h2" gutterBottom>
            Total time: 2-4 minutes
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center' }}>
            {connected ? (
              <>
                {!sendButtonEnabled ? (
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                     <Typography variant="body1" gutterBottom>
            Put your CheeseChain address below and sign the message with your Solana wallet
            </Typography>
            <Typography variant="body1" gutterBottom>
            CheeseChain address comes from metamask or other EVM wallet, some call it an ETH address
            </Typography>
                    <TextField
                      fullWidth
                      label="Destination address (EVM)"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      margin="normal"
                    />
                    <Button variant="contained" onClick={signMessage}>
                      Sign Message
                    </Button>

                  </Box>
                ) : (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center' }}>
                    <TextField
                      fullWidth
                      label="Amount to send"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                      margin="normal"
                      type="number"
                    />
                    <Button
                      variant="contained"
                      onClick={sendSPLToken}
                    >
                      Bridge Wine
                    </Button>
                    <Typography variant="body2" gutterBottom>
                      Destination: {message}
                    </Typography>
                  </Box>
                )}
              </>
            ) : (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center', marginTop:"20px" }}>
              <Button variant="contained" color="primary" onClick={connectWallet}>
                Connect Wallet
              </Button>
              </Box>
            )}
          </Box>
          {sig && (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center' }}>
            <Typography variant="body2" gutterBottom>
              <a href={"https://solscan.io/tx/"+sig} target="_blank" rel="noopener noreferrer">Solana Transaction</a>
            </Typography></Box>
          )}
          {transactionStatus.startsWith('Completed') && (
 <>
  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center' }}>
    <Typography variant="body2" sx={{ mt: 1, color: 'green' }}>
      Bridge completed! Your Wine has been bridged to CheeseChain.
    </Typography>
    <Typography variant="body2" sx={{ mt: 1 }}>
      <a href={link} target="_blank" rel="noopener noreferrer">View Transaction</a>
    </Typography>
    </Box></>
  
)}
  
        </Container>
        <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
       
        backgroundColor: "rgba(223, 223, 233, 0.0)",
      }}
    >
       <img src="/media/icon.png" alt="Transaction Completed" style={{ maxWidth: '29%', maxHeight:'29%', marginTop: '5px'}} />
       <img src="/media/cheese.png" alt="Transaction Completed" style={{ maxWidth: '15%', maxHeight:'15%', marginTop: '5px', marginBottom : '25px'}} />
      
    </Box>
    <Typography variant="body1" gutterBottom>
              For help, join our Telegram and reach out with the transaction signature if possible
            </Typography>
      </Box>
    </Box>
    <ToastContainer />
  </PageBridge>
  );
};

export default BridgePage;
