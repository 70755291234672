import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const CookieConsent = () => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    const consent = localStorage.getItem('cookieConsenter');
    if (!consent) {
      setOpen(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsenter', 'true');
    setOpen(false);
  };

  return (
    <Dialog open={open} aria-labelledby="beta-modal-title">
    <DialogTitle id="beta-modal-title">Welcome to chatover.wine</DialogTitle>
    <DialogContent>
      <DialogContentText>
        This site is currently in under active development
        <br /><br />
        Key Information:
        <ul>
          <li>Bot minting is in early access</li>
          <li>Bot chat and image generators are in early access</li>
        </ul>
        Contact us on Twitter or Telegram with feedback
        <br /><br />
        <br /><br />
        YOU MUST BE 18 YEARS OR OLDER TO USE THIS SITE
        <br /><br />
        By continuing to use this site, you agree to our <a href="https://chatover.wine/terms" style={{ color: theme.palette.upcoming.dark }}>Terms of Service</a> and consent to our use of cookies.
        <br /><br />
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => handleAccept()} color="primary">I'm over 18, Agree and Continue</Button>
    </DialogActions>
  </Dialog>
  );
};

export default CookieConsent;
