import React from 'react';
import { ethers } from 'ethers';
import { useAccount } from 'wagmi';
import Button from '@mui/material/Button';

const AddCustomChainButton = () => {
  const { isConnected } = useAccount();

  const handleAddChain = async () => {
    if (!isConnected) {
      alert('Please connect your wallet first.');
      return;
    }

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const { chainId } = await provider.getNetwork();

    if (chainId !== 383353) {
      try {
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: '0x5d979', // 0x8CA804 is the hexadecimal value of 9209108
              chainName: 'CheeseChain',
              nativeCurrency: {
                name: 'Cheese',
                symbol: 'CHEESE',
                decimals: 18,
              },
              rpcUrls: ['https://rpc.cheesechain.xyz/http '],
              blockExplorerUrls: ['https://fetascan.xyz/'],
            },
          ],
        });
        alert('CheeseChain added successfully!');
      } catch (error) {
        console.error('Failed to add custom chain', error);
        alert('Failed to add custom chain');
      }
    } else {
      alert('CheeseChain is already added.');
    }
  };

  return (
    <Button variant="contained" color="primary" onClick={handleAddChain}>
      Add CheeseChain
    </Button>
  );
};

export default AddCustomChainButton;
