import React, { useState, useEffect } from 'react';
import { useReadContract, useWriteContract, useWaitForTransactionReceipt, useAccount, useConnect } from 'wagmi';
import { ethers } from 'ethers';
import { Box, Typography, TextField, Button, MenuItem, Select, FormControl, InputLabel, CircularProgress, SelectChangeEvent } from '@mui/material';
import PageRedux from 'components/PageRedux';
import { useNavigate, useParams } from 'react-router-dom';
import { Address } from 'viem';
// Assuming you have this component

const CONTRACT_ADDRESS = '0xba6aEe6D0465e18dd34F19E93ac50526b48f0eA8'; // Replace with your actual contract address
const CONTRACT_ABI = [
	{
		"inputs": [],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [],
		"name": "AccessControlBadConfirmation",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			},
			{
				"internalType": "bytes32",
				"name": "neededRole",
				"type": "bytes32"
			}
		],
		"name": "AccessControlUnauthorizedAccount",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "user",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "address",
				"name": "token",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "BalanceDeducted",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "user",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "deductBalance",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "user",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "wineAmount",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "otherTokenId",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "otherAmount",
				"type": "uint256"
			}
		],
		"name": "deductDualBalance",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "user",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "address",
				"name": "token",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "bankFee",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "ownerFee",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "Deposit",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "depositTokens",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "user",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "wineAmount",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "otherAmount",
				"type": "uint256"
			}
		],
		"name": "DualBalanceDeducted",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "bankAddress",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "address",
				"name": "defaultOwnerAddress",
				"type": "address"
			}
		],
		"name": "FeeAddressesUpdated",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "bankFeePercentage",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "ownerFeePercentage",
				"type": "uint256"
			}
		],
		"name": "FeesUpdated",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "grantBalanceManagerRole",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "grantFeeManagerRole",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes32",
				"name": "role",
				"type": "bytes32"
			},
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "grantRole",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "grantWithdrawerRole",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes32",
				"name": "role",
				"type": "bytes32"
			},
			{
				"internalType": "address",
				"name": "callerConfirmation",
				"type": "address"
			}
		],
		"name": "renounceRole",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes32",
				"name": "role",
				"type": "bytes32"
			},
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "revokeRole",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "bytes32",
				"name": "role",
				"type": "bytes32"
			},
			{
				"indexed": true,
				"internalType": "bytes32",
				"name": "previousAdminRole",
				"type": "bytes32"
			},
			{
				"indexed": true,
				"internalType": "bytes32",
				"name": "newAdminRole",
				"type": "bytes32"
			}
		],
		"name": "RoleAdminChanged",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "bytes32",
				"name": "role",
				"type": "bytes32"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "account",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "sender",
				"type": "address"
			}
		],
		"name": "RoleGranted",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "bytes32",
				"name": "role",
				"type": "bytes32"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "account",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "sender",
				"type": "address"
			}
		],
		"name": "RoleRevoked",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_bankAddress",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "_defaultOwnerAddress",
				"type": "address"
			}
		],
		"name": "setFeeAddresses",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_bankFeePercentage",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_ownerFeePercentage",
				"type": "uint256"
			}
		],
		"name": "setFeePercentages",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_tokenAddressProvider",
				"type": "address"
			}
		],
		"name": "setTokenAddressProvider",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "user",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "address",
				"name": "token",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "Withdraw",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "withdrawTokens",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "BALANCE_MANAGER_ROLE",
		"outputs": [
			{
				"internalType": "bytes32",
				"name": "",
				"type": "bytes32"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "bankAddress",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "bankFeePercentage",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "DEFAULT_ADMIN_ROLE",
		"outputs": [
			{
				"internalType": "bytes32",
				"name": "",
				"type": "bytes32"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "defaultOwnerAddress",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "FEE_MANAGER_ROLE",
		"outputs": [
			{
				"internalType": "bytes32",
				"name": "",
				"type": "bytes32"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "user",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "getBalance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes32",
				"name": "role",
				"type": "bytes32"
			}
		],
		"name": "getRoleAdmin",
		"outputs": [
			{
				"internalType": "bytes32",
				"name": "",
				"type": "bytes32"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes32",
				"name": "role",
				"type": "bytes32"
			},
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "hasRole",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "ownerFeePercentage",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes4",
				"name": "interfaceId",
				"type": "bytes4"
			}
		],
		"name": "supportsInterface",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "tokenAddressProvider",
		"outputs": [
			{
				"internalType": "contract ITokenAddressProvider",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "WINE_TOKEN_ADDRESS",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "WITHDRAWER_ROLE",
		"outputs": [
			{
				"internalType": "bytes32",
				"name": "",
				"type": "bytes32"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
]; // Replace with your actual contract ABI
const CONTRACT_ADDRESS_NFT = '0xA8675eaacFCB964a81e360B857e06dbedc2F003d';
const CONTRACT_ABI_NFT = [
  {
    "inputs": [{"internalType": "uint256","name": "tokenId","type": "uint256"}],
    "name": "getTokenAddress",
    "outputs": [{"internalType": "address","name": "","type": "address"}],
    "stateMutability": "view",
    "type": "function"
  },
  // ... other functions from your NFT ABI
];

const WINE_TOKEN_ADDRESS = '0x56e5776B59c3dfB42bA9B45733Cf618F6D128Ec5';

const ERC20_ABI = [
    {
      "constant": true,
      "inputs": [{"name": "_owner","type": "address"},{"name": "_spender","type": "address"}],
      "name": "allowance",
      "outputs": [{"name": "","type": "uint256"}],
      "type": "function"
    },
    {
      "constant": false,
      "inputs": [{"name": "_spender","type": "address"},{"name": "_value","type": "uint256"}],
      "name": "approve",
      "outputs": [{"name": "","type": "bool"}],
      "type": "function"
    },
    {
      "constant": true,
      "inputs": [{"name": "_owner","type": "address"}],
      "name": "balanceOf",
      "outputs": [{"name": "balance","type": "uint256"}],
      "type": "function"
    },
    // ... other standard ERC20 functions
  ];
  
  interface INFTMetadata {
    tokenId: number;
    name: string;
    description: string;
    image: string;
    coinTicker: string;
    coinName: string;
  }
  
  const TokenDepositPage = () => {
	const getPathTokenID = () => {
		const pathname = window.location.pathname; // e.g., '/chatbot/1'
		console.log("Current Path:", pathname);
		
		const segments = pathname.split('/'); // Split the path into segments
		if(segments.length < 3) return 0; // If there are less than 3 segments, return 0 (no token ID found)
		const tokenID = segments[2]; // Assuming 'chatbot' is always at the first index after the first slash
		if(tokenID) {
		console.log("Token ID:", tokenID);
		//make tokenid a number
		const token= Number(tokenID);
		return token;
		}
	
		return 0;
	}
	
    const { botId } = useParams<{ botId: string }>();
    const [amount, setAmount] = useState('');
    const [tokenId, setTokenId] = useState<number>(0);
    const [statusMessage, setStatusMessage] = useState('');
    const [statusSeverity, setStatusSeverity] = useState('info');
    const [nfts, setNfts] = useState<INFTMetadata[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [approvedAmount, setApprovedAmount] = useState<string>('0');
    const [isTransactionPending, setIsTransactionPending] = useState(false);
	const [isApprovalComplete, setIsApprovalComplete] = useState(false);
    const { address, isConnected } = useAccount();
    const { connect, connectors } = useConnect();
    const navigate = useNavigate();
	const tmpToken= getPathTokenID();
	useEffect(() => {
        const tmpToken = getPathTokenID();
        setTokenId(tmpToken);
    }, []);


    const { data: tokenAddress } = useReadContract({
      address: CONTRACT_ADDRESS_NFT,
      abi: CONTRACT_ABI_NFT,
      functionName: 'getTokenAddress',
      args: [BigInt(tokenId)],
    }) as { data: Address | undefined };
  
    const currentTokenAddress = tokenId === 0 ? WINE_TOKEN_ADDRESS : tokenAddress;
  
	const { data: allowance, refetch: refetchAllowance } = useReadContract({
		address: currentTokenAddress,
		abi: ERC20_ABI,
		functionName: 'allowance',
		args: [address, CONTRACT_ADDRESS],
	  }) as { data: bigint | undefined, refetch: () => void };
  
    const { data: tokenBalance } = useReadContract({
      address: currentTokenAddress,
      abi: ERC20_ABI,
      functionName: 'balanceOf',
      args: [address],
    }) as { data: bigint | undefined };
  
    const { data: wineBalance } = useReadContract({
      address: WINE_TOKEN_ADDRESS,
      abi: ERC20_ABI,
      functionName: 'balanceOf',
      args: [address],
    }) as { data: bigint | undefined };
  
    const { data: depositedBalance, refetch: refetchBalance } = useReadContract({
      address: CONTRACT_ADDRESS,
      abi: CONTRACT_ABI,
      functionName: 'getBalance',
      args: [address, BigInt(tokenId)],
    });
  
    const { writeContract: writeApprove, data: approveData } = useWriteContract();
    const { writeContract: writeDeposit, data: depositData } = useWriteContract();
  
    const { isLoading: isApprovingConfirming, isSuccess: isApproveConfirmed } = 
      useWaitForTransactionReceipt({ hash: approveData });
  
    const { isLoading: isDepositConfirming, isSuccess: isDepositConfirmed } = 
      useWaitForTransactionReceipt({ hash: depositData });
  
    useEffect(() => {
      const fetchNFTs = async () => {
        try {
          const response = await fetch('https://api.chatover.wine/api/getNFTDataLatest', {
            method: 'GET',
          });
  
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
  
          const data = await response.json() as INFTMetadata[];
          setNfts([{ tokenId: 0, name: 'WINE', coinTicker: 'WINE' } as INFTMetadata, ...data]);
  
          if (botId) {
            const selectedNFT = data.find(nft => nft.tokenId === parseInt(botId));
            if (selectedNFT) {
              setTokenId(selectedNFT.tokenId);
            } else {
              setError(`No token found for Bot ID: ${botId}`);
            }
          }
        } catch (error: any) {
          console.error('Failed to fetch NFT data:', error);
          setError('Failed to load NFT data: ' + error.message);
        }
        setLoading(false);
      };
  
      if (isConnected) {
        fetchNFTs();
      }
    }, [botId, isConnected]);
  
    useEffect(() => {
      if (allowance !== undefined) {
        setApprovedAmount(ethers.utils.formatUnits(allowance.toString(), 18));
      }
    }, [allowance]);
  
	useEffect(() => {
		if (isApprovingConfirming) {
		  setStatusMessage('Approval transaction is confirming...');
		  setStatusSeverity('info');
		  setIsTransactionPending(true);
		} else if (isApproveConfirmed) {
		  setStatusMessage('Approval successful! You can now deposit tokens.');
		  setStatusSeverity('success');
		  setIsTransactionPending(false);
		  setIsApprovalComplete(true);
		  refetchAllowance();
		}
	  }, [isApprovingConfirming, isApproveConfirmed, refetchAllowance]);

    useEffect(() => {
      if (isDepositConfirming) {
        setStatusMessage('Deposit transaction is confirming...');
        setStatusSeverity('info');
        setIsTransactionPending(true);
      } else if (isDepositConfirmed) {
        setStatusMessage('Deposit successful!');
        setStatusSeverity('success');
        setIsTransactionPending(false);
        refetchBalance();
      }
    }, [isDepositConfirming, isDepositConfirmed, refetchBalance]);
  
    const handleConnectWallet = async () => {
      if (connectors.length > 0) {
        await connect({ connector: connectors[0] });
      }
    };
  
    const handleDeposit = () => {
      if (!currentTokenAddress) return;
      const amountBigInt = BigInt(ethers.utils.parseUnits(amount, 18).toString());
      setIsTransactionPending(true);
      writeDeposit({
        address: CONTRACT_ADDRESS,
        abi: CONTRACT_ABI,
        functionName: 'depositTokens',
        args: [BigInt(tokenId), amountBigInt],
      });
    };
  
    const handleApprove = () => {
      if (!currentTokenAddress) return;
      const amountBigInt = BigInt(ethers.utils.parseUnits(amount, 18).toString());
      setIsTransactionPending(true);
      writeApprove({
        address: currentTokenAddress,
        abi: ERC20_ABI,
        functionName: 'approve',
        args: [CONTRACT_ADDRESS, amountBigInt,],
      });
    };
  
    const handleTokenChange = (event: SelectChangeEvent<number>) => {
      const newTokenId = event.target.value as number;
      setTokenId(newTokenId);
      setAmount('');
      setApprovedAmount('0');
      refetchBalance();
    };
  
  useEffect(() => {
    if (allowance !== undefined) {
      setApprovedAmount(ethers.utils.formatUnits(allowance.toString(), 18));
      if (BigInt(allowance) > 0) {
        setIsApprovalComplete(true);
      }
    }
  }, [allowance]);
    const handleBuyToken = () => {
      if (tokenId === 0) {
        window.open('https://dexscreener.com/solana/5y3rhbbmdy9qedamqz1sgfrb767nw6anxirtugrj3jnv', '_blank');
      } else {
        navigate(`/chatbot/${tokenId}`);
      }
    };
  
    const handleBuyCheddar = () => {
      window.open('https://cheddex.com/swap?inputCurrency=0xf721d32f758f18e6476ea5bad3680c9e8709f9be&outputCurrency=0x56e5776b59c3dfb42ba9b45733cf618f6d128ec5&chainId=383353&token0=NATIVE&token1=0x56e5776B59c3dfB42bA9B45733Cf618F6D128Ec5', '_blank');
    };
  
    if (!isConnected) {
      return (
        <PageRedux backgroundImage={'/media/cell.jpg'}>
          <Box
			sx={{
			  display: 'flex',
			  flexDirection: 'column',
			  justifyContent: 'center',
			  alignItems: 'center',
			  minHeight: '85vh',
			  backgroundColor: "rgba(223, 223, 233, 0.0)",
			}}
		  >
			<Box
			  sx={{
				maxWidth: 600,
				padding: 3,
				backgroundColor: "rgba(223, 223, 233, 0.8)",
				borderRadius: '8px',
				boxShadow: '0 4px 8px rgba(10,10,10,0.1)',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				minHeight: '35vh',
				gap: 2,
				border: '1px solid rgba(0, 0, 0, 0.1)',
			  }}
			>
              <Typography variant="h4" gutterBottom>
                Connect Wallet
              </Typography>
              <Typography variant="body1" gutterBottom>
                Please connect your wallet to access the token deposit feature.
              </Typography>
              <Button 
                variant="contained" 
                color="primary" 
                onClick={handleConnectWallet}
                disabled={isConnected}
              >
                {isConnected ? 'Connecting...' : 'Connect Wallet'}
              </Button>
            </Box>
          </Box>
        </PageRedux>
      );
    }
  
    if (loading) {
      return <CircularProgress />;
    }
  
    if (error) {
      return <Typography color="error">{error}</Typography>;
    }
	return (
		<PageRedux backgroundImage={'/media/cell.jpg'}>
		  <Box
			sx={{
			  display: 'flex',
			  flexDirection: 'column',
			  justifyContent: 'center',
			  alignItems: 'center',
			  minHeight: '85vh',
			  backgroundColor: "rgba(223, 223, 233, 0.0)",
			}}
		  >
			<Box
			  sx={{
				maxWidth: 600,
				padding: 3,
				backgroundColor: "rgba(223, 223, 233, 0.8)",
				borderRadius: '8px',
				boxShadow: '0 4px 8px rgba(10,10,10,0.1)',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				minHeight: '35vh',
				gap: 2,
				border: '1px solid rgba(0, 0, 0, 0.1)',
			  }}
			>
			  <Typography variant="h1" gutterBottom>
				Deposit Tokens
			  </Typography>
			  <Typography variant="h2" gutterBottom>
				Telegram and Discord bots
			  </Typography>
			  <Typography variant="body1" gutterBottom>
				Deposit tokens to your account in order to use them in the ChatOver.Wine ecosystem with Telegram and Discord.
			  </Typography>
			  <Typography variant="body1" gutterBottom>
				Please deposit only what your willing to use, you can't withdraw tokens.
			  </Typography>
			  <Box margin={"1rem"} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
				<img src={nfts.find(nft => nft.tokenId === tokenId)?.image || "/media/iconwine.png"} alt="Token Icon" style={{ width: '30%', height: '30%' }} />
			  </Box>
			  {tokenId === 0 && (
				<Typography>WINE Balance: {wineBalance ? ethers.utils.formatUnits(wineBalance.toString(), 18) : '0.0'}</Typography>
			  )}
			  {tokenId > 0 && (
				<Typography>{nfts.find(nft => nft.tokenId === tokenId)?.coinName} Balance: {tokenBalance ? ethers.utils.formatUnits(tokenBalance.toString(), 18) : '0.0'}</Typography>
			  )}
			  <Typography>Deposited Balance: {depositedBalance ? ethers.utils.formatUnits(depositedBalance.toString(), 18) : '0.0'}</Typography>
			  <Typography>Approved Amount: {approvedAmount}</Typography>
			  <FormControl fullWidth margin="normal">
				
				<Select<number>
				  value={tokenId}
				  onChange={handleTokenChange}
				>
				  {nfts.map((nft) => (
					<MenuItem key={nft.tokenId} value={nft.tokenId}>
					  {nft.name} ({nft.coinTicker})
					</MenuItem>
				  ))}
				</Select>
			  </FormControl>
			  <TextField
				label="Amount"
				value={amount}
				onChange={(e) => setAmount(e.target.value)}
				fullWidth
				margin="normal"
				type="number"
			  />
			  <Button 
				variant="contained" 
				color="primary" 
				onClick={handleApprove}
				disabled={isTransactionPending }
			  >
				{Number(approvedAmount) >= Number(amount) ? 'Approved' : 'Approve Tokens'}
			  </Button>
			  <Button 
				variant="contained" 
				color="primary" 
				onClick={handleDeposit} 
				disabled={isTransactionPending || Number(approvedAmount) < Number(amount)}
			  >
				Deposit Tokens
			  </Button>
			  {tokenId === 0 ? (
				<Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
				  <Button variant="contained" color="primary" onClick={handleBuyToken} sx={{ flex: 1, marginRight: 1 }}>
					Buy WINE (Solana)
				  </Button>
				  <Button variant="contained" color="primary" onClick={handleBuyCheddar} sx={{ flex: 1, marginLeft: 1 }}>
					Buy WINE (Cheddex)
				  </Button>
				</Box>
			  ) : (
				<Button variant="contained" color="primary" onClick={handleBuyToken} fullWidth>
				  Buy {nfts.find(nft => nft.tokenId === tokenId)?.coinTicker}
				</Button>
			  )}
			  {statusMessage && (
				<Typography color={statusSeverity}>{statusMessage}</Typography>
			  )}
			  {isTransactionPending && <CircularProgress />}
			</Box>
		  </Box>
		</PageRedux>
	  );
	};
	
  
  export default TokenDepositPage;